import { Box, Select, Text } from 'grommet';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { ModelType } from '../../../codegen/models/models';
import ModelEditor, { SetHyperprameterOptimizer } from '../../../core/components/ModelEditor';
import Requestable from '../../../core/components/Requestable';
import ScrollThumb from '../../../core/components/ScrollThumb';
import { displayNameFromSchemaRef } from '../../../core/components/Utils';
import { IApplicationState } from '../../../core/state';
import { getAvailableAlgorithms, getSchema, setAlgorithm } from '../actions';
import { IModel } from '../state';
import NeuralNetworkBuilder from './NeuralNetBuilder';
import { InfoDropdown } from '../../../core/components/InfoDropdown';


export const Algorithm = ({ model }: { model: ModelType }) => {
  const modelState = useSelector<IApplicationState, IModel>(s => s.model)
  const availableAlgorithms = modelState.availableAlgorithms
  const dispatch = useDispatch()
  let schemaRef: string | undefined = model?.algorithm?.schemaRef
  const isRange = model?.optimisationSetup?.parameters?.filter(p => p.name)?.map(p => p.name) || []
  return <Requestable
        requestable={ availableAlgorithms }
        action= { getAvailableAlgorithms }
        render={(availableAlgorithms: string[]) => (
          <Scrollbars renderThumbVertical={ScrollThumb}>
            <Box>
                <Box  pad="xsmall" gap="xsmall">
                    <Select
                      id="select"
                      name="select"
                      labelKey={ displayName => displayNameFromSchemaRef(displayName) }
                      placeholder="Select Algorithm"
                      options={ availableAlgorithms }
                      value={ schemaRef }
                      onChange={ event => {
                        if (event) dispatch(setAlgorithm({ algorithmSchemaRef: event.value , model: model }))
                        schemaRef = event.value
                    }}
                    />
                </Box>
                <Box fill>
                    {
                        model.algorithm && schemaRef &&
                            <Requestable
                                requestable={modelState.schemas[schemaRef]}
                                action={() => schemaRef !== undefined && getSchema(schemaRef)}
                                render={schema => (
                                        schemaRef === 'model.schema.json#/definitions/neural_network' ?
                                        <NeuralNetworkBuilder model={model} schema={schema} />
                                        : model ? <Box fill direction='column' gap='small'>
                                            <ModelEditor
                                              schema={schema}
                                              allowRange
                                              model={model}
                                              property='algorithm'
                                              />
                                            <SetHyperprameterOptimizer isRange={isRange} model={model}/>
                                            {
                                              schema.info && <AlgoInfo info={schema.info}/>
                                            }
                                        </Box> : <></>
                                  )
                                }
                            />
                    }
                </Box>
            </Box>
          </Scrollbars>
        )}
      />
}

export default Algorithm;


const AlgoInfo = ({info}: {info: string}) => {
  return <Box fill='horizontal' direction='row' justify='center' align='center'>
  <InfoDropdown info={info}/>
  <Text>Algorithm Information!</Text>
</Box>
}
