import { toastr } from "react-redux-toastr";
import { createApiThunk } from "../../core/api";
import { ConfigType } from "../../codegen/models/models";

export const getConfigs = createApiThunk<
    ConfigType[],
    void
>(
    'config/getConfigs',
    async (api) => await api.get('config'),
    e => e.type === 'api-error' && toastr.error('Get Configs', e.message)
)

export const addConfig = createApiThunk<
    ConfigType,
    ConfigType
>(
    'config/addConfig',
    async (api, config) => await api.post('config', config),
    e => e.type === 'api-error' && toastr.error('Add Config', e.message)
)

export const updateConfig = createApiThunk<
    ConfigType,
    ConfigType
>(
    'config/updateConfig',
    async (api, config) => await api.put(`config/${ config.id}`, config),
    e => e.type === 'api-error' && toastr.error('Update Config', e.message)
)

export const deleteConfig = createApiThunk<
    void,
    { configId: number }
>(
    'config/deleteConfig',
    async (api, { configId }) => await api.delete(`config/${ configId}`),
    e => e.type === 'api-error' && toastr.error('Update Config', e.message)
)