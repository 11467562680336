import { Box, Text } from 'grommet'
import produce from 'immer'
import { isNil } from 'lodash'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useDispatch, useSelector } from 'react-redux'
import { DatasetType, ProjectType, SiftDatasetPropertiesType, SiftDataSourceInfoType } from '../../../codegen/models/models'
import Breadcrumbs from '../../../core/components/Breadcrumbs'
import Page from '../../../core/components/Page'
import { PageSection } from '../../../core/components/PageSection'
import Requestable from '../../../core/components/Requestable'
import ScrollThumb from '../../../core/components/ScrollThumb'
import useInterval from '../../../core/hooks/useInterval'
import { IApplicationState } from '../../../core/state'
import { useIdSearchParam } from '../../../core/utils/router'
import { getProjects } from '../../project/actions'
import { IProjects } from '../../project/state'
import { getDatasets, getDataSource, updateDataset } from '../actions'
import { IData } from '../state'
import DatasetsTable from './DatasetsTable'
import DateRange from './DateRange'
import FeatureSelector from './FeatureSelector'
import FilterStrings from './FilterStrings'
import Instances from './Instances'
import SelectedDataset from './SelectedDataset'


const DatasetConfiguration = ({ siftDataSource, currentDataset, datasetProperties }: { siftDataSource: SiftDataSourceInfoType, currentDataset: DatasetType, datasetProperties: SiftDatasetPropertiesType }) => {
    return <Box fill direction='column' gap='xsmall'>
        <Box fill gap='xsmall' basis='1/2' margin={{bottom:'small'}}>
            <Box fill direction='row' gap='xsmall'>
                <PageSection title='Available Data' basis='1/3'>
                    <FeatureSelector dataSource={siftDataSource} dataset={currentDataset} datasetProperties={datasetProperties}/>
                </PageSection>
                <PageSection title='Available Instances' basis='1/3'>
                    <Instances key={`instances-${currentDataset.id}`} dataSource={siftDataSource} datasetProperties={datasetProperties} dataset={currentDataset} />
                </PageSection>
                <PageSection title='Current Dataset' basis='1/3'>
                    <SelectedDataset dataSource={siftDataSource} dataset={currentDataset} datasetProperties={datasetProperties} />
                </PageSection>
            </Box>
        </Box>
        <Box fill basis='1/2' direction='column'>
            <Box fill direction='row' gap='xsmall' basis='1/3'>
                <Box fill basis='1/2'>
                    <PageSection title='Date Range'>
                        <DateRange key={`date-range-${currentDataset.id}`} groups={siftDataSource.groups} dataset={currentDataset}/>
                    </PageSection>
                </Box>
                <Box fill basis='1/2'>
                    <PageSection title='Filter Strings'>
                        <FilterStrings key={`filter-string-${currentDataset.id}`} groups={siftDataSource.groups} dataset={currentDataset} />
                    </PageSection>
                </Box>
            </Box>
            <Box fill gap='xsmall' basis='2/3'>
                <PageSection title='Data' />
            </Box>
        </Box>
    </Box>
}

export const DatasetPage = ({ currentDataset }: { currentDataset: DatasetType }) => {
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const dispatch = useDispatch()
    useInterval(() => dispatch(getDataSource({ dataSourceId: currentDataset.dataSourceId })), 30000)
    const siftDatasetProperties = (currentDataset && currentDataset.properties?.schemaRef === 'sift_dataset_properties.schema.json') ? currentDataset.properties : undefined
    return <Box gap='xsmall' fill>
        <Breadcrumbs
            parentName='Data'
            name={currentDataset.name}
            description={currentDataset.description}
            searchParamName='datasetId'
            onNameEdited={n => currentDataset && dispatch(updateDataset(produce(currentDataset, d => { d.name = n })))}
            onDescriptionEdited={desc => currentDataset && dispatch(updateDataset(produce(currentDataset, d => { d.description = desc })))}/>
        {
            currentDataset && siftDatasetProperties &&
            <Requestable
                requestable={data.dataSource[currentDataset.dataSourceId]}
                action={() => getDataSource({ dataSourceId: currentDataset.dataSourceId })}
                render={dataSource => <DatasetConfiguration siftDataSource={dataSource.info as any as SiftDataSourceInfoType} currentDataset={currentDataset} datasetProperties={siftDatasetProperties} />}
            />
        }
    </Box>
}

const DataSourceInfoLoader = ({ project }: { project: ProjectType }) => {
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const currentDatasetId = useIdSearchParam('datasetId')
    return <Requestable
        requestable={data.datasets}
        action={getDatasets}
        render={datasets => {
            const currentDataset = datasets.find(d => d.id === currentDatasetId)
            return currentDataset ?
                <DatasetPage currentDataset={currentDataset}/> :
                <DatasetsTable datasets={datasets.filter(d => d.projectId === project.id && isNil(d.sourceId))} project={project} />
        }} />
}

export default ({ projectId }: { projectId: number }) => {
    const projects = useSelector<IApplicationState, IProjects>(s => s.projects)
    return <Page>
        <Box fill>
            <Scrollbars renderThumbVertical={ScrollThumb}>
                <Requestable
                    requestable={projects.projects}
                    action={getProjects}
                    render={(projects) => {
                        const project = projects.find(p => p.id === projectId)
                        return project ? (
                            <DataSourceInfoLoader project={project} />
                        ) : <Text>Project Not Found</Text>
                    }}
                />
            </Scrollbars>
        </Box>
    </Page>
}
