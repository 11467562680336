import { toastr } from "react-redux-toastr";
import { createApiThunk } from "../../core/api";
import { SiftLinkType } from "../../codegen/models/models";

export const getSiftLinks = createApiThunk<
    SiftLinkType[],
    void
>(
    'siftLink/getSiftLinks',
    async (api) => await api.get('sift-link'),
    e => e.type === 'api-error' && toastr.error('Get SiftLinks', e.message)
)

export const addSiftLink = createApiThunk<
    SiftLinkType,
    SiftLinkType
>(
    'siftLink/addSiftLink',
    async (api, siftLink) => await api.post('sift-link', siftLink),
    e => e.type === 'api-error' && toastr.error('Add SiftLink', e.message)
)

export const updateSiftLink = createApiThunk<
    SiftLinkType,
    SiftLinkType
>(
    'siftLink/updateSiftLink',
    async (api, siftLink) => await api.put(`sift-link/${ siftLink.id}`, siftLink),
    e => e.type === 'api-error' && toastr.error('Update SiftLink', e.message)
)

export const deleteSiftLink = createApiThunk<
    void,
    { siftLinkId: number }
>(
    'siftLink/deleteSiftLink',
    async (api, { siftLinkId }) => await api.delete(`sift-link/${ siftLinkId}`),
    e => e.type === 'api-error' && toastr.error('Update SiftLink', e.message)
)