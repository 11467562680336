import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { createAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  camelCase, forEach, isArray, isPlainObject, snakeCase
} from 'lodash';
import React, { DependencyList, useContext, useEffect } from 'react';

export interface IAuthState {
  token?: string
}

export interface IRequestable<TState> {
  isRequesting: boolean
  errorMessage?: string
  value?: TState
}

export interface ILargeRequestable<TState> extends IRequestable<TState> {
  gotAll: boolean
  dictionary?: {[alertId: number]: {[ids: string]: string | number}}
}

export interface IHasRequestableAuth {
  auth: IRequestable<IAuthState>
}

function convertObjectKeys(originalObject: {[key: string]: any} | any[], converter: (v: string) => string): any {
  if (isArray(originalObject)) return originalObject.map((value) => convertObjectKeys(value, converter));
  if ((typeof originalObject !== 'object' && typeof originalObject !== 'function') || originalObject === null) return originalObject;
  const camelCaseObject: any = {};
  forEach(
    originalObject,
    (value, key) => {
      if (isPlainObject(value) || isArray(value)) { // checks that a value is a plain object or an array - for recursive key conversion
        value = convertObjectKeys(value, converter); // recursively update keys of any values that are also objects
      }
      camelCaseObject[converter(key)] = value;
    },
  )
  return camelCaseObject
}

export default class Api {
  async get(url: string, convertKeys: boolean = true) {
    const response = await axios.get(`/api/v1/${url}`, await this.getConfig());
    return convertKeys ? convertObjectKeys(response.data, camelCase) : response.data;
  }

  async post(url: string, data?: { [key: string]: any }) {
    const response = await axios.post(`/api/v1/${url}`, data ? convertObjectKeys(data, snakeCase) : null, await this.getConfig());
    return convertObjectKeys(response.data, camelCase);
  }

  async put(url: string, data: { [key: string]: any }) {
    const response = await axios.put(`/api/v1/${url}`, convertObjectKeys(data, snakeCase), await this.getConfig());
    return convertObjectKeys(response.data, camelCase);
  }

  async delete(url: string) {
    const response = await axios.delete(`/api/v1/${url}`, await this.getConfig());
    return convertObjectKeys(response.data, camelCase);
  }

  async getConfig(): Promise<AxiosRequestConfig> {
    return {}
  }

}

export class AuthenticatedApi extends Api {
  static getAccessTokenSilently?: (options?: GetTokenSilentlyOptions) => Promise<string> = undefined

  async getConfig(): Promise<AxiosRequestConfig> {
    if (AuthenticatedApi.getAccessTokenSilently) {
      const token = await AuthenticatedApi.getAccessTokenSilently()
      return { headers: { Authorization: `Bearer ${token}` } }
    }
    return {}
  }
}

type ThunkApi<S> = {
  getState: () => S,
  dispatch: ThunkDispatch<any, any, any>
}

export interface StandardApiError {
  type: 'api-error'
  message: string
}

export interface UnauthenticatedApiError {
  type: 'unauthenticated-api-error'
}

export type ApiError = StandardApiError | UnauthenticatedApiError;

export const unauthenticated = createAction('auth/unauthenticated')

export function handleApiError(error: AxiosError, dispatch: (action: any) => any): ApiError {
  if (error.response?.status === 401) {
    dispatch(unauthenticated());
    return { type: 'unauthenticated-api-error' };
  }
  return { message: error.message, type: 'api-error' };
}

export const createApiThunk = <R, A, S extends IHasRequestableAuth = IHasRequestableAuth>(
  name: string,
  payloadCreator: (api: Api, args: A, thunkApi: ThunkApi<S>) => R | Promise<R> | any,
  onError?: ((e: ApiError) => void) | undefined,
) => createAsyncThunk<R | undefined, A, { state: S }>(
    name,
    async (args: A, thunkApi: ThunkApi<S>) => {
      const api = new AuthenticatedApi()
      try {
        return await payloadCreator(api, args, thunkApi);
      } catch (e) {
        const apiError = handleApiError(e as AxiosError, thunkApi.dispatch);
        if (onError) { onError(apiError);
        }
        throw apiError;
      }
    },
  )

export const ApiContext = React.createContext<Api | undefined>(undefined)

export const useApi = () => useContext(ApiContext)

export const useApiEffect = (effect: (api: Api) => Promise<any>, deps?: DependencyList, authenticated: boolean = true) => {
    const authenticatedApi = useContext(ApiContext)
    const api = (authenticated && authenticatedApi) || new Api()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(() => { effect(api) }, [...(deps || []), api, effect])
}

export const AuthenticatedApiProvider = ({ children }: { children: React.ReactNode }) => {
    const { getAccessTokenSilently } = useAuth0()
    const api = new AuthenticatedApi()
    AuthenticatedApi.getAccessTokenSilently = getAccessTokenSilently
    return <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
}