import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { getDataSources, getProjects, addSession } from "./actions"
import { IProjects } from "./state"

export const projects = createSlice<IProjects, SliceCaseReducers<IProjects>>({
  name: 'projects',
  initialState: { projects: { isRequesting: false }, dataSources: { isRequesting: false }, sessions: [] },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, state => {
      state.projects = { isRequesting: true }
    })
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.projects = { isRequesting: false, value: payload }
    })
    builder.addCase(getProjects.rejected, (state, { error: { message }}) => {
      state.projects = { isRequesting: false, errorMessage: message }
    })
    builder.addCase(getDataSources.pending, state => {
      state.dataSources = { isRequesting: true }
    })
    builder.addCase(getDataSources.fulfilled, (state, { payload }) => {
      state.dataSources = { isRequesting: false, value: payload }
    })
    builder.addCase(getDataSources.rejected, (state, { error: { message }}) => {
      state.dataSources = { isRequesting: false, errorMessage: message }
    })
    builder.addCase(addSession, (state, { payload: session }) => {
      state.sessions.push(session)
    })
  }
})