import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { reducer as toastr } from 'react-redux-toastr'
import { model } from '../pages/model/reducer'
import { experiment } from '../pages/experiment/reducer'
import { dataSources } from '../pages/data/reducer'
import { projects } from '../pages/project/reducer'
import { predict } from '../pages/predict/reducer'
import { app } from '../pages/app/reducer'
import { configs } from '../codegen/reducers/configReducer'
import { siftLinks } from '../codegen/reducers/siftLinkReducer'
import { wizards } from '../codegen/reducers/wizardReducer'

const store = createStore(
  combineReducers({
    app: app.reducer,
    data: dataSources.reducer,
    projects: projects.reducer,
    model: model.reducer,
    experiment: experiment.reducer,
    predict: predict.reducer,
    config: configs.reducer,
    wizards: wizards.reducer,
    siftLink: siftLinks.reducer,
    toastr,
  }),
  applyMiddleware(
    thunkMiddleware,
    createLogger()
  ),
)

export default store

export type AppDispatch = typeof store.dispatch
