import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { isNil } from "lodash"
import { createDataset, deleteDataset, getDatasets, getDataSource, clearAddedDataset, updateDataset } from "./actions"
import { IData } from "./state"

export const dataSources = createSlice<IData, SliceCaseReducers<IData>>({
    name: 'projects',
    initialState: { dataSource: {}, datasets: { isRequesting: false } },
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getDataSource.pending, (state, { meta: { arg: { dataSourceId } }}) => {
        if (isNil(state.dataSource[dataSourceId]?.value)) {
          state.dataSource[dataSourceId] = { isRequesting: true }
        }
      })
      builder.addCase(getDataSource.fulfilled, (state, { payload, meta: { arg: { dataSourceId } }}) => {
        state.dataSource[dataSourceId] = { isRequesting: false, value: payload }
      })
      builder.addCase(getDataSource.rejected, (state, { error: { message }, meta: { arg: { dataSourceId } }}) => {
        state.dataSource[dataSourceId] = { isRequesting: false, errorMessage: message }
      })
      builder.addCase(getDatasets.pending, (state) => {
        state.datasets = { isRequesting: true }
      })
      builder.addCase(getDatasets.fulfilled, (state, { payload }) => {
        state.datasets = { isRequesting: false, value: payload }
      })
      builder.addCase(getDatasets.rejected, (state, { error: { message } }) => {
        state.datasets = { isRequesting: false, errorMessage: message }
      })
      builder.addCase(createDataset.pending, (state) => {
        state.datasets = { ...state.datasets, isRequesting: true }
      })
      builder.addCase(createDataset.fulfilled, (state, { payload }) => {
        const value = payload === undefined ? state.datasets.value : state.datasets.value === undefined ? [payload] : [...state.datasets.value, payload]
        state.datasets = { isRequesting: false, value }
        state.datasetAdded = payload?.id
      })
      builder.addCase(createDataset.rejected, (state, { error: { message } }) => {
        state.datasets = { isRequesting: false, errorMessage: message, value: state.datasets.value }
      })
      builder.addCase(clearAddedDataset, (state) => {
        state.datasetAdded = undefined
      })
      builder.addCase(updateDataset.pending, (state, { meta: { arg: dataset } }) => {
        if (state.datasets.value !== undefined) {
          const datasetIndex = state.datasets.value?.findIndex(d => d.id === dataset.id)
          if (datasetIndex >= 0 && datasetIndex < state.datasets.value?.length) {
            state.datasets.value[datasetIndex] = dataset
          }
        }
      })
      builder.addCase(updateDataset.fulfilled, (state, { meta: { arg: dataset } }) => {
      })
      builder.addCase(updateDataset.rejected, (state, { meta: { arg: dataset } }) => {
        // TODO handle rejection!
      })

      builder.addCase(deleteDataset.fulfilled, (state, {meta: {arg: { datasetId }}}) => {
        if (state.datasets.value !== undefined) {
          state.datasets.value = state.datasets.value.filter(d => d.id !== datasetId)
        }
      })
      builder.addCase(deleteDataset.rejected, (state, { error: { message }}) => {
        // TODO handle rejection!
      })

    }
})
