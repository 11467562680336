import { Box } from 'grommet'
import produce from 'immer'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PredictionType, ProjectType } from '../../../codegen/models/models'
import ItemsTable, { CopyCell, DeleteCell, EditableCell, NameCell } from '../../../core/components/ItemsTable'
import { DeleteModal } from '../../../core/components/Modal'
import { IApplicationState } from '../../../core/state'
import { clearAddedPrediction, addPrediction, deletePrediction, updatePrediction } from '../actions'
import { IPredict } from '../state'

export default ({ project, predictions }: { project: ProjectType, predictions: PredictionType[] }) => {
    const prediction = useSelector<IApplicationState, IPredict>(s => s.predict)
    const dispatch = useDispatch()
    const [deleting, setDeleting] = useState<number>()
    return <Box>
        <ItemsTable
            clearAddedIdAction={clearAddedPrediction}
            addedId={prediction.predictionAdded}
            name='Prediction'
            onCreate={name => project.id && dispatch(addPrediction({ 
                prediction: {
                    name: name || 'New Prediction',
                    projectId: project.id,
                    schemaRef: 'prediction.schema.json',
                    predictionSinkId: project.defaultPredictionSinkId,
                    predictionStatus: 'pending'
                }
            }))}
            searchParam='predictionId'
            items={predictions}
            columns={[
                {
                    property: 'id',
                    header: 'Name',
                    render: (item) => <NameCell item={item} searchParam='predictionId' />
                },
                {
                    property: 'description',
                    header: 'Description',
                    render: (item) => <EditableCell value={item.description || ''} onChanged={v => dispatch(updatePrediction(produce(item, p => { p.description = v })))} />
                },
                {
                    header: 'Copy',
                    property: 'id1',
                    size: 'xsmall',
                    render: (prediction: PredictionType) => {
                        return <CopyCell onCopy={() => dispatch(addPrediction({ prediction: {
                            ...prediction,
                            id: undefined,
                            name: `${prediction.name} (Copy)`
                        }}))} />
                    }
                },
                {
                    header: 'Delete',
                    property: 'id2',
                    size: 'xsmall',
                    render: (item: PredictionType) => {
                        return <DeleteCell onDelete={setDeleting} item={item}/>
                    }
                }
            ]}
        />
        <DeleteModal
            isOpen={deleting !== undefined}
            item={predictions.find(p => p.id === deleting)?.name || '?'}
            text={'Prediction'}
            onAccept={() => {
                deleting && dispatch(deletePrediction({predictionId: deleting}))
                setDeleting(undefined)
            }}
            onCancel={() => setDeleting(undefined)} />
    </Box>
}