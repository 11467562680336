import { TextInput, TextInputProps } from 'grommet'
import { toInteger, toNumber } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

type EditableTextType = 'string' | 'number' | 'integer'

export default ({initialValue, placeholder, onFinishEdit, onAbortEdit, focus, type}:
    {initialValue?: string, placeholder: string, onFinishEdit: (v?: any) => void, onAbortEdit: () => void, focus?: boolean, type?: EditableTextType } & TextInputProps) => {
    const [value, setValue] = useState(initialValue)
    const textInput = useRef<any>()
    if(focus === undefined){focus = true}
    useEffect(() => { if (textInput && focus) textInput.current.focus() })

    const onFinishEditWithType = (v: string | undefined) => {
        if (!type || type === 'string') {
            onFinishEdit(v)
        } else if (type === 'number') {
            const floatValue: number = v !== undefined ? toNumber(v) : 0.0
            setValue(floatValue.toString())
            onFinishEdit(floatValue)
        } else if (type === 'integer') {
            const integerValue: number = v !== undefined ? toInteger(v) : 0
            setValue(integerValue.toString())
            onFinishEdit(integerValue)
        }
    }

    return <TextInput
        ref={textInput}
        value={value}
        onKeyPress={(e) => {
            if (e.key === 'Enter') onFinishEditWithType(value)
            else if (e.key === 'Esc') onAbortEdit()
        }}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => onFinishEditWithType(value)}
        type={!type || type === 'string' ? 'text' : 'number'}
        step={type === 'integer' ? 1 : type === 'number' ? 0.1 : undefined}
        placeholder={placeholder}
    />
}