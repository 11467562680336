import { Anchor, Box, CheckBox, DropButton } from 'grommet'
import produce from 'immer'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModelType } from '../../../codegen/models/models'
import Breadcrumbs from '../../../core/components/Breadcrumbs'
import Icon from '../../../core/components/Icon'
import { PageSection } from '../../../core/components/PageSection'
import { updateModel } from '../actions'
import DataLoader from './DataLoader'
import Preprocessing from './Preprocessing'
import TestingSetup from './TestingSetup'
import Algorithm from './Algorithm'
import TrainOptions from './TrainOptions'
import JSONViewer from '../../../core/components/JSONViewer'
import ProjectOverview from '../../../core/components/ProjectOverview'


export default ({model, dataSourceId, projectId}: {model: ModelType, dataSourceId: number, projectId: number}) => {
    const dispatch = useDispatch()
    const [openNotification, setOpenNotification] = useState(false)
    const [openProjectOverview, setOpenProjectOverwiew] = useState(false)
    return <Box gap='xsmall' fill>
      <Box fill='horizontal' direction='row'>
        <Breadcrumbs
          parentName='Models'
          name={model.name}
          description={model.description}
          searchParamName='modelId'
          onNameEdited={n => model && dispatch(updateModel(produce(model, m => {m.name = n})))}
          onDescriptionEdited={n => model && dispatch(updateModel(produce(model, m => {m.description = n})))}
          />
        <Box pad='xsmall' align='end' justify='center'>
            <DropButton gap='small' label={<Icon icon='ellipsis-v' color='control'/>} size='small' dropAlign={{ top: 'bottom', right: 'right' }} dropContent={
              <Box direction='column' gap='small' pad='small'>
                <CheckBox
                  checked={openNotification}
                  onClick={() => setOpenNotification(!openNotification)}
                    label='View .json'
                  />
                <CheckBox
                  checked={openProjectOverview}
                    onClick={() => setOpenProjectOverwiew(!openProjectOverview)}
                    label='Project Overview'
                  />
                <Anchor href={`/api/v1/test-data/${model.id}`} label='Download Test Files'/>
              </Box>
            }/>
        </Box>
      </Box>
      {
        model !== undefined &&
           <Box fill direction='row' gap='xsmall'>
             <Box gap="xsmall" fill>
                 <PageSection title='Data Loading' basis='content' fill={false}>
                     <DataLoader key={`data-loader-${model.id}`} model={model} dataSourceId={dataSourceId}/>
                 </PageSection>
                 <PageSection title='Testing Setup' basis='content' fill={false}>
                      <TestingSetup model={model} projectId={projectId} />
                 </PageSection>
                 <PageSection title='Preprocessing' basis='content' fill={false} flex='grow'>
                     <Preprocessing key={`preprocessing-${model.id}`} model={ model } />
                 </PageSection>
             </Box>
             <Box gap="xsmall" fill>
                 <PageSection title='Algorithm' fill={false} basis='content' flex='grow'>
                     <Algorithm key={`algorithm-${model.id}`} model={ model }/>
                 </PageSection>
                 <PageSection title="Training Options" fill={false} basis='content'>
                   <TrainOptions key={`train-options-${model.id}`} model={ model} projectId={model.projectId} />
                 </PageSection>
             </Box>
             <JSONViewer isOpen={openNotification} model={model} />
             <ProjectOverview isOpen={openProjectOverview}/>
           </Box>
      }
      </Box>
  }