import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { getAvailablePreprocessors,
         getAvailableAlgorithms,
         createModel,
         updateModel,
         deleteModel,
         getExistingModels,
         clearAddedModel,
         getSchema,
         validate,
         setSchema} from "./actions"
import { IModel } from "./state"

export const model = createSlice<IModel, SliceCaseReducers<IModel>>({
    name: 'model',
    initialState: { availablePreprocessors: { isRequesting: false },
                    availableAlgorithms: { isRequesting: false },
                    existingModels:{ isRequesting: false },
                    existingExperiments: { isRequesting: false },
                    schemas: {}
                },
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getAvailablePreprocessors.pending, (state) => {
        state.availablePreprocessors = { isRequesting: true }
      })
      builder.addCase(getAvailablePreprocessors.fulfilled, (state, { payload }) => {
        state.availablePreprocessors = { isRequesting: false, value: payload }
      })
      builder.addCase(getAvailablePreprocessors.rejected, (state, { error: { message }}) => {
        state.availablePreprocessors = { isRequesting: false, errorMessage: message }
      })

      builder.addCase(getAvailableAlgorithms.pending, (state) => {
        state.availableAlgorithms = { isRequesting: true }
      })
      builder.addCase(getAvailableAlgorithms.fulfilled, (state, { payload }) => {
        state.availableAlgorithms = { isRequesting: false, value: payload }
      })
      builder.addCase(getAvailableAlgorithms.rejected, (state, { error: { message }}) => {
        state.availableAlgorithms = { isRequesting: false, errorMessage: message }
      })

      builder.addCase(getExistingModels.fulfilled, (state, { payload }) => {
        state.existingModels = { isRequesting: false, value: payload }
      })

      builder.addCase(createModel.fulfilled, (state, { payload }) => {
          if (state.existingModels.value && payload){
            state.existingModels.value.push(payload)
            state.modelAdded = payload.id
          }
      })

      builder.addCase(clearAddedModel, state => state.modelAdded = undefined)

      builder.addCase(updateModel.pending, (state, {meta: {arg: model}}) => {
        const existingModels = state.existingModels.value
        if(existingModels){
          const modelIndex = existingModels.findIndex(m=>m.id===model.id)
          existingModels[modelIndex] = model
        }
      })
      builder.addCase(updateModel.rejected, (state, { error: { message }}) => {

      })

      builder.addCase(deleteModel.fulfilled, (state, {meta: {arg: { modelId }}}) => {
        if(state.existingModels.value !== undefined){
          state.existingModels.value = state.existingModels.value.filter(m => m.id !== modelId)
        }
      })

      builder.addCase(deleteModel.rejected, (state, { error: { message }}) => {
        // TODO! Handle Rejection
      })

      builder.addCase(getSchema.pending, (state, { meta: { arg: schemaRef } }) => {
        state.schemas[schemaRef] = { isRequesting: true }
      })
      builder.addCase(getSchema.fulfilled, (state, { payload, meta: { arg: schemaRef } }) => {
        state.schemas[schemaRef] = { isRequesting: false, value: payload }
      })
      builder.addCase(getSchema.rejected, (state, { meta: { arg: schemaRef } }) => {
        state.schemas[schemaRef] = { isRequesting: false, errorMessage: `Error getting schema ${schemaRef}` }
      })
      builder.addCase(setSchema, (state, { payload: { schemaRef, schema } }) => {
        state.schemas[schemaRef] = { isRequesting: false, value: schema }
      })
      builder.addCase(validate, (state, { payload }) => {
        state.validationResults = payload
      })
    }
})
