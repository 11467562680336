import React from 'react';
import { AccordionPanel, Box, Button, Select, Text } from 'grommet';
import { PreprocessorType, DatasetType, FeatureType, FlagFeatureType, EventFeatureType, SignalFeatureType, SiftDataSourceInfoType, ModelType } from '../../../codegen/models/models'
import { camelToDisplayCase, displayNameFromSchemaRef, SchemaInterface } from '../../../core/components/Utils'
import ModelEditor from '../../../core/components/ModelEditor';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../core/state';
import { IRequestable } from '../../../core/api';
import Requestable from '../../../core/components/Requestable';
import { getSchema } from '../actions';
import InputRow from '../../../core/components/InputRow'
import { produce } from 'immer'
import { InfoDropdown } from '../../../core/components/InfoDropdown'
import { keys } from 'lodash'


export interface ExtendedPreprocessorType extends PreprocessorType {
    [key: string]: any
}

export interface ExtendedFeatureType extends FeatureType {
    [key: string]: any
}

const isFeature = (schemaRef: string) => {
  const featureRefs = ['sift_dataset_properties.schema.json#/definitions/feature', 'sift_dataset_properties.schema.json#/definitions/signal_feature', 'sift_dataset_properties.schema.json#/definitions/flag_feature']
  if(featureRefs.includes(schemaRef)){
    return true
  }
  else{
    return false
  }
}

const getFeatureProps = (preprocessor: ExtendedPreprocessorType) => {
  return keys(preprocessor).filter(key => typeof(preprocessor[key]) === 'object' && isFeature(preprocessor[key].schemaRef))
}



export const Preprocessor = ({model, index, preprocessor, onUpdated, onRemoved, dataset, dataSourceInfo}:
                             {model: ModelType, index: number, preprocessor: PreprocessorType, onUpdated: (p: PreprocessorType) => any,
                              onRemoved: () => void, dataset?: DatasetType, dataSourceInfo: SiftDataSourceInfoType  }) => {   
    const schema = useSelector<IApplicationState, IRequestable<SchemaInterface>>(s => s.model.schemas[preprocessor.schemaRef])
    const datasetFeatures = getDatasetFeatures(dataset)
    const featureProps = getFeatureProps(preprocessor as ExtendedPreprocessorType)
    return <AccordionPanel label={ displayNameFromSchemaRef(preprocessor.schemaRef)} style={{background: "#002B36"}}>
        <Box fill gap='small' style={{background: "backgroundContrast"}} pad='small'>
            <Requestable
                requestable={schema}
                action={() => getSchema(preprocessor.schemaRef)}
                render={schema => <Box>
                  <ModelEditor
                      schema={schema}
                      model={model}
                      property={`preprocessors[${index}]`}
                      excluded= {['feature', 'featureType', ...featureProps]}
                  />

                  {
                    datasetFeatures ?
                      featureProps.map((key) => <Box direction='row' fill='horizontal' alignContent='end' pad='small'>
                          <InputRow label={`Select ${camelToDisplayCase(key)}`}>
                            <Select
                              value={(preprocessor as ExtendedPreprocessorType)[key].featureId }
                              placeholder='Please select feature/label...'
                              options={ datasetFeatures.map(f => f.id) }
                              onChange={({ value }) => {
                                  const feature = datasetFeatures.find(f => f.id === value) as unknown as ExtendedFeatureType
                                  if(feature){
                                    onUpdated(produce(preprocessor, p => {
                                      (p as ExtendedPreprocessorType)[key] = feature;
                                      }))
                                  }
                              }}
                            />
                            </InputRow>
                          </Box>):
                        (preprocessor as ExtendedPreprocessorType).feature && <Text> Please Select Dataset...</Text>
                  }
                  { 
                    schema.info && <PPInfo info={schema.info}/>
                  }
                </Box>
              }/>
            <Button label='Remove Preprocessor' onClick={onRemoved}/>
        </Box>
    </AccordionPanel>
}

export default Preprocessor;


const getDatasetFeatures = (dataset?: DatasetType) => {
  if(dataset !== undefined && dataset?.properties?.schemaRef === 'sift_dataset_properties.schema.json'){
     return dataset?.properties?.features
  }
  else{
    return undefined
  }
}

const PPInfo = ({info}: {info: string}) => {
  return <Box fill='horizontal' direction='row' justify='center' align='center'>
  <InfoDropdown info={info}/>
  <Text>Preprocessor Information!</Text>
</Box>
}