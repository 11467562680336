import { Text } from 'grommet'
import produce from 'immer'
import React from 'react'
import { useDispatch } from 'react-redux'
import { DatasetType, DateRangeType, GroupType } from '../../../codegen/models/models'
import DateRange from '../../../core/components/DateRange'
import { getDateRange } from '../../../core/utils/dateRange'
import { updateDataset } from '../actions'

const updateDate = (dataset: DatasetType, dateRange: DateRangeType) => {
    return produce(dataset, d => {
        if (d.properties?.schemaRef !== 'sift_dataset_properties.schema.json') {
            return d
        }
        d.properties.dateRange.dateFrom = dateRange.dateFrom || d.properties.dateRange.dateFrom
        d.properties.dateRange.dateTo = dateRange.dateTo || d.properties.dateRange.dateTo
    })
}

export default ({groups, dataset}: {groups: GroupType[], dataset: DatasetType}) => {
    const dispatch = useDispatch()
    return dataset.properties?.schemaRef === 'sift_dataset_properties.schema.json' ? <DateRange 
        dateRange={dataset.properties.dateRange}
        initialDateRange={getDateRange(groups)}
        onUpdate={(dr: DateRangeType) => dispatch(updateDataset(updateDate(dataset, dr)))}
    /> : <Text>Date Range Unsupported for type</Text>
}