import { Box, Button, CheckBox, Select, Text } from "grommet"
import produce from 'immer'
import { get, keys, last, map, set, split } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChoiceParameterType, DiscreteParameterType, ModelType, UniformParameterType } from '../../codegen/models/models'
import { setSchema, updateModel, validate } from '../../pages/model/actions'
import { IRequestable } from '../api'
import { IApplicationState } from '../state'
import { ValidationResult } from '../validation/validate'
import EditableText from './EditableText'
import InputRow from './InputRow'
import { camelToDisplayCase, displayNameFromSchemaRef, generateModel, SchemaInterface } from './Utils'

export default ({ schema, model, excluded, property, allowRange = false }:
    {
        schema: SchemaInterface, model: ModelType, property: string,
        excluded?: string[], allowRange?: boolean
    }) => {
    const dispatch = useDispatch()
    const schemas = useSelector<IApplicationState, { [key: string]: IRequestable<SchemaInterface> }>(s => s.model.schemas)
    const validationResults = useSelector<IApplicationState, ValidationResult[] | undefined>(s => s.model.validationResults)
    const schemaRef = get(model, property).schemaRef
    useEffect(() => {
        if (schemaRef && !schemas[schemaRef]) {
            dispatch(setSchema({ schemaRef, schema }))
        } else {
            dispatch(validate({ model, schemas }))
        }
    }, [schemas, schemaRef, dispatch, model, schema])
    const doUpdate = (v: any, propertyPath: string) => dispatch(updateModel(produce(model, m => set(m, propertyPath, v))))
    const exclusionList = ["enabled", "schema_ref", ...(excluded || [])].map(p => `${property}.${p}`)
    return <Box gap='small'>
        {keys(schema.properties).map(p => ({prop: p, propertyPath: `${property}.${p}`})).map(({ prop, propertyPath }) => <Box key={`editor-${propertyPath}`}>
            {!exclusionList.includes(propertyPath) &&
                <Box direction='row' fill='horizontal' gap='small'>
                      {
                      schema.properties[last(split(propertyPath, '.')) || propertyPath].oneOf ?
                        <Box fill gap='small'>
                        <InputRow
                        label={camelToDisplayCase(last(split(propertyPath, '.')) || propertyPath)}
                        validation={validationResults?.filter(r => r.propertyName === propertyPath) || []}
                        info={schema.properties[prop].info}>
                                <Select
                                    value={schema.properties[last(split(propertyPath, '.')) || propertyPath].oneOf.find((r: any) => r.ref === get(model, propertyPath)?.schemaRef)}
                                    labelKey={value => displayNameFromSchemaRef(value.ref)}
                                    options={schema.properties[last(split(propertyPath, '.')) || propertyPath].oneOf}
                                    onChange={({ value }) => doUpdate(generateModel(value, value.ref), propertyPath)}
                                />
                        </InputRow>
                        {
                            map(schema.properties[last(split(propertyPath, '.')) || propertyPath].oneOf.find((r: any) => r.ref === get(model, propertyPath)?.schemaRef)?.properties || {}, (value, key) => {
                                return <InputRow
                                label={camelToDisplayCase(key)}
                                validation={validationResults?.filter(r => r.propertyName === propertyPath) || []}
                                info={schema.properties[prop].info}>
                                    <SingleInputRow 
                                            schema={schema.properties[last(split(propertyPath, '.')) || propertyPath].oneOf.find((r: any) => r.ref === get(model, propertyPath)?.schemaRef)}
                                            model={model}
                                            propertyPath={`${propertyPath}.${key}`}
                                            doUpdate={doUpdate} />
                                </InputRow>
                            })
                        } 
                        </Box>: 
                        <InputRow
                        label={camelToDisplayCase(last(split(propertyPath, '.')) || propertyPath)}
                        validation={validationResults?.filter(r => r.propertyName === propertyPath) || []}
                        info={schema.properties[prop].info}>
                        {
                            model.optimisationSetup?.parameters?.find(p => p.name === propertyPath) && model ?
                                <RangeInputRow
                                    schema={schema}
                                    model={model}
                                    propertyPath={propertyPath}
                                    doUpdate={doUpdate}
                                /> :
                                <SingleInputRow schema={schema} model={model} propertyPath={propertyPath} doUpdate={doUpdate} />
                        }
                        </InputRow>
                    }
                    {
                        schema.properties[last(split(propertyPath, '.')) || propertyPath].allowRange && model &&
                        <Box fill='vertical' width='30%'>
                            <SetRangeButton model={model} propertyPath={propertyPath} schema={schema} />
                        </Box>
                    }
                </Box>
            }
        </Box>
        )}
    </Box>

}

const SingleInputRow = ({ schema, model, propertyPath, doUpdate }:
    {
        schema: SchemaInterface, model: ModelType, propertyPath: string,
        doUpdate: (v: any, propertyPath: string) => void
    }) => {
    const schemaProperty = last(split(propertyPath, '.')) || propertyPath
    return <Box fill>
        {
            schema.properties[schemaProperty].type === 'boolean' ?
                <CheckBox
                    checked={get(model, propertyPath)}
                    onChange={e => doUpdate(e.target.checked, propertyPath)}
                /> :
            schema.properties[schemaProperty].enum ?
                <Select
                    value={get(model, propertyPath)}
                    options={schema.properties[schemaProperty].enum}
                    onChange={({ value }) => doUpdate(value, propertyPath)}
                /> :
            <EditableText
                initialValue={get(model, propertyPath)}
                value={get(model, propertyPath)}
                focus={false}
                placeholder=''
                type={schema.properties[schemaProperty].type}
                onFinishEdit={(v) => doUpdate(v, propertyPath)}
                onAbortEdit={() => { }}
            />
        }
    </Box>
}

const RangeInputRow = ({ schema, model, propertyPath, doUpdate }:
    { schema: SchemaInterface, model: ModelType, propertyPath: string, doUpdate: (v: any, propertyPath: string) => void }) => {
    const dispatch = useDispatch()
    const schemaProperty = last(split(propertyPath, '.')) || propertyPath
    const enumOptions: string[] = schema.properties[schemaProperty].enum
    const parameter = model.optimisationSetup?.parameters?.find(p => p.name === propertyPath)
    return (
        <Box>
            {
                schema.properties[schemaProperty].type === 'boolean' ?
                    <CheckBox
                        checked={get(model, propertyPath)}
                        onChange={e => doUpdate(e.target.checked, propertyPath)}
                    />
                    : parameter?.schemaRef === 'model.schema.json#/definitions/choice_parameter' ?
                        <Box direction='column'>
                            <Select
                                multiple
                                closeOnChange={false}
                                placeholder='Select Options...'
                                selected={parameter.items.map(i => enumOptions.findIndex(o => o === i))}
                                options={enumOptions}
                                onChange={({ value }) => {
                                    dispatch(updateModel(produce(model, m => {
                                        if (m?.optimisationSetup?.parameters) {
                                            const pIndex = m.optimisationSetup.parameters.findIndex(param => param.name === propertyPath)
                                            m.optimisationSetup.parameters[pIndex] = {
                                                schemaRef: 'model.schema.json#/definitions/choice_parameter',
                                                items: value,
                                                name: propertyPath
                                            }
                                        }
                                    })))
                                }}
                            />
                        </Box>
                        : (parameter?.schemaRef === 'model.schema.json#/definitions/discrete_parameter' || parameter?.schemaRef === 'model.schema.json#/definitions/uniform_parameter') ? <Box>
                            <RangeInput model={model} parameter={parameter} />
                        </Box> : <Text>Invalid parameter type</Text>
            }
        </Box>)
}


const RangeInput = ({ model, parameter }: { model: ModelType, parameter: UniformParameterType | DiscreteParameterType }) => {
    const dispatch = useDispatch()
    const doUpdate = (v: number, prop: 'rangeMin' | 'rangeMax') => {
        dispatch(updateModel(produce(model, m => {
            if (m.optimisationSetup?.parameters) {
                const indexToUpdate = m.optimisationSetup.parameters.findIndex(p => p.name === parameter.name)
                if (indexToUpdate > -1) {
                    m.optimisationSetup.parameters[indexToUpdate] = {
                        ...parameter,
                        [prop]: v 
                    }
                }
            }
        })))
    }
    const type = parameter.schemaRef === 'model.schema.json#/definitions/discrete_parameter' ? 'integer' : 'number'
    return <Box fill direction='row' align='center' gap='small'>
        <Text size='xsmall'>Min</Text>
        <EditableText
            initialValue={parameter.rangeMin.toString()}
            focus={false}
            placeholder=''
            onAbortEdit={() => { }}
            type={type}
            onFinishEdit={v => { v && doUpdate(v as number, 'rangeMin') }}
        />
        <Text size='xsmall'>Max</Text>
        <EditableText
            initialValue={parameter.rangeMax.toString()}
            focus={false}
            placeholder=''
            onAbortEdit={() => { }}
            type={type}
            onFinishEdit={v => { v && doUpdate(v as number, 'rangeMax') }}
        />
    </Box>
}

const getParameterDefinition = (schema: any, name: string): (UniformParameterType | DiscreteParameterType | ChoiceParameterType) => {
    if (schema.enum) {
        return {
            schemaRef: 'model.schema.json#/definitions/choice_parameter',
            items: [],
            name
        }
    } else if (schema.discrete || schema.type === 'number' || schema.type === 'integer') {
        return {
            schemaRef: (schema.discrete || schema.type === 'integer') ? 'model.schema.json#/definitions/discrete_parameter' : 'model.schema.json#/definitions/uniform_parameter',
            rangeMin: schema.default || 0,
            rangeMax: schema.default || 0,
            name
        }
    } else {
        return {
            schemaRef: 'model.schema.json#/definitions/uniform_parameter',
            rangeMax: 0,
            rangeMin: 0,
            name
        }
    }
}

const SetRangeButton = ({ propertyPath, model, schema }: { schema: any, propertyPath: string, model: ModelType }) => {
    const dispatch = useDispatch()
    const hasParameter = (model.optimisationSetup?.parameters || []).find(p => p.name === propertyPath) !== undefined
    const schemaProperty = last(split(propertyPath, '.')) || propertyPath
    return <Button
        primary={hasParameter}
        secondary={!hasParameter}
        label={hasParameter ? 'Set Single Value' : 'Set Range'}
        onClick={() => {
            dispatch(updateModel(produce(model, m => {
                const existingParameter = (m.optimisationSetup?.parameters || []).find(p => p.name === propertyPath)
                const parameters: (UniformParameterType | DiscreteParameterType | ChoiceParameterType)[] = existingParameter ?
                    (m.optimisationSetup?.parameters || []).filter(p => p !== existingParameter) :
                    [...(m.optimisationSetup?.parameters || []), getParameterDefinition(schema.properties[schemaProperty], propertyPath)]
                m.optimisationSetup = {
                    ...(m.optimisationSetup || {
                        schemaRef: 'model.schema.json#/definitions/optimisation_setup',
                        optimisationType: 'hyperopt'
                    }),
                    parameters
                }
            })))
        }}
    />
}


export const SetHyperprameterOptimizer = ({ isRange, model }: { isRange: (string | undefined)[], model: ModelType }) => {
    const dispatch = useDispatch()
    return <Box>
        {isRange.length > 0 && model?.optimisationSetup !== undefined &&
            <InputRow label='Select Optimisation Algorithm'>
                <Box>
                    {
                        model?.optimisationSetup &&
                        <Select
                            value={model.optimisationSetup.optimisationType}
                            options={['hyperopt', 'bayesopt']}
                            onChange={({ value }) => {
                                const newModel = produce(model, m => {
                                    m.optimisationSetup = {
                                        ...(m.optimisationSetup || {}),
                                        schemaRef: 'model.schema.json#/definitions/optimisation_setup',
                                        optimisationType: value
                                    }
                                })
                                dispatch(updateModel(newModel))
                            }}
                        />
                    }
                </Box>
            </InputRow>
        }
    </Box>
}
