import { toastr } from "react-redux-toastr";
import { createApiThunk } from "../../core/api";
import { WizardType } from "../../codegen/models/models";

export const getWizards = createApiThunk<
    WizardType[],
    void
>(
    'wizard/getWizards',
    async (api) => await api.get('wizard'),
    e => e.type === 'api-error' && toastr.error('Get Wizards', e.message)
)

export const addWizard = createApiThunk<
    WizardType,
    WizardType
>(
    'wizard/addWizard',
    async (api, wizard) => await api.post('wizard', wizard),
    e => e.type === 'api-error' && toastr.error('Add Wizard', e.message)
)

export const updateWizard = createApiThunk<
    WizardType,
    WizardType
>(
    'wizard/updateWizard',
    async (api, wizard) => await api.put(`wizard/${ wizard.id}`, wizard),
    e => e.type === 'api-error' && toastr.error('Update Wizard', e.message)
)

export const deleteWizard = createApiThunk<
    void,
    { wizardId: number }
>(
    'wizard/deleteWizard',
    async (api, { wizardId }) => await api.delete(`wizard/${ wizardId}`),
    e => e.type === 'api-error' && toastr.error('Update Wizard', e.message)
)