import { Auth0Provider } from '@auth0/auth0-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Box } from 'grommet'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import App from './App'
import { AuthenticatedApiProvider, useApiEffect } from './core/api'
import './index.css'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { GrommetWrapper } from './theme'

library.add(fas)

interface AuthConfig {
  domain: string
  clientId: string
  redirectUri: string
  audience: string
}

const isAuthConfig = (o: any): o is AuthConfig => (
  o.domain && o.clientId && o.redirectUri && o.audience
)

const AmlAuth0Provider = ({ children }: { children: React.ReactNode }) => {
  const [authConfig, setAuthConfig] = useState<AuthConfig | undefined>()
  const [error, setError] = useState<string | undefined>()
  useApiEffect(async api => {
    if (authConfig === undefined && error === undefined) {
      try {
        const result = await api.get('auth-config')
        if (isAuthConfig(result)) {
          setAuthConfig(result)
        } else {
          setError('Error loading Sift AML: Invalid authentication config ' + JSON.stringify(result))
        }
      } catch (e) {
        setError('Error loading Sift AML')
      }
    }
  }, [authConfig, error])
  return error ? <Box background='background-back' align='center' justify='center' fill>{error}</Box> :
    !authConfig ? <Box background='background-back' align='center' justify='center' fill>Loading...</Box> :
    <Auth0Provider
      useRefreshTokens={true}
      cacheLocation='localstorage'
      {...authConfig}
    >
      {children}
    </Auth0Provider>
}

ReactDOM.render(
  <Provider store={store}>
    <GrommetWrapper>
      <AmlAuth0Provider>
        <AuthenticatedApiProvider>
          <App />
          <ReduxToastr preventDuplicates transitionIn='fadeIn' transitionOut='fadeOut' />
        </AuthenticatedApiProvider>
      </AmlAuth0Provider>
    </GrommetWrapper>
  </Provider>,
  document.getElementById('root'),
)

Modal.setAppElement('#grommet-root')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
