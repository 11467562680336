import { Box, Button, Select } from 'grommet'
import produce from 'immer'
import { capitalize } from 'lodash'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { DatasetType, FilterStringType, GroupType } from '../../../codegen/models/models'
import EditableText from '../../../core/components/EditableText'
import Icon from '../../../core/components/Icon'
import ScrollThumb from '../../../core/components/ScrollThumb'
import { updateDataset } from '../actions'

const FilterString = ({groups, filterString, onChanged, onDeleted}: {groups: GroupType[], filterString: FilterStringType, onChanged: (f: FilterStringType) => void, onDeleted: () => void}) => {
    return <Box direction='row' gap='small' align='center'>
        <Select
            value={groups.find(g => g.id === filterString.groupId)}
            options={groups}
            labelKey={g => g.displayName || g.id}
            onChange={({ value }) => onChanged(produce(filterString, f => { f.groupId = value.id }))}
        />
        <Select
            value={filterString.type}
            options={["include", "exclude"]}
            labelKey={capitalize}
            onChange={({ value }) => onChanged(produce(filterString, f => { f.type = value }))}
        />
        <EditableText
            focus={false}
            initialValue={filterString.filter}
            placeholder='Enter a Filter String'
            onFinishEdit={v => v && onChanged(produce(filterString, f => { f.filter = v} ))}
            onAbortEdit={() => {}}
        />
        <Button plain onClick={onDeleted}>
            <Icon icon='trash' color='background-front' size='lg'/>
        </Button>
    </Box>
}

export default ({groups, dataset}: {groups: GroupType[], dataset: DatasetType}) => {
    const dispatch = useDispatch()
    return <Scrollbars renderThumbVertical={ScrollThumb}>
        <Box gap='small' pad='small'>
            {
                dataset.properties?.schemaRef === 'sift_dataset_properties.schema.json' &&
                dataset.properties.filterStrings.map((fs, index) => (
                    <FilterString
                        key={`filter-string-${index}`}
                        groups={groups}
                        filterString={fs}
                        onChanged={f => dispatch(updateDataset(produce(dataset, d => {
                            if (d.properties?.schemaRef === 'sift_dataset_properties.schema.json') {
                                d.properties.filterStrings[index] = f
                            }
                        })))}
                        onDeleted={() => dispatch(updateDataset(produce(dataset, d => {
                            const properties = d.properties
                            if (properties?.schemaRef === 'sift_dataset_properties.schema.json') {
                                properties.filterStrings = properties.filterStrings.filter(f => f !== properties.filterStrings[index])
                            }
                        }))) }/>
                ))
            }
            <Button secondary label='Add Filter String'
                onClick={() => dispatch(updateDataset(produce(dataset, d => {
                    const groupId = groups[0]?.id
                    if (d.properties?.schemaRef === 'sift_dataset_properties.schema.json' && groupId) {
                        d.properties.filterStrings.push({ filter: '', type: 'include', groupId, schemaRef: 'sift_dataset_properties.schema.json#/definitions/filter_string' })
                    }
                })))}/>
        </Box>
    </Scrollbars>
}
