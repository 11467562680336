import { createAction } from "@reduxjs/toolkit"
import { toastr } from "react-redux-toastr"
import { PredictionSinkType, PredictionType } from "../../codegen/models/models"
import { createApiThunk } from "../../core/api"


export const getPredictions = createApiThunk<
    PredictionType[],
    void
>(
    'predictions/getPredictions',
    async api => await api.get('prediction'),
    error => error.type === 'api-error' && toastr.error('Get Predictions', error.message)
)

export const addPrediction = createApiThunk<
    PredictionType,
    {prediction: PredictionType}
>(
    'predictions/addPrediction',
    async (api, { prediction }) => {
        return await api.post('prediction', prediction)
    },
    error => error.type === 'api-error' && toastr.error('Add Prediction', error.message)
)

export const updatePrediction = createApiThunk<
    PredictionType,
    PredictionType
>(
    'predictions/updatePrediction',
    async (api, prediction) => await api.put(`prediction/${prediction.id}`, prediction),
    error => error.type === 'api-error' && toastr.error('Update Prediction', error.message)
)

export const getPredictionSink = createApiThunk<
    PredictionSinkType,
    { predictionSinkId: number }
>(
    'predictions/getPredictionSink',
    async (api, { predictionSinkId} ) => await api.get(`prediction-sink/${predictionSinkId}`),
    error => error.type === 'api-error' && toastr.error('Get Prediction Sink', error.message)
)

export const makePrediction = createApiThunk<
    void,
    { predictionId: number }
>(
    'predictions/makePrediction',
    async (api, { predictionId }) => {
        await api.post(`make-prediction/${predictionId}`)
        toastr.info('Predict', 'Prediction Requested')
    },
    e => e.type === 'api-error' && toastr.error('Predict', e.message)
)

export const deletePrediction = createApiThunk<
    void,
    { predictionId: Number }
>(
    'predictions/deletePrediction',
    async (api, {predictionId}) => {
        await api.delete(`prediction/${predictionId}`)
    },
    e => e.type === 'api-error' && toastr.error('Prediction Delete', e.message)
)

export const clearAddedPrediction = createAction('predictions/clearAddedPrediction')
