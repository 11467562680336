import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { filter, map } from "lodash";
import { toastr } from "react-redux-toastr";
import { AlgorithmType, LayerType, ModelExperimentLinkType, ModelType, NeuralNetworkType, PreprocessorType } from "../../codegen/models/models";
import { createApiThunk, IRequestable } from "../../core/api";
import { generateModel, SchemaInterface } from '../../core/components/Utils';
import { executeValidationPlans, getValidationPlans, ValidationResult } from '../../core/validation/validate';
import { addModelExperimentLink, addModelToNewExperiment } from "../experiment/actions";
import { ExtendedPreprocessorType } from '../model/components/Preprocessor';

//PREPROCESSORS------------------------------------
export const getAvailablePreprocessors = createApiThunk<
    [string],
    void
>(
    'model/getAvailablePreprocessors',
    async (api) => await api.get(`available-schemas?extends=model.schema.json%23/definitions/preprocessor`),
    e => e.type === 'api-error' && toastr.error('Get Preprocessor Schemas', e.message)
)

export const addPreprocessor = createApiThunk<
    ModelType,
    {
        preprocessorSchemaRef: string,
        model: ModelType
    }
>(
    'model/addPreprocessor',
    async (api, { model, preprocessorSchemaRef }, { dispatch }) => {
        const schema: SchemaInterface = await api.get(`schemas/${preprocessorSchemaRef.replace("#", "%23")}`)
        const newModel = produce(model, m => {
            if (!m.preprocessors) {
                m.preprocessors = []
            }
            const newPreprocessor = generateModel(schema, preprocessorSchemaRef)
            m.preprocessors.push((newPreprocessor as ExtendedPreprocessorType))
        })
        dispatch(updateModel(newModel))
    },
    e => e.type === 'api-error' && toastr.error('Add Preprocessor', e.message)
)

export const updatePreprocessor = createApiThunk<
    ModelType,
    {
        updatedPreprocessor: PreprocessorType,
        position: number
        model: ModelType
    }
>(
    'model/updatePreprocessor',
    async (_, { model, updatedPreprocessor, position }, { dispatch }) => {
        const newModel = produce(model, m => {
            if (!m.preprocessors) {
                m.preprocessors = []
            }
            m.preprocessors[position] = updatedPreprocessor
        })
        dispatch(updateModel(newModel))
    },
    e => e.type === 'api-error' && toastr.error('Add Preprocessor', e.message)
)

//ALGORITHMS----------------------------------------
export const getAvailableAlgorithms = createApiThunk<
    [string],
    void
>(
    'model/getAvailableAlgorithms',
    async (api) => await api.get(`available-schemas?extends=model.schema.json%23/definitions/algorithm`),
    e => e.type === 'api-error' && toastr.error('Get Algorithm Schemas', e.message)
)

export const setAlgorithm = createApiThunk<
    ModelType,
    {
        algorithmSchemaRef: string,
        model: ModelType
    }
>(
    'model/setAlgorithm',
    async (api, { model, algorithmSchemaRef }, {dispatch}) => {
      const schema: SchemaInterface = await api.get(`schemas/${algorithmSchemaRef.replace("#", "%23")}`)
      const newModel = produce(model, m => {
          m.algorithm = generateModel(schema, algorithmSchemaRef) as AlgorithmType
          if(m?.optimisationSetup?.parameters){
              m.optimisationSetup.parameters = []
          }
      })
      dispatch(updateModel(newModel))
    },
    e => e.type === 'api-error' && toastr.error('Set Algorithm', e.message)
)

export const updateAlgorithm = createApiThunk<
    ModelType,
    {
        updatedAlgorithm: AlgorithmType,
        model: ModelType
    }
>(
    'model/updateAlgorithm',
    async (_, { model, updatedAlgorithm }, { dispatch }) => {
        const newModel = produce(model, m => {
            m.algorithm = updatedAlgorithm
        })
        dispatch(updateModel(newModel))
    },
    e => e.type === 'api-error' && toastr.error('Set Algorithm', e.message)
)


//SPECIAL NEURAL NETS -----------------------------
export const addLayer = createApiThunk<
 ModelType,
 { model: ModelType,
   layerIndex: number,
   layerName: string,
   layerSchema: SchemaInterface }
 >(
   'model/addLayer',
    async(_, {model, layerIndex, layerName, layerSchema}, {dispatch}) => {
        const newModel = produce(model, m => {
            var newLayer = generateModel(layerSchema, `model.schema.json#/definitions/layer`) as LayerType
             newLayer.layerName = layerName;
            (m.algorithm as unknown as NeuralNetworkType).layers.splice(layerIndex, 0, newLayer)
        })
        dispatch(updateModel(newModel))
  },
    e => e.type === 'api-error' && toastr.error('Add NN Layer', e.message)
 )

//DATA MODEL --------------------------------------
export const getExistingModels = createApiThunk<
    ModelType[],
    void
>(
    'model/getExistingModels',
    async (api) => await api.get(`model`),
    e => e.type === 'api-error' && toastr.error('Get Existing Models', e.message)
)

export const createModel = createApiThunk<
    ModelType,
    {model: ModelType}
>(
    'model/createModel',
    async (api, { model }) => await api.post('model', model),
    e => e.type === 'api-error' && toastr.error('Create Model', e.message)
)

export const clearAddedModel = createAction('model/clearAddedModel')


export const updateModel = createApiThunk<
    ModelType,
    ModelType
>(
    'model/updateModel',
    async (api, model) => {
        return await api.put(`model/${model.id}`, model)
    },
    e => e.type === 'api-error' && toastr.error('Model Update', e.message)
)

export const deleteModel = createApiThunk<
    void,
    { modelId: Number }
>(
    'model/deleteModel',
    async (api, {modelId}) => {
        //New model Id included for future handling of behaviour on delete.
        await api.delete(`model/${modelId}`)
    },
    e => e.type === 'api-error' && toastr.error('Model Delete', e.message)
)

//TRAINING ------------------------------------
export const addToExperimentAndTrain = createApiThunk<
    void,
    { modelId: number, experimentName: string, projectId: number, mostRecentLink?: ModelExperimentLinkType }
>(
    'model-training/addToExperimentAndTrain',
    async (api, { modelId, experimentName, projectId, mostRecentLink }, { dispatch }) => {
        let link: ModelExperimentLinkType | undefined
        if (mostRecentLink === undefined) {
            const result = await dispatch(addModelToNewExperiment({ modelId, experimentName, projectId })) as any
            if (addModelToNewExperiment.fulfilled.match(result) && result.payload?.id !== undefined) {
                link = result.payload as ModelExperimentLinkType
            }
        } else {
            const result = await dispatch(addModelExperimentLink({ modelId, experimentId: mostRecentLink.experimentId })) as any
            if (addModelExperimentLink.fulfilled.match(result) && result.payload?.id !== undefined) {
                link = result.payload as ModelExperimentLinkType
            }
        }
        if (link !== undefined) {
            await api.post(`model-training/${link.modelId}`)
            toastr.info('Train Model', 'Model Training Requested')
        }
    },
    e => e.type === 'api-error' && toastr.error('Train Model', e.message)
)

export const trainModel = createApiThunk<
    void,
    { modelId: number }
>(
    'model-training/trainModel',
    async (api, { modelId }, { dispatch }) => {
        await api.post(`model-training/${modelId}`)
        toastr.info('Train Model', 'Model Training Requested')
        dispatch(getExistingModels())
    },
    e => e.type === 'api-error' && toastr.error('Train Model', e.message)
)

//UTILS----------------------------------------------
export const getSchema = createApiThunk<
    SchemaInterface,
    string
>(
    'schemas/getSchema',
    async (api, schemaRef) => await api.get(`schemas/${schemaRef.replace("#", "%23")}`),
    e => e.type === 'api-error' && toastr.error('Get Schema', e.message)
)

export const setSchema = createAction<PrepareAction<{schemaRef: string, schema: SchemaInterface }>>(
    'model/setSchema',
    (payload: { schemaRef: string, schema: SchemaInterface }) => ({ payload })
)

export const validate = createAction<PrepareAction<ValidationResult[]>>(
    'model/validate', ({ schemas, model }: { schemas: {[key: string]: IRequestable<SchemaInterface>}, model: ModelType }) => {
        const validSchemas = filter(map(
            schemas, (schemaRequestable, schemaRef) => (schemaRequestable.value ? { schemaRef, schema: schemaRequestable.value} : undefined)
        ), (vs): vs is {schemaRef: string, schema: SchemaInterface} => vs !== undefined)
        const validationPlans = getValidationPlans(validSchemas)
        return {
            payload: executeValidationPlans(model, model, validationPlans)
        }
    }
)