import { Box, Tab, Tabs, TextInput } from 'grommet'
import produce from 'immer'
import { filter } from 'lodash'
import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { DatasetType, EventFeatureType,FlagFeatureType, FlagType,  SiftDatasetPropertiesType, SiftDataSourceInfoType, SignalFeatureType, SignalType } from '../../../codegen/models/models'
import ScrollThumb from '../../../core/components/ScrollThumb'
import Flag from './Flag'
import Signal from './Signal'
import GroupedItemSelector from './GroupedItemSelector'
import Icon from '../../../core/components/Icon'
import { createFeaturesFromInstances } from './Instances'


export const isSignal = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is SignalFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature'
export const isFlag = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is FlagFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature'

export const featuresEqual = (l: (SignalFeatureType | FlagFeatureType | EventFeatureType), r: (SignalFeatureType | FlagFeatureType | EventFeatureType)) => {
    if ( l.groupId !== r.groupId || l.schemaRef !== r.schemaRef) {
        return false
    }
    if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') {
        return l.signalId === r.signalId
    } else if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature') {
        return l.flagId === r.flagId
    } else if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/event_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/event_feature') {
        return l.columnSpec === r.columnSpec
    }
    return false
}

export const addOrRemoveFeatures = (features: (SignalFeatureType | FlagFeatureType | EventFeatureType)[], add: boolean, dataset: DatasetType): DatasetType => {
    return produce(dataset, newDataset => {
        if (newDataset?.properties?.schemaRef === 'sift_dataset_properties.schema.json') {
            const currentFeatures = newDataset?.properties.features
            if (add) {
                const featuresToAdd = features.filter(f => !currentFeatures.find(currF => featuresEqual(f, currF)))
                if( newDataset.properties.featurized){
                    newDataset.properties.features = [...newDataset.properties.features, ...createFeaturesFromInstances(featuresToAdd, newDataset.properties.instances)]
                }
                else{
                    newDataset.properties.features = [...newDataset.properties.features, ...featuresToAdd]
                }
            } else {
                newDataset.properties.features = newDataset.properties.features.filter(currF => !features.find(f => featuresEqual(f, currF)))
            }
        }
        return newDataset
    })
}

export default ({dataSource, dataset, datasetProperties}: {dataSource: SiftDataSourceInfoType, dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType}) => {
    const signalFeatures = filter(datasetProperties.features, isSignal)
    const flagFeatures = filter(datasetProperties.features, isFlag)
    const [searchedSignals, setSearchedSignals] = useState(dataSource.signals)
    return <Box fill direction='column' gap='xsmall'>
        <Box fill pad={{bottom:'small'}}>
            <Tabs flex={true}>
                <Tab title="Signals">
                    <Scrollbars renderThumbVertical={ScrollThumb}>
                        <Box pad='small'>
                            <GroupedItemSelector<SignalType, SignalFeatureType>
                                items={searchedSignals}
                                groups={dataSource.groups}
                                features={signalFeatures}
                                dataset={dataset}
                                target={s => <Signal signal={s} size='s'/>}
                                createFeature={s => ({schemaRef: 'sift_dataset_properties.schema.json#/definitions/signal_feature', 
                                                      signalId: s.signalId, 
                                                      groupId: s.groupId, 
                                                      featureType: 'feature', 
                                                      id: `signal_${s.groupId}_${s.signalId}`
                                })}
                                getProp={v => v.signalId}
                            />
                        </Box>
                    </Scrollbars>
                </Tab>
                <Tab title="Flags">
                    <Scrollbars renderThumbVertical={ScrollThumb}>
                        <Box pad='small'>
                            <GroupedItemSelector<FlagType, FlagFeatureType>
                                items={dataSource.flags}
                                groups={dataSource.groups}
                                features={flagFeatures}
                                dataset={dataset}
                                target={f => <Flag flag={f} size='s'/>}
                                createFeature={f => ({schemaRef: 'sift_dataset_properties.schema.json#/definitions/flag_feature', 
                                                      flagId: f.flagId, 
                                                      groupId: f.groupId, 
                                                      featureType: 'feature',  
                                                      id: `flag_${f.groupId}_${f.flagId}`
                                })}
                                getProp={v => v.flagId}
                            />
                        </Box>
                    </Scrollbars>
                </Tab>
            </Tabs>
        </Box>
        <Box fill align='center' basis='15%' direction='row' gap='small' pad='xsmall' border='top'>
                <Icon icon='search' color='white' size='2x'/>
                <TextInput
                    placeholder='Search...' 
                    onChange={text  => {
                        const escapedText = text.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                        const exp = new RegExp(escapedText, 'i');
                        setSearchedSignals(searchedSignals.filter(o => exp.test(o.signalId)));
                      }}
                />    
        </Box>  
    </Box>
}