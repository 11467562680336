import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { setInnerPageUrl, setIsLoadingInnerPage } from "./actions"
import { IApp } from "./state"

export const app = createSlice<IApp, SliceCaseReducers<IApp>>({
  name: 'app',
  initialState: {  },
  reducers: {
  }, extraReducers: (builder) => {
      builder.addCase(setInnerPageUrl, (state, { payload }) => { 
        state.innerPageUrl = payload
        state.isLoadingInnerPage = false
      })
      builder.addCase(setIsLoadingInnerPage, (state, { payload }) => { state.isLoadingInnerPage = payload })
  }
})