//INTERFACES-----------------------------------
export type ValidationLevel = 'error' | 'warning'

export type ValidationConditionType = '>' | '>=' | '<' | '<=' | '=' | '!='

export interface ValidationCondition {
  type: ValidationConditionType
  value?: number
  property?: string
}

export interface ValidationRule {
  level: ValidationLevel
  message: string
  conditions: ValidationCondition[] // Must meet all conditions
}

export type SchemaType = 'number' | 'integer' | 'string' | 'object' | 'boolean'

export interface SchemaInterface {
  type: SchemaType,
  properties: {[key: string]: any & { validationRules?: ValidationRule[] }},
  definitions?: {[key: string]: object},
  items?: SchemaInterface
  extends?: string
  info?: string
}

export interface GeneratedInterface{
  schemaRef: string,
  [key: string]: any
}

//FUNCTIONS------------------------------------
export const schemaNameFromSchemaRef = (schemaRef: string) => {
  return schemaRef.split("/")[2]
}

export const displayNameFromSchemaRef = (schemaRef: string) => {
    let words = schemaNameFromSchemaRef(schemaRef).split("_")
    let name: string = capitaliseFirst({word: words[0]})
    for (let i = 1; i < words.length; i++){
        name += " "
        name += capitaliseFirst({word: words[i]})
    }
    return name
}

export const capitaliseFirst = ({word}: {word: string}) => {
  let capital: string = word[0].toUpperCase()
  capital = capital + word.substring(1);
  return capital
}
export const lowercaseFirst = (word: string) => {
  let capital: string = word[0].toLowerCase()
  capital = capital + word.substring(1);
  return capital
}

export const toCamelCase = (words: string | string[]) => {
  if(typeof words == 'string' || 'string[]'){
    let wordArray: string[] = []
    if(typeof words =='string'){ wordArray = words.split('_') }
    else{ wordArray = words }
    let camelCaseName: string = lowercaseFirst(wordArray[0])
    for (let i = 1; i < wordArray.length; i++){
        camelCaseName += capitaliseFirst({word: wordArray[i]})
    }
    return camelCaseName
  }
  else{ throw new Error('Invalid type for camel-case conversion') }
}

export const camelToDisplayCase = (name: string) => {
  let displayName = ""
  for(let i = 0; i < name.length; i++){
      if(i === 0){
          displayName += ((name.charAt(i)).toUpperCase())
      }
      else{
          if(isUpperCase(name.charAt(i))){
              displayName += " "
          }
          displayName += (name.charAt(i))
      }
  }
  return displayName
}

export const isLowerCase = (str: string) => {
   return str === str.toLowerCase() && str !== str.toUpperCase();
}

export const isUpperCase = (str: string) => {
  return str === str.toUpperCase() && str !== str.toLowerCase();
}


export const generateModel = (schema: SchemaInterface, schemaRef: string) => {
  const jsonObject: GeneratedInterface = { schemaRef: schemaRef, enabled: true }
  for(var key in schema.properties){
      if(schema.properties[key].type === "array") {
          jsonObject[key] = []
      } else if(schema.properties[key].type !== "object") {
          jsonObject[key] = schema.properties[key]?.default
      } else {
          const subJsonObject: GeneratedInterface = {
            schemaRef: schema.properties[key].ref
          }
          for(var objectKey in schema.properties[key].properties){
              const returnedValue = resolveSchemaKey(objectKey, schema.properties[key].properties[objectKey])
              subJsonObject[objectKey] = returnedValue
          }
          jsonObject[key] = subJsonObject
      }
  }
  return jsonObject
}

const resolveSchemaKey = (key: string, schemaProps: GeneratedInterface) => {
    if((schemaProps.enum || schemaProps.type) && schemaProps.type !== "array"){
        return schemaProps?.default
    }
    if(schemaProps.type === "array"){
        return []
    }
    if(schemaProps.type === "boolean"){
        return schemaProps?.default
    }
    else{
        resolveSchemaKey(key, schemaProps)
    }
}
