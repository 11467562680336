import React, { useState } from 'react';
import { TestModeType, ModelType, TestingOptionsType, DatasetType } from '../../../codegen/models/models'
import { useDispatch, useSelector } from 'react-redux'
import { updateModel } from '../actions'
import { Box, Text, Select } from 'grommet';
import InputRow from '../../../core/components/InputRow'
import produce from 'immer';
import { generateModel, SchemaInterface } from '../../../core/components/Utils';
import EditableText from '../../../core/components/EditableText'
import { InfoDropdown } from '../../../core/components/InfoDropdown';
import { IRequestable, useApiEffect } from '../../../core/api';
import { IApplicationState } from '../../../core/state';
import Requestable from '../../../core/components/Requestable';
import { getDatasets } from '../../data/actions';
import { isNil } from 'lodash';

const updateTestingOptions = (model: ModelType, testMode: TestModeType, value?: number) =>{
    if(model.testingOptions){
        var newTestingOptions = {...model.testingOptions,
                                    testMode: testMode
                                }
        if(value){
            if(testMode === "percentage_split"){
                newTestingOptions.percentageSplit = value
            }
            if(testMode === "isolated_test_set"){
                newTestingOptions.testSetId = value
            }
        }
        const newModel = produce(model, m => {
            m.testingOptions = newTestingOptions
        })
        return newModel
    }
    else{
        return model
    }
}


export default ({ model, projectId }: { model: ModelType, projectId?: number }) => {
    const modeOptions = ["percentage_split", "isolated_test_set"]
    const datasets = useSelector<IApplicationState, IRequestable<DatasetType[]>>(s => s.data.datasets)
    const [schema, setSchema] = useState<SchemaInterface>()
    const dispatch = useDispatch()
    const schemaRef = 'model.schema.json#/definitions/testing_options'
    useApiEffect(async api => {
        if (!model.testingOptions) {
            const schema = await api.get(`schemas/${schemaRef.replace('#', '%23')}`)
            setSchema(schema)
            dispatch(
                updateModel(produce(model, m => {
                    m.testingOptions = generateModel(schema, schemaRef) as TestingOptionsType
                }))
            )
        }
    }, [model.testingOptions])
    if (!model.testingOptions) {
        return <Text>Creating Test Options...</Text>
    }
    return <Box gap='small' pad='small'>
        <Box fill direction='row' gap='small'>
            { schema && <InfoDropdown info={schema.properties.testMode.info}/> }
            <InputRow label='Select Testing Mode'>
                <Select
                id="select"
                name="select"
                placeholder="Select Test Mode"
                options={ modeOptions }
                value={ model.testingOptions.testMode }
                onChange={ event => { dispatch(updateModel(updateTestingOptions(model, event.value))) }}
                />
            </InputRow>
        </Box>
        
        {
            model.testingOptions && model.testingOptions.testMode &&
                <Box>
                    {
                      model.testingOptions.testMode === "percentage_split" && model.testingOptions.percentageSplit ?
                        <Box fill direction='row' gap='small'>
                            { schema && <InfoDropdown info={schema.properties.percentageSplit.info}/> }
                            <InputRow label='Percentage'>
                              <EditableText key={'Percentage Editor'}
                                  initialValue={(model.testingOptions.percentageSplit).toString()}
                                  value={ (model.testingOptions.percentageSplit).toString() }
                                  placeholder=' '
                                  focus={false}
                                  onFinishEdit={v => !isNil(v) && dispatch(updateModel(updateTestingOptions(model,"percentage_split", parseFloat(v)))) }
                                  onAbortEdit={() => { }}
                              />
                          </InputRow>
                        </Box>:
                      model.testingOptions.testMode === "isolated_test_set" ?
                        <Box fill direction='row' gap='small'>
                            { schema && <InfoDropdown info={schema.properties.testSetId.info}/> }
                            <InputRow label='Test Set:' validation={[{    
                                    level: 'warning',
                                    message: 'CAUTION! DATASET FEATURES MUST MATCH!',
                                    propertyName: 'test_set_id'
                                }]}>
                                <Requestable
                                    requestable={datasets}
                                    action={getDatasets}
                                    render={
                                        (datasets) => {
                                            return <Select
                                                options={datasets.filter(d => d.projectId === projectId && isNil(d.sourceId))}
                                                value={datasets.filter(d => d.projectId === projectId && isNil(d.sourceId)).find(v => v.id === model.testingOptions?.testSetId)}
                                                labelKey={v => v.name}
                                                placeholder={'No Dataset Selected'}
                                                emptySearchMessage={'No Datasets Found'}
                                                onChange={({ value }) => dispatch(updateModel(updateTestingOptions(model, 'isolated_test_set', value.id)))}
                                            />
                                        }
                                    }
                                />
                            </InputRow>
                          </Box>:
                      <Text>No Values Found</Text>
                    }
                </Box>
        }
    </Box>
}
