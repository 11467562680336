import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { addConfig, deleteConfig, getConfigs, updateConfig } from "../actions/configActions"
import { ConfigState } from "../states/configState"

export const configs = createSlice<ConfigState, SliceCaseReducers<ConfigState>>({
    name: 'configs',
    initialState: { configs: { isRequesting: false } },
    reducers: { },
    extraReducers: (builder) => {
        builder.addCase(addConfig.fulfilled, (state, { payload: config }) => {
            if (state.configs.value !== undefined && config !== undefined ) {
                state.configs.value = [...state.configs.value, config]
            }
        })
        builder.addCase(getConfigs.pending, (state) => {
            if (state.configs.value === undefined) {
                state.configs = {
                    isRequesting: true
                }
            }
        })
        builder.addCase(getConfigs.fulfilled, (state, { payload: configs }) => {
            state.configs = {
                isRequesting: false,
                value: configs
            }
        })
        builder.addCase(getConfigs.rejected, (state, { error: { message }}) => {
            state.configs = {
                isRequesting: false,
                errorMessage: message
            }
        })
        builder.addCase(updateConfig.pending, (state, { meta: { arg: config }}) => {
            if (state.configs.value !== undefined && config.id !== undefined) {
                state.configs.value = state.configs.value.map(
                    s => s.id === config.id ? config : s
                )
            }
        })
        builder.addCase(updateConfig.rejected, () => {
            // TODO: Handle rejected case
        })
        builder.addCase(deleteConfig.pending, (state, { meta: { arg: { configId } }}) => {
            if (state.configs.value !== undefined) {
                state.configs.value = state.configs.value.filter(s => s.id !== configId)
            }
        })
        builder.addCase(deleteConfig.rejected, () => {
            // TODO: Handle rejected case
        })
    }
})