import { Box, Tab, Tabs } from 'grommet'
import { filter } from 'lodash'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { DatasetType, EventFeatureType, FeatureTypeType, FlagFeatureType, FlagType, SiftDatasetPropertiesType, SiftDataSourceInfoType, SignalFeatureType, SignalType } from '../../../codegen/models/models'
import { GroupedItemSelector } from '../../../core/components/GroupedItemSelector'
import ScrollThumb from '../../../core/components/ScrollThumb'
import Flag from '../../data/components/Flag'
import Signal from '../../data/components/Signal'
import { updateDataset } from '../../data/actions'

const isSignal = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is SignalFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature'
const isFlag = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is FlagFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature'


export default ({dataSource, dataset, datasetProperties, featureType}: {dataSource: SiftDataSourceInfoType, dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType, featureType: FeatureTypeType}) => {
    const signalFeatures = filter(datasetProperties.features, isSignal)
    const flagFeatures = filter(datasetProperties.features, isFlag)
    return <Box fill direction='column' gap='xsmall'>
    <Box fill pad={{bottom:'small'}}>
        <Tabs flex={true}>
            <Tab title="Signals">
                <Scrollbars renderThumbVertical={ScrollThumb}>
                    <Box pad='small'>
                        <GroupedItemSelector<SignalType, SignalFeatureType>
                            items={dataSource.signals}
                            groups={dataSource.groups}
                            features={signalFeatures}
                            dataset={dataset}
                            label={s => <Signal signal={s} size='s'/>}
                            createFeature={s => ({ schemaRef: 'sift_dataset_properties.schema.json#/definitions/signal_feature', 
                                                   signalId: s.signalId, 
                                                   groupId: s.groupId, 
                                                   featureType: featureType, 
                                                   id: `signal_${s.groupId}_${s.signalId}`
                            })}
                            getProp={v => v.signalId}
                            updateFunction={updateDataset}
                        />
                    </Box>
                </Scrollbars>
            </Tab>
            <Tab title="Flags">
                <Scrollbars renderThumbVertical={ScrollThumb}>
                    <Box pad='small'>
                        <GroupedItemSelector<FlagType, FlagFeatureType>
                            items={dataSource.flags}
                            groups={dataSource.groups}
                            features={flagFeatures}
                            dataset={dataset}
                            label={f => <Flag flag={f} size='s'/>}
                            createFeature={f => ({ schemaRef: 'sift_dataset_properties.schema.json#/definitions/flag_feature', 
                                                   flagId: f.flagId, 
                                                   groupId: f.groupId, 
                                                   featureType: featureType,  
                                                   id: `flag_${f.groupId}_${f.flagId}`
                            })}
                            getProp={v => v.flagId}
                            updateFunction={updateDataset}
                        />
                    </Box>
                </Scrollbars>
            </Tab>
        </Tabs>
    </Box>
</Box>
}
