import {  Box, Collapsible, Header, Text } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import { MultiRequestable } from '../components/Requestable'
import { IApplicationState } from '../state'
import { getDatasets } from '../../pages/data/actions'
import { IData } from '../../pages/data/state'
import { getExperiments } from '../../pages/experiment/actions'
import { IExperiment } from '../../pages/experiment/state'
import { getProjects } from '../../pages/project/actions'
import { IProjects } from '../../pages/project/state'
import { getExistingModels } from '../../pages/model/actions'
import { IModel } from '../../pages/model/state'


export default ({ isOpen }: { isOpen: boolean }) => {
    const projects = useSelector<IApplicationState, IProjects>(p => p.projects)
    const datasets = useSelector<IApplicationState, IData>(d => d.data)
    const models = useSelector<IApplicationState, IModel>(m => m.model)
    const experiments = useSelector<IApplicationState, IExperiment>(e => e.experiment)
    return <MultiRequestable
              requestables={[
                {requestable: projects.projects, action: getProjects},
                {requestable: datasets.datasets, action: getDatasets},
                {requestable: models.existingModels, action: getExistingModels},
                {requestable: experiments.experiments, action: getExperiments}
              ]}
              render={([currentProjects, currentDatasets, currentModels, currentExperiments]) =>
                      <Collapsible direction="horizontal" open={isOpen}>
                          <Box flex width="medium" background="dark-2" pad="small"
                               overflow='scroll' wrap={true} elevation="small" align='center'>
                              <Box>
                                  <Header align='center'>'Current Projects'</Header>
                                  <Box><ul>{ currentProjects.map((p: any) =>  <Text> Id: {p.id} - {p.description}</Text> )}</ul></Box>
                              </Box>
                              <Box>
                                  <Header align='center'>'Current Datsets'</Header>
                                  <Box><ul>{ currentDatasets.map((d: any) =>  <Text>`Name: {d.name} - {d.description}`</Text> )}</ul></Box>
                              </Box>
                              <Box>
                                  <Header align='center'>'Current Models'</Header>
                                  <Box> { currentModels.map((m: any) => <Text>`Name: {m.name} - {m.description}`</Text> )}</Box>
                              </Box>
                              <Box>
                                  <Header align='center'>'Current Experiments'</Header>
                                <Box><ul>{ currentExperiments.map((e: any) => <Text>`Name: {e.name} - {e.description}`</Text> )}</ul></Box>
                              </Box>
                          </Box>
                      </Collapsible>
                      }
           />
  }