import { Box, CheckBox, Select, Text } from 'grommet'
import produce, { current } from 'immer'
import { filter, isEqual } from 'lodash'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { DatasetType, EventFeatureType, FeatureTypeType, FlagFeatureType, InstanceIdType, SiftDatasetPropertiesType, SiftDataSourceInfoType, SignalFeatureType } from '../../../codegen/models/models'
import ScrollThumb from '../../../core/components/ScrollThumb'
import { updateDataset } from '../actions'
import Flag from './Flag'
import Signal from './Signal'

const isSignal = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is SignalFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature'
const isFlag = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is FlagFeatureType => v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature'


export default ({dataSource, dataset, datasetProperties}: {dataSource: SiftDataSourceInfoType, dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType}) => {
    const dispatch = useDispatch()
    const signalFeatures = filter(datasetProperties.features, isSignal)
    const flagFeatures = filter(datasetProperties.features, isFlag)
    const instances = datasetProperties.instances
    const featureLabel = (s: (SignalFeatureType | FlagFeatureType)) => {
        if (s.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') {
            const signal = dataSource.signals.find(v => v.signalId === s.signalId)
            return signal && <Signal signal={signal} size='s'/>
        } else if (s.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature') {
            const flag = dataSource.flags.find(v => v.flagId === s.flagId)
            return flag && <Flag flag={flag}  size='s'/>
        }
    }
    const setFeatureType = (dataset: DatasetType, matchFeature: (feature: (SignalFeatureType | FlagFeatureType | EventFeatureType)) => boolean ,  featureType: FeatureTypeType): DatasetType => {
        return produce(dataset, d => {
            if (d.properties?.schemaRef !== 'sift_dataset_properties.schema.json') return d
            const currentProperties = d.properties
            const indexToUpdate = currentProperties.features.findIndex(matchFeature)
            currentProperties.features[indexToUpdate].featureType = featureType
        })
    }
    const getInstanceId = (instanceId: InstanceIdType) => {
        const instance = datasetProperties.instances.find(i => i.id === instanceId.id)
        if(instance){
            return instance.displayName
        }
        else{ return }
    }
    return <Box fill direction='column' gap='xsmall'>
        <Box fill >
        {
            datasetProperties.features.length > 0 ?
            <Scrollbars renderThumbVertical={ScrollThumb}>
                <Box pad='small' gap='xsmall'>
                    { signalFeatures.length ? <SelectionHeader ft={'Signal'}/> : undefined }
                    {
                        signalFeatures.map(s => <Box direction='row' align='center' gap='small' key={`signal-feature-${s.id}`}>
                            <Box fill basis='1/2'>
                                <Text size='s'>{datasetProperties.instances.find(i => i.id === s.instanceId?.id)?.displayName} {featureLabel(s) || s.signalId} ({s.groupId})</Text>
                            </Box>
                            <Box fill basis='1/4'>
                                <CheckBox
                                    checked={ s.featureType === 'feature' ? true : false }
                                    onChange={({ target: { checked }}) => dispatch(updateDataset(
                                        setFeatureType(
                                            dataset,
                                            (f => f.schemaRef === s.schemaRef && f.groupId === s.groupId && f.signalId === s.signalId && isEqual(f.instanceId, s.instanceId)),
                                            checked ? 'feature': s.featureType === 'feature' ? 'excluded': 'target'
                                        )
                                    ))}
                                />
                            </Box>
                            <Box fill basis='1/4'>
                                <CheckBox
                                    checked={ s.featureType === 'target' ? true : false }
                                    onChange={({ target: { checked }}) => dispatch(updateDataset(
                                        setFeatureType(
                                            dataset,
                                            (ft => ft.schemaRef === s.schemaRef && ft.groupId === s.groupId && ft.signalId === s.signalId && isEqual(ft.instanceId, s.instanceId)),
                                            checked ? 'target': s.featureType === 'target' ? 'excluded': 'feature'
                                        )
                                    ))}
                                />
                            </Box>
                        </Box>)
                    }
                    { flagFeatures.length ? <Text alignSelf='center' weight='bold'>Flag Features</Text> : undefined }
                    {
                        flagFeatures.map(fl => <Box direction='row' align='center' gap='small' key={`flag-feature-${fl.groupId}-${fl.flagId}`}>
                            <Box fill>
                                <Text size='s'>{featureLabel(fl) || fl.flagId} ({fl.groupId})</Text>
                            </Box>
                            <Box fill basis='1/4'>
                                <CheckBox
                                    checked={ fl.featureType === 'feature' ? true : false }
                                    onChange={({ target: { checked }}) => dispatch(updateDataset(
                                        setFeatureType(
                                            dataset,
                                            (f => f.schemaRef === fl.schemaRef && f.groupId === fl.groupId && f.flagId === fl.flagId && isEqual(f.instanceId, fl.instanceId)),
                                            checked ? 'feature': fl.featureType === 'feature' ? 'excluded': 'target'
                                        )
                                    ))}
                                />
                            </Box>
                            <Box fill basis='1/4'>
                                <CheckBox
                                    checked={ fl.featureType === 'target' ? true : false }
                                    onChange={({ target: { checked }}) => dispatch(updateDataset(
                                        setFeatureType(
                                            dataset,
                                            (ft => ft.schemaRef === fl.schemaRef && ft.groupId === fl.groupId && ft.flagId === fl.flagId && isEqual(ft.instanceId, fl.instanceId)),
                                            checked ? 'target': fl.featureType === 'target' ? 'excluded': 'feature'
                                        )
                                    ))}
                                />
                            </Box>
                        </Box>)
                    }
                </Box>
            </Scrollbars> :
            <Box align='center' justify='center' fill>
                <Text>No Features Yet</Text>
                <Text>Use Signals tab to select Signals</Text>
                <Text>Use Flags tab to select Flags</Text>
            </Box>
        }
        </Box>
        <Box fill basis='15%' border='top'>

        </Box>
    </Box>
}

export const SelectionHeader = ({ ft }:{ ft: string }) => {
    return <Box direction='row' gap='xsmall'>
    <Box fill basis='1/2'>
        <Text alignSelf='center' weight='bold'>{ft} Name:</Text>
    </Box>
    <Box fill basis='1/4'>
        <Text weight='bold'>Feature:</Text>    
    </Box>
    <Box fill basis='1/4'>
        <Text weight='bold'>Target:</Text>
    </Box>
</Box>
}
