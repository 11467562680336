import React from 'react'
import ReactModal from 'react-modal'
import {
  Box, Button, Text,
} from 'grommet'

export default function Modal({
  isOpen, children, height, width,
}: { isOpen: boolean, children: any, height?: string, width?: string }) {
  const contentStyle = {
    content: {
      padding: 0,
      position: undefined,
      border: undefined,
      background: undefined,
    },
    overlay: {
      backgroundColor: 'rgba(43,46,46,0.55)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  }

  return (
    <ReactModal isOpen={isOpen} style={contentStyle} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Box fill={true} justify='center' align='center'>
        <Box round='xsmall' elevation='small' background='background-back' height={height || 'small'} width={width || 'medium'}>
          {children}
        </Box>
      </Box>
    </ReactModal>
  )
}

export function DeleteModal({
  isOpen, item, onCancel, onAccept, text,
}: { isOpen: boolean, item: string, onCancel: () => void, onAccept: () => void, text?: string }) {
  return (
    <Modal isOpen={isOpen}>
      <Box fill align='center' justify='evenly' pad='medium' height='auto'>
        <Text textAlign='center'>
          Are you sure you want to delete
          {' '}
          {text}
          {' '}
          "
          {item}
          "?
        </Text>
        <Box direction='row' fill='horizontal' justify='evenly'>
          <Button label='OK' onClick={onAccept} />
          <Button label='Cancel' onClick={onCancel} />
        </Box>
      </Box>
    </Modal>
  )
}
