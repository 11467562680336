import { Box, Text } from 'grommet'
import produce from 'immer'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatasetType, ProjectType } from '../../../codegen/models/models'
import ItemsTable, { CopyCell, DeleteCell, EditableCell, NameCell } from '../../../core/components/ItemsTable'
import { DeleteModal } from '../../../core/components/Modal'
import Requestable from '../../../core/components/Requestable'
import { IApplicationState } from '../../../core/state'
import { clearAddedDataset, createDataset, deleteDataset, getDataSource, updateDataset } from '../actions'
import { IData } from '../state'

export default ({ project, datasets }: { project: ProjectType, datasets: DatasetType[] }) => {
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const [deleting, setDeleting] = useState<number>()
    const [viewWizards, setViewWizards] = useState(false)
    const dispatch = useDispatch()
    return <Box>
        <ItemsTable
            clearAddedIdAction={clearAddedDataset}
            addedId={data.datasetAdded}
            name='Dataset'
            onCreate={(name?: string) => project.id && dispatch(createDataset({ 
                dataset: {
                    name: name ? name : 'New Dataset',
                    projectId: project.id,
                    dataSourceId: project.defaultDataSourceId,
                    schemaRef: 'dataset.schema.json',
                    purpose: 'train',
                    properties: {
                        schemaRef: 'sift_dataset_properties.schema.json',
                        filterStrings: [],
                        dateRange: {schemaRef: 'dates.schema.json#/definitions/date_range'},
                        instances: [],
                        features: [],
                        featurized: false
                    }
                }
            }))}
            searchParam='datasetId'
            items={!viewWizards ? datasets.filter(d => !d.wizardId): datasets}
            columns={[
                {
                    property: 'name',
                    header: 'Name',
                    render: (item) => <NameCell item={item} searchParam='datasetId' />
                },
                {
                    property: 'description',
                    header: 'Description',
                    render: (item) => <EditableCell value={item.description || ''} onChanged={v => dispatch(updateDataset(produce(item, d => { d.description = v })))} />
                },
                {
                    property: 'dataSourceId',
                    header: 'Data Source',
                    render: ({ dataSourceId }: DatasetType) => (
                        <Requestable
                            requestable={data.dataSource[dataSourceId]}
                            action={() => getDataSource({ dataSourceId })}
                            render={dataSource => <Text>{(dataSource.details?.['filename'])}</Text>} />
                    )
                },
                {
                    header: 'Copy',
                    property: 'id1',
                    size: 'xsmall',
                    render: (dataset: DatasetType) => {
                        return <CopyCell onCopy={() => dispatch(createDataset({dataset: {
                            ...dataset,
                            id: undefined,
                            name: `${dataset.name} (Copy)`
                        }}))} />
                    }
                },
                {
                    header: 'Delete',
                    property: 'id2',
                    size: 'xsmall',
                    render: (item: DatasetType) => {
                        return <DeleteCell onDelete={setDeleting} item={item}/>
                    }
                }
            ]}
        />
        <DeleteModal
            isOpen={deleting !== undefined}
            item={datasets.find(d => d.id === deleting)?.name || '?'}
            text={'Prediction'}
            onAccept={() => {
                deleting && dispatch(deleteDataset({datasetId: deleting}))
                setDeleting(undefined)
            }}
            onCancel={() => setDeleting(undefined)} />
    </Box>
}