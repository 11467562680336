import React from "react"
import { ProjectType } from "../../../codegen/models/models"
import { useIdSearchParam } from "../../../core/utils/router"
import Requestable from "../../../core/components/Requestable"
import { useSelector } from "react-redux"
import { getExistingModels } from "../actions"
import { IApplicationState } from "../../../core/state"
import { IModel } from "../state"
import { isNil } from "lodash"
import ModelConfiguration from "./ModelConfiguration"
import ModelsTable from "./ModelsTable"


export default ({ project }: { project: ProjectType }) => {
    const currentDataModelId = useIdSearchParam('modelId')
    const model = useSelector<IApplicationState, IModel>(s => s.model)
    return <Requestable
      requestable={model.existingModels}
      action={getExistingModels}
      render={models => {
        const currentDataModel = models.find(m => m.id === currentDataModelId)
        return currentDataModel && project.id ?
            <ModelConfiguration model={currentDataModel} dataSourceId={project.defaultDataSourceId} projectId={project.id} /> :
            <ModelsTable models={models.filter(m => m.projectId === project.id && isNil(m.sourceId))} project={project} />
        }} />
  }