import React from 'react'
import {  Box, Heading } from 'grommet'
import { BasisType, FillType } from 'grommet/utils'


export const PageSection = ({children, title, fill = true, basis, flex}:
                            {children?: string | React.ReactElement | React.ReactElement[],
                            fill?: FillType, title?: string, basis?: BasisType,
                            flex?: 'grow' | 'shrink' | boolean | { grow?: number; shrink?: number }}) => (
       <Box fill={fill} basis={basis} flex={flex} border='all' pad='xsmall' round='xsmall'
            style={getStyle(title)}>
            { title &&
                  <Box style={{ position: 'relative',
                                width: 'max-content',
                                bottom: '24px',
                                marginBottom: '-32px',
                                left: '10px',
                                background:'#00212B'}}>
                      <Heading size='14pt' margin='xsmall'>{title}</Heading>
                  </Box>
            }
            {children}
      </Box>
)

export default PageSection


const getStyle = (title?: string, alt?: string) => {
    let boxStyle = {
        borderWidth:'1.5px',
        marginTop:'0px',
        background: ' ',
        fill: 'true'
      }
      if(title){ boxStyle.marginTop = '15px' }
      if(alt){ boxStyle.background = '#002B36' }
      return boxStyle
}
