import { Box, Text } from "grommet"
import React from "react"
import { ValidationResult } from "../validation/validate"
import ValidationMessage from "./InputValidation"
import { InfoDropdown } from "./InfoDropdown"


export default ({ label, children, validation, info }: { label? : string, children?: React.ReactElement, validation?: ValidationResult[], info?: string }) => {
    const validationResult = validation?.find(r => r.level === 'error') || validation?.find(r => r.level === 'warning')
    return <Box direction='row' gap='small' align='center' fill='horizontal'>
        <Box basis='1/4' align='center' direction='row' justify='end'>
            {
                info && <InfoDropdown info={info}/>
            }
            <Text as='label' textAlign='end'>{label}</Text>
        </Box>
        <Box basis='3/4' direction='row' fill gap='small'>
            {children}
            {
                validationResult && <ValidationMessage result={validationResult} />
            }
        </Box>
    </Box>
}

