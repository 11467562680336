import { Box, Button, CheckBox, Heading, Select, Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeType, Tip } from 'grommet'
import { normalizeColor } from 'grommet/utils'
import { ScaleLoader } from 'halogenium'
import produce from 'immer'
import { flatMap, isEqual, isNil } from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import {
    DatasetType,
    EventFeatureType,
    ExperimentType, FlagFeatureType,
    ModelExperimentLinkType, ModelType,
    PredictionOverridesType,
    PredictionStatusType, PredictionType, ProjectType,
    SiftDatasetPropertiesType, SiftDataSourceInfoType,
    SiftPredictionTargetType, SignalFeatureType, SignalTargetType
} from '../../../codegen/models/models'
import Breadcrumbs from '../../../core/components/Breadcrumbs'
import DateRange from '../../../core/components/DateRange'
import EditableText from '../../../core/components/EditableText'
import Icon from '../../../core/components/Icon'
import InputRow from '../../../core/components/InputRow'
import Page from '../../../core/components/Page'
import PageSection from '../../../core/components/PageSection'
import Requestable, { MultiRequestable } from '../../../core/components/Requestable'
import ScrollThumb from '../../../core/components/ScrollThumb'
import useInterval from '../../../core/hooks/useInterval'
import { IApplicationState } from '../../../core/state'
import { getDateRange } from '../../../core/utils/dateRange'
import { useIdSearchParam } from '../../../core/utils/router'
import { getDatasets, getDataSource } from '../../data/actions'
import { InstanceSelector } from  '../../../core/components/InstanceSelector'
import { IData } from '../../data/state'
import { getExperiments, getModelExperimentLinks } from '../../experiment/actions'
import { IExperiment } from '../../experiment/state'
import { getExistingModels } from '../../model/actions'
import { IModel } from '../../model/state'
import { getProjects } from '../../project/actions'
import { IProjects } from '../../project/state'
import { getPredictions, getPredictionSink, makePrediction, updatePrediction } from '../actions'
import { IPredict } from '../state'
import PredictionsTable from './PredictionsTable'
import ErrorCat from '../../../core/components/ErrorCat'

const Dataset = ({ datasets, prediction, dataSourceInfo, selectedModel }: { datasets: DatasetType[], prediction: PredictionType, dataSourceInfo: SiftDataSourceInfoType, selectedModel: ModelType }) => {
    const dispatch = useDispatch()
    const currentDataset = (datasets as DatasetType[]).find(m => m.id === prediction.datasetId)
    return <Box gap='small' pad='small'>
        <CheckBox label='Override Dataset?' checked={isNil(prediction.datasetId) || prediction.datasetId !== selectedModel.datasetId} onChange={ ({ target: { checked }}) => {
            dispatch(updatePrediction(produce(prediction, p => { 
                p.datasetId = checked ? undefined : selectedModel.datasetId
                p.predictionTargets = []
            })))
        }} />
        {
            prediction.datasetId !== selectedModel.datasetId &&
            <Select
                options={datasets.filter(d => isNil(d.sourceId))}
                value={currentDataset}
                labelKey={m => m.name}
                placeholder={`No Dataset Selected`}
                emptySearchMessage={`No Datasets Found`}
                onChange={({ value }) => dispatch(updatePrediction(produce(prediction, p => {
                    p.datasetId = value.id
                    p.predictionTargets = []
                })))}
            />
        }
        <CheckBox label='Override Date Range?' checked={!isNil(prediction.overrides?.dateRange)} onChange={ ({ target: { checked }}) => {
            dispatch(updatePrediction(produce(prediction, p => {
                const overrides: PredictionOverridesType = p.overrides || { schemaRef: 'prediction.schema.json#/definitions/prediction_overrides' }
                overrides.dateRange = checked ? { schemaRef: 'dates.schema.json#/definitions/date_range' } : undefined
                p.overrides = overrides
            })))
        }} />
        {
            !isNil(prediction.overrides?.dateRange) && prediction.overrides?.dateRange &&
            <DateRange
                dateRange={prediction.overrides.dateRange}
                initialDateRange={getDateRange(dataSourceInfo.groups)}
                onUpdate={dr => dispatch(updatePrediction(produce(prediction, p => {
                    p.overrides = {...(p.overrides || {}), schemaRef: 'prediction.schema.json#/definitions/prediction_overrides', dateRange: dr}
                })))}/>
        }
        <CheckBox label='Override Instances?' checked={!isNil(prediction.overrides?.instances)} onChange={ ({ target: { checked }}) => {
            dispatch(updatePrediction(produce(prediction, p => {
                const overrides: PredictionOverridesType = p.overrides || { schemaRef: 'prediction.schema.json#/definitions/prediction_overrides' }
                overrides.instances = checked ? [] : undefined
                p.overrides = overrides
            })))
        }} />
        {
            currentDataset && !isNil(prediction.overrides?.instances) && prediction.overrides?.instances &&
            <InstanceSelector
                dataSource={dataSourceInfo}
                instances={prediction.overrides.instances}
                dataset={currentDataset}
                onUpdated={(instances, addOrRemove) => dispatch(updatePrediction(produce(prediction, p => {
                    const overrides: PredictionOverridesType = p.overrides || { schemaRef: 'prediction.schema.json#/definitions/prediction_overrides' }
                    overrides.instances = [
                        ...(overrides.instances?.filter(oi => instances.find(i => i.id === oi.id) === undefined) || []),
                        ...(addOrRemove ? instances : [])
                    ]
                })))}/>
        }
    </Box>
}

const TargetConfiguration = ({ sinkInfo, datasetProperties, prediction }: { sinkInfo: SiftDataSourceInfoType, datasetProperties: SiftDatasetPropertiesType, prediction: PredictionType }) => {
    const dispatch = useDispatch()
    const getFeatureName = (f: FlagFeatureType | SignalFeatureType | EventFeatureType) => {
        if (f.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature') {
            const sinkFlag = sinkInfo.flags.find(fl => fl.flagId === f.flagId)
            return `Flag ${sinkFlag?.displayName || f.flagId}`
        }
        if (f.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') {
            const sinkSignal = sinkInfo.signals.find(s => f.signalId === s.signalId)
            return `Signal ${sinkSignal?.displayName || f.signalId}`
        }
        return `Feature ID ${f.id}`
    }
    const updatePredictionTarget = (p: PredictionType, oldTarget: SiftPredictionTargetType, newTarget: SiftPredictionTargetType): PredictionType => {
        return produce(p, pp => {
            pp.predictionTargets = [
                ...(pp.predictionTargets?.filter(pt => !isEqual(pt, oldTarget)) || []),
                newTarget
            ]
        })
    }
    const datasetTargets = datasetProperties.features.filter(f => f.featureType === 'target')
    const targets: (SignalFeatureType | FlagFeatureType | EventFeatureType | undefined)[] = datasetTargets.length > 0 ? datasetTargets : [undefined]
    return <Box gap='small'>
        {
            targets.map(f => {
                const predictionTarget: SiftPredictionTargetType = prediction.predictionTargets?.find(pt => isNil(pt.feature) ? isNil(f) : isEqual(pt.feature, f)) || {
                    schemaRef: 'prediction.schema.json#/definitions/sift_prediction_target',
                    feature: f,
                    target: (f === undefined || f.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') ? {
                        schemaRef: 'prediction.schema.json#/definitions/signal_target'
                    } : {
                        schemaRef: 'prediction.schema.json#/definitions/flag_target'
                    }
                }
                const target = predictionTarget.target
                return <Box key={`feature-${f?.id || 'cluster-index'}`} gap='small' margin='small' background='background-front' pad='small'>
                    <Heading level='4' alignSelf='center' margin='xsmall'>{f ? getFeatureName(f) : 'Cluster Index'}</Heading>
                    <InputRow label='Group'>
                        <Select
                            options={sinkInfo.groups}
                            labelKey={g => g.id}
                            value={sinkInfo.groups.find(g => g.id === predictionTarget.target.groupId)}
                            placeholder={`No Group Selected`}
                            emptySearchMessage={`No Groups Found`}
                            onChange={({ value }) => {
                                dispatch(
                                    updatePrediction(
                                        updatePredictionTarget(
                                            prediction, predictionTarget,
                                            produce(predictionTarget, t => { t.target.groupId = value.id })
                                        )
                                    )
                                )
                            }}
                            />
                    </InputRow>
                    <InputRow label='Create New'>
                        <CheckBox checked={target.createNew || false} onChange={({target: {checked}}) => dispatch(
                            updatePrediction(
                                updatePredictionTarget(
                                    prediction, predictionTarget,
                                    produce(predictionTarget, t => {t.target.createNew = checked})
                                )
                            )
                        )} />
                    </InputRow>
                    {
                        target.schemaRef === 'prediction.schema.json#/definitions/signal_target' && (
                            target.createNew ?
                            <InputRow label='New Signal Name'>
                                <EditableText focus={false} onFinishEdit={value => dispatch(
                                    updatePrediction(
                                        updatePredictionTarget(
                                            prediction, predictionTarget,
                                            produce(predictionTarget, t => {
                                                if (t.target.schemaRef === 'prediction.schema.json#/definitions/signal_target') {
                                                    t.target.signalId = value
                                                }
                                            })
                                        )
                                    )
                                )} onAbortEdit={() => {}} placeholder='Enter a name for the new signal...'
                                value={target.signalId} initialValue={target.signalId}/>
                            </InputRow> :
                            <InputRow label='Signal'>
                                <Select
                                    options={sinkInfo.signals}
                                    labelKey={s => s.displayName || s.signalId}
                                    value={sinkInfo.signals.find(s => s.signalId === target.signalId && s.groupId === target.groupId)}
                                    placeholder={`No Signal Selected`}
                                    emptySearchMessage={`No Signals Found`}
                                    onChange={({ value }) => {
                                        dispatch(
                                            updatePrediction(
                                                updatePredictionTarget(
                                                    prediction, predictionTarget,
                                                    produce(predictionTarget, t => {
                                                        if (t.target.schemaRef === 'prediction.schema.json#/definitions/signal_target') {
                                                            t.target.signalId = value.signalId
                                                        }
                                                    })
                                                )
                                            )
                                        )
                                    }}
                                    />
                            </InputRow>
                        )
                    }
                    {
                        target.schemaRef === 'prediction.schema.json#/definitions/flag_target' && (
                            target.createNew ?
                            <InputRow label='New Flag Name'>
                                <EditableText focus={false} onFinishEdit={value => dispatch(
                                    updatePrediction(
                                        updatePredictionTarget(
                                            prediction, predictionTarget,
                                            produce(predictionTarget, t => {
                                                if (t.target.schemaRef === 'prediction.schema.json#/definitions/flag_target') {
                                                    t.target.flagName = value
                                                }
                                            })
                                        )
                                    )
                                )} onAbortEdit={() => {}} placeholder='Enter a name for the new flag...'
                                value={target.flagName} initialValue={target.flagName}/>
                            </InputRow> :
                            <InputRow label='Flag Name'>
                                <Select
                                    options={sinkInfo.flags}
                                    labelKey={f => f.displayName || f.signalId}
                                    value={sinkInfo.flags.find(f => f.displayName === target.flagName && f.groupId === target.groupId)}
                                    placeholder={`No Flag Selected`}
                                    emptySearchMessage={`No Flags Found`}
                                    onChange={({ value }) => {
                                        dispatch(
                                            updatePrediction(
                                                updatePredictionTarget(
                                                    prediction, predictionTarget,
                                                    produce(predictionTarget, t => {
                                                        if (t.target.schemaRef === 'prediction.schema.json#/definitions/flag_target') {
                                                            t.target.flagName = value.displayName
                                                        }
                                                    })
                                                )
                                            )
                                        )
                                    }}
                                    />
                            </InputRow>
                        )
                    }
                </Box>
            })
        }
    </Box>
}


const PredictionStatusIcon = ({ status }: { status: PredictionStatusType }) => {
    const theme = useContext(ThemeContext) as ThemeType
    const color = normalizeColor('brand', theme)
    return <Box fill align='start'>
        <Tip content={status}>
            <Box>
                {
                    status === 'running' || status === 'initialising' ? <ScaleLoader height='10px' width='2px'  color={color}/> :
                    <Icon icon={
                        status === 'succeeded' ? 'check-double' :
                        status === 'finalising' ? 'check' :
                        (status === 'failed_to_complete' || status === 'failed_to_initialise' || status === 'failed_to_finalise') ? 'times' :
                        'ellipsis-h'}/>
                }
            </Box>
        </Tip>
    </Box>
}

const PredictionTable = ({ currentPrediction, predictions, models }: { currentPrediction: PredictionType, predictions: PredictionType[], models: ModelType[]}) => {
    const dispatch = useDispatch()
    useInterval(() => dispatch(getPredictions()), 5000)
    return <Box pad='medium' fill>
        <Table>
            <TableHeader>
                <TableRow>
                    <TableCell scope='col' border='bottom'>Revision ID</TableCell>
                    <TableCell scope='col' border='bottom'>Model</TableCell>
                    <TableCell scope='col' border='bottom'>Time</TableCell>
                    <TableCell scope='col' border='bottom'>Status</TableCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {
                    predictions.filter(p => p.sourceId === currentPrediction.id).sort((l, r) => (r.revisionId || -1) - (l.revisionId || -1)).map(p => {
                        const model = models.find(m => m.id === p.modelId)
                        return <TableRow>
                            <TableCell>{p.revisionId}</TableCell>
                            <TableCell>{model && `${model.name} - Revision ${model.revisionId}`}</TableCell>
                            <TableCell>{moment.utc(p.revisionTime).format('Do MMM YYYY, HH:mm')}</TableCell>
                            <TableCell><PredictionStatusIcon status={p.predictionStatus} /></TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
    </Box>
}
type ModelSelectorItemType = ExperimentType | ModelExperimentLinkType

const ModelSelector = ({ models, experiments, modelExperimentLinks, selectedModel, currentPrediction }:
    { models: ModelType[], selectedModel?: ModelType, experiments: ExperimentType[], modelExperimentLinks: ModelExperimentLinkType[], currentPrediction: PredictionType}) => {
    const dispatch = useDispatch()
    const items = flatMap(experiments, e => [e, ...modelExperimentLinks.filter(l => l.experimentId === e.id && models.find(m => m.id === l.modelId))])
    const selectedItem = items.find(i => i.schemaRef === 'model_experiment_link.schema.json' && i.modelId === selectedModel?.id)
    return <Select
        key={`select-model-${currentPrediction.id}`}
        options={items}
        value={selectedItem}
        disabledKey={(i: ModelSelectorItemType) => (
            i.schemaRef === 'model_experiment_link.schema.json' && models.find(m => m.id === i.modelId)?.runStatus !== 'complete'
        )}
        labelKey={(i: ModelSelectorItemType) => {
            if (i.schemaRef === 'experiment.schema.json') {
                return <Box key={`model-selector-experiment-${i.id}`} direction='row' gap='small' align='baseline' style={{fontStyle: 'italic'}}>
                    <Icon icon='vials'/>
                    {i.name}
                </Box>
            } else {
                const model = models.find(m => m.id === i.modelId)
                return <Box key={`model-selector-model-${i.id}`} margin={{left: 'small'}} direction='row' gap='small' align='baseline'>
                    <Icon icon='vial'/>
                    {`${model?.name} - Revision ${model?.revisionId}`}
                </Box>
            }
        }}
        valueLabel={selectedModel ? (
            <Box key={`model-selector-selected-model-${selectedModel.id}`} margin='small' direction='row' gap='small' align='baseline'>
                <Icon icon='vial'/>
                {`${selectedModel?.name} - Revision ${selectedModel.revisionId}`}
            </Box>
         ) : 'No Model Selected'}
        emptySearchMessage={`No Models Found`}
        onChange={({ value }: { value: ModelSelectorItemType }) => {
            if (value.schemaRef === 'model_experiment_link.schema.json') {
                const modelId = value.modelId
                dispatch(updatePrediction(produce(currentPrediction, p => { 
                    p.modelId = modelId
                    p.datasetId = models.find(m => m.id === modelId)?.datasetId
                    p.predictionTargets = []
                })))
            }
        }}
    />
}

const Predict = ({ predictions, models, datasets, projects, experiments, modelExperimentLinks, projectId, predict, data }:
    { predictions: PredictionType[], models: ModelType[], datasets: DatasetType[], projects: ProjectType[], experiments: ExperimentType[], modelExperimentLinks: ModelExperimentLinkType[], projectId: number, predict: IPredict, data: IData}) => {
    const currentPredictionId = useIdSearchParam('predictionId')
    const currentPrediction = predictions.find(p => p.id === currentPredictionId)
    const currentProject = projects.find(p => p.id === projectId)
    if (!currentProject) {
        return <Text>No project found</Text>
    }
    return currentPrediction ?
        <PredictInfo
            predictions={predictions} currentPrediction={currentPrediction}
            models={models} datasets={datasets} experiments={experiments}
            modelExperimentLinks={modelExperimentLinks} predict={predict} data={data}
            project={currentProject}/> :
        <PredictionsTable predictions={predictions.filter(p => isNil(p.sourceId))} project={currentProject} />
}

const PredictInfo = ({ predictions, currentPrediction, models, datasets, experiments, modelExperimentLinks, project, predict, data }:
    { predictions: PredictionType[], currentPrediction: PredictionType, models: ModelType[], datasets: DatasetType[], experiments: ExperimentType[], modelExperimentLinks: ModelExperimentLinkType[], project: ProjectType, predict: IPredict, data: IData}) => {
    const dispatch = useDispatch()
    const selectedModel = (models as ModelType[]).find(m => m.id === currentPrediction?.modelId)
    const currentDatasetId = currentPrediction?.datasetId || selectedModel?.datasetId
    const currentDataset = (datasets as DatasetType[]).find(m => m.id === currentDatasetId)
    const currentDatasetProperties = currentDataset?.properties?.schemaRef === 'sift_dataset_properties.schema.json' ? currentDataset.properties : undefined
    const projectExperiments = (experiments as ExperimentType[]).filter(e => e.projectId === project.id)
    const projectModelExperimentLinks = (modelExperimentLinks as ModelExperimentLinkType[]).filter(l => projectExperiments.find(e => e.id === l.experimentId))
    return <Box fill gap='small'>
        <Box fill='horizontal'>
            <Breadcrumbs
                parentName='Predictions'
                name={currentPrediction?.name}
                description={currentPrediction.description}
                searchParamName='predictionId'
                onNameEdited={n => currentPrediction && dispatch(updatePrediction(produce(currentPrediction, p => {p.name = n})))}
                onDescriptionEdited={n => currentPrediction && dispatch(updatePrediction(produce(currentPrediction, p => {p.description = n})))}
                />
        </Box>
        {
            currentPrediction && project &&
            <Box direction='row' gap='small' fill>
                <Box gap='small' fill>
                    <PageSection basis='20%' title='Select Model'>
                        <ModelSelector
                            models={(models as ModelType[]).filter(m => m.projectId === project.id && !isNil(m.sourceId) && m.runStatus === 'complete')}
                            selectedModel={selectedModel}
                            currentPrediction={currentPrediction}
                            experiments={projectExperiments}
                            modelExperimentLinks={projectModelExperimentLinks}
                        />
                    </PageSection>
                    <PageSection fill basis='80%' title='Select Dataset'>
                        <Scrollbars renderThumbVertical={ScrollThumb}>
                            {
                                selectedModel ?
                                <Requestable
                                    requestable={data.dataSource[project.defaultDataSourceId]}
                                    action={() => getDataSource({ dataSourceId: project.defaultDataSourceId })}
                                    render={(dataSource) => <Dataset
                                        key={`prediction-dataset-${currentPrediction.id}`}
                                        datasets={(datasets as DatasetType[])}
                                        prediction={currentPrediction}
                                        dataSourceInfo={dataSource.info as any as SiftDataSourceInfoType}
                                        selectedModel={selectedModel} />
                                    }
                                /> : <Text>Select a model first...</Text>
                            }
                        </Scrollbars>
                    </PageSection>
                </Box>
                <Box fill gap='small'>
                    <PageSection title='Configure Targets' basis='70%' fill='vertical'>
                        <Scrollbars renderThumbVertical={ScrollThumb}>
                            {
                                currentDatasetProperties &&
                                <Requestable
                                    requestable={predict.predictionSink[project.defaultPredictionSinkId]}
                                    action={() => getPredictionSink({ predictionSinkId: project.defaultPredictionSinkId })}
                                    render={sink => <TargetConfiguration key={`target-config-${currentPrediction.id}`} sinkInfo={sink.info as any as SiftDataSourceInfoType} datasetProperties={currentDatasetProperties} prediction={currentPrediction} />}
                                />
                            }
                        </Scrollbars>
                    </PageSection>
                    <PageSection title='Predict' fill={false}>
                        <Box pad='small'>
                            <Button primary label='Predict' disabled={isNil(currentPrediction.id)} onClick={() => (
                                currentPrediction.id && dispatch(makePrediction({ predictionId: currentPrediction.id }))
                            )} />
                        </Box>
                    </PageSection>
                    <PageSection title='Prediction Status' fill={false} basis='30%'>
                        <Scrollbars renderThumbVertical={ScrollThumb}>
                            <PredictionTable currentPrediction={currentPrediction} predictions={predictions} models={models} />
                        </Scrollbars>
                    </PageSection>
                </Box>
            </Box>
        }
    </Box>
}

export default ({projectId}: {projectId: number}) => {
    const predict = useSelector<IApplicationState, IPredict>(s => s.predict)
    const model = useSelector<IApplicationState, IModel>(s => s.model)
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const projects = useSelector<IApplicationState, IProjects>(s => s.projects)
    const experiment = useSelector<IApplicationState, IExperiment>(s => s.experiment)
    return <Page><MultiRequestable
            requestables={[
                { requestable: predict.predictions, action: getPredictions },
                { requestable: model.existingModels, action: getExistingModels },
                { requestable: data.datasets, action: getDatasets },
                { requestable: projects.projects, action: getProjects },
                { requestable: experiment.experiments, action: getExperiments},
                { requestable: experiment.modelExperimentLinks, action: getModelExperimentLinks}
            ]}
            render={([predictions, models, datasets, projects, experiments, modelExperimentLinks]) => (
                <Predict
                    predictions={(predictions as PredictionType[]).filter(p => p.projectId === projectId)}
                    models={models}
                    datasets={datasets}
                    projects={projects}
                    experiments={experiments}
                    modelExperimentLinks={modelExperimentLinks}
                    projectId={projectId}
                    predict={predict}
                    data={data}
                />
            )
            }/></Page>
  }
