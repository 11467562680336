import React from 'react'
import { Box, Image, Text } from 'grommet'

export default ({message}: { message?: string }) => {
    return <Box fill align='center' direction='column' justify='center'>
        <Image sizes='small' src={require('../../cat_error.gif')}/>
        <Text size='large' weight='bold'>OH NO, SOMETHING BROKE...!</Text>
        {
          message &&
          <Text>{message}</Text>
        }
    </Box>
  }