import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { addPrediction, getPredictions, getPredictionSink, updatePrediction, deletePrediction, clearAddedPrediction } from "./actions"
import { IPredict } from "./state"

export const predict = createSlice<IPredict, SliceCaseReducers<IPredict>>({
    name: 'predict',
    initialState: {
        predictions: { isRequesting: false },
        predictionSink: { }
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getPredictions.pending, state => {
            if (!state.predictions.value) {
                state.predictions = { isRequesting: true }
            }
        })
        builder.addCase(getPredictions.fulfilled, (state, { payload }) => {
            state.predictions = { isRequesting: false, value: payload }
        })
        builder.addCase(getPredictions.rejected, (state, { error }) => {
            state.predictions = { isRequesting: false, errorMessage: error.message }
        })
        builder.addCase(addPrediction.pending, () => {
        })
        builder.addCase(addPrediction.fulfilled, (state, { payload }) => {
            const predictions = state.predictions.value
            if (predictions && payload) {
                predictions.push(payload)
                state.predictionAdded = payload.id
            }
        })
        builder.addCase(addPrediction.rejected, () => { })
        builder.addCase(updatePrediction.pending, (state, { meta: { arg: experiment } }) => {
            const predictions = state.predictions.value
            if (predictions) {
                state.predictions.value = predictions.map(e => e.id === experiment.id ? experiment : e)
            }
        })
        builder.addCase(updatePrediction.fulfilled, () => { })
        builder.addCase(updatePrediction.rejected, () => { })
        builder.addCase(getPredictionSink.pending, (state, { meta: { arg: { predictionSinkId }}}) => {
            state.predictionSink[predictionSinkId] = { isRequesting: true }
        })
        builder.addCase(getPredictionSink.fulfilled, (state, { payload: predictionSink, meta: { arg: { predictionSinkId }}}) => {
            state.predictionSink[predictionSinkId] = { isRequesting: false, value: predictionSink }
        })
        builder.addCase(getPredictionSink.rejected, (state, { error: { message }, meta: { arg: { predictionSinkId }}}) => {
            state.predictionSink[predictionSinkId] = { isRequesting: false, errorMessage: message }
        })

        builder.addCase(deletePrediction.fulfilled, (state, {meta: {arg: predId}}) => {
          if(state.predictions.value !== undefined){
            state.predictions.value = state.predictions.value.filter(p => p.id !== predId.predictionId)
          }
        })
        builder.addCase(clearAddedPrediction, state => state.predictionAdded = undefined)

        builder.addCase(deletePrediction.rejected, (state, { error: { message }}) => {
          // TODO! Handle Rejection!
        })

    }
})
