import { Text } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import Page from '../../../core/components/Page'
import Requestable from '../../../core/components/Requestable'
import { IApplicationState } from '../../../core/state'
import { getProjects } from '../../project/actions'
import { IProjects } from '../../project/state'
import ModelsLoader from './ModelsLoader'


export default ({projectId}: {projectId: number}) => {
  const projects = useSelector<IApplicationState, IProjects>(s => s.projects)
  return <Page>
    <Requestable
      requestable={projects.projects}
      action={getProjects}
      render={projects => {
        const currentProject = projects.find(p => p.id === projectId)
        if (!currentProject) {
          return <Text>Current Project Not Found</Text>
        }
        return <ModelsLoader
          project={currentProject}
          />
      }} />
  </Page>
}
