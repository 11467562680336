import { Box } from 'grommet'
import produce from 'immer'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { DatasetType, InstanceType, SiftDatasetPropertiesType, SiftDataSourceInfoType } from '../../../codegen/models/models'
import { InstanceSelector }  from '../../../core/components/InstanceSelector'
import ScrollThumb from '../../../core/components/ScrollThumb'
import { updateDataset } from '../../data/actions'


const addOrRemoveInstances = (instances: InstanceType[], addOrRemove: boolean, dataset: DatasetType): DatasetType => {
    return produce(dataset, (d) => {
        if (d.properties?.schemaRef !== "sift_dataset_properties.schema.json") {
            return d
        }
        const currentProperties = d.properties
        if (addOrRemove) {
            const instancesToAdd = instances.filter(i => !currentProperties.instances.find(ci => ci.groupId === i.groupId && ci.id === i.id))
            d.properties.instances = [...d.properties.instances, ...instancesToAdd]
        } else {
            d.properties.instances = [...d.properties.instances.filter(ci => !instances.find(i => i.groupId === ci.groupId && i.id === ci.id))]
        }
        return d
    })
}

export default ({dataSource, dataset, datasetProperties}: {dataSource: SiftDataSourceInfoType, dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType}) => {
    const dispatch = useDispatch()
    return <Box fill>
        <Scrollbars renderThumbVertical={ScrollThumb}>
            <InstanceSelector 
                dataset={dataset}
                dataSource={dataSource}
                instances={datasetProperties.instances}
                onUpdated={(instances, addOrRemove) => dispatch(updateDataset(addOrRemoveInstances(instances, addOrRemove, dataset)))} 
            />
        </Scrollbars>
    </Box>
}
