import React, { useState } from 'react';
import { ExperimentType, ModelExperimentLinkType, ModelType } from '../../../codegen/models/models'
import { Box, Button, CheckBox, Heading, Menu, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { MultiRequestable } from '../../../core/components/Requestable';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../../core/state';
import { addModelExperimentLink, addModelToNewExperiment, getExperiments, getModelExperimentLinks } from '../../experiment/actions';
import { IExperiment } from '../../experiment/state';
import { addToExperimentAndTrain } from '../actions';
import Modal from '../../../core/components/Modal';
import EditableText from '../../../core/components/EditableText';
import { IModel } from '../state';
import { last, sortBy } from 'lodash';
import InputRow from '../../../core/components/InputRow';
import { ValidationResult } from '../../../core/validation/validate';
import Icon from '../../../core/components/Icon';
import Scrollbars from 'react-custom-scrollbars';
import ScrollThumb from '../../../core/components/ScrollThumb';

export const TrainOptions = ({ model, projectId }: { model: ModelType, projectId: number }) => {
    return model.id ? <TrainModel model={model} projectId={projectId} /> : <></>
}

const ValidationResults = ({validationResults}: { validationResults: ValidationResult[]}) => {
    return <Box fill>
        <Scrollbars renderThumbVertical={ScrollThumb}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        validationResults.map(r => <TableRow>
                            <TableCell>{r.propertyName}</TableCell>
                            <TableCell>{r.level}</TableCell>
                            <TableCell>{r.message}</TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </Scrollbars>
    </Box>
}

const TrainModel = ({ model, projectId }: { model: ModelType, projectId: number }) => {
    const experiments = useSelector<IApplicationState, IExperiment>(s => s.experiment)
    const modelState = useSelector<IApplicationState, IModel>(s => s.model)
    const validationResults = useSelector<IApplicationState, ValidationResult[] | undefined>(s => s.model.validationResults)
    const isValid = validationResults !== undefined && !validationResults.find(r => r.level === 'error')
    const dispatch = useDispatch()
    const [isAddingNewExperiment, setIsAddingNewExperiment] = useState<{ shouldTrain: boolean } | undefined>()
    const [newExperimentName, setNewExperimentName] = useState<string | undefined>(undefined)
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)

    const modelsWithLinks = modelState.existingModels.value?.filter(m => (
        m.sourceId === model.id &&
        experiments.modelExperimentLinks.value?.find(l => l.modelId === m.id) !== undefined
    ))
    const defaultNewExperimentName = `Experiment for model ${model.name}`
    const mostRecentModel = modelsWithLinks && last(sortBy(modelsWithLinks, l => l.revisionId))
    const mostRecentLink = mostRecentModel && experiments.modelExperimentLinks.value?.find(l => l.modelId === mostRecentModel.id)
    return <MultiRequestable
        requestables={[
            { requestable: experiments.experiments, action: getExperiments },
            { requestable: experiments.modelExperimentLinks, action: getModelExperimentLinks }
        ]}
        render={([experiments, modelExperimentLinks]) => {
            const menuItems = experiments.filter((e: ExperimentType) => e.projectId === projectId).map((e: ExperimentType) => ({
                label: e.name,
                onClick: () => e.id !== undefined && model.id !== undefined && dispatch(addModelExperimentLink({ modelId: model.id, experimentId: e.id })),
                disabled: modelExperimentLinks.find((l: ModelExperimentLinkType) => l.modelId === model.id && l.experimentId === e.id) !== undefined
            }))
            const newExperimentItem = {
                label: <Text style={{ fontStyle: 'italic' }}>Create New Experiment</Text>,
                onClick: () => model.id !== undefined && setIsAddingNewExperiment({ shouldTrain: false })
            }
            return <Box gap='small' pad='small' direction='row'>
                <Box basis='1/2'>
                    <Menu label='Add to Experiment'
                        fill='horizontal'
                        justifyContent='center'
                        disabled={model.id === undefined || !isValid}
                        items={[newExperimentItem, ...menuItems]} />
                </Box>
                <Box basis='1/2'>
                    <Button fill primary
                        disabled={model.id === undefined || !isValid}
                        label='Train'
                        onClick={() => {
                            if (!model.id) return
                            if (mostRecentLink) {
                                dispatch(addToExperimentAndTrain({
                                    modelId: model.id,
                                    experimentName: newExperimentName || defaultNewExperimentName,
                                    projectId: projectId,
                                    mostRecentLink
                                }))
                            } else {
                                setIsAddingNewExperiment({ shouldTrain: true })
                            }
                        }}
                    />
                </Box>
                {
                    validationResults && validationResults?.length > 0 &&
                    <Box fill='vertical' justify='center'>
                        <Button onClick={() => setShowValidationErrors(true)}>
                            <Icon icon='exclamation-triangle' color={isValid ? 'orange' : 'red'} size='2x' />
                        </Button>
                    </Box>
                }
                <Modal isOpen={showValidationErrors} height='medium' width='large'>
                    <Box fill pad='small'>
                        <Box fill='horizontal' direction='row' justify='between'>
                            <Heading level={3} margin='small'>Validation Results</Heading>
                            <Button onClick={() => setShowValidationErrors(false)}>
                                <Icon icon='times' />
                            </Button>
                        </Box>
                        <Box fill>
                        {
                            validationResults && validationResults.length > 0 ?
                            <ValidationResults validationResults={validationResults} /> :
                            <Text>Model Valid</Text>
                        }
                        </Box>
                    </Box>
                </Modal>
                <Modal isOpen={isAddingNewExperiment !== undefined} height='medium' width='large'>
                    <Box fill pad='large' gap='large'>
                        <Heading level={3} margin='small'>Configure New Experiment</Heading>
                        <InputRow label='Experiment Name'>
                            <EditableText
                                initialValue={newExperimentName || defaultNewExperimentName}
                                placeholder='Enter an experiment name'
                                onFinishEdit={v => v && setNewExperimentName(v)}
                                onAbortEdit={() => setIsAddingNewExperiment(undefined)} />
                        </InputRow>
                        <InputRow label='Start Training'>
                            <CheckBox
                                checked={isAddingNewExperiment?.shouldTrain || false}
                                onChange={({ target: { checked: shouldTrain } }) => setIsAddingNewExperiment({ shouldTrain })}
                            />
                        </InputRow>
                        <Box fill justify='end'>
                            <Box direction='row' justify='evenly' fill='horizontal'>
                                <Button label='Add To Experiment' onClick={() => {
                                    const experimentName = newExperimentName || defaultNewExperimentName
                                    if (!model.id || !experimentName) {
                                        return
                                    }
                                    if (isAddingNewExperiment?.shouldTrain) {
                                        dispatch(addToExperimentAndTrain({ modelId: model.id, experimentName, projectId: projectId }))
                                    } else {
                                        dispatch(addModelToNewExperiment({ modelId: model.id, projectId: projectId, experimentName }))
                                    }
                                    setIsAddingNewExperiment(undefined)
                                }} />
                                <Button label='Cancel' onClick={() => setIsAddingNewExperiment(undefined)} />
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        }} />
}

export default TrainOptions;
