import { useAuth0 } from '@auth0/auth0-react'
import { ResponsiveScatterPlotCanvas, Serie } from '@bitbloom/nivo-scatterplot'
import { HeatMapData, ResponsiveHeatMap } from '@bitbloom/nivo-heatmap'
import { IconName } from '@fortawesome/fontawesome-common-types'
import { Anchor, Box, Button, CheckBox, DataTable, Image, Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeType, Tip } from 'grommet'
import { normalizeColor } from 'grommet/utils'
import { BeatLoader, DotLoader, GridLoader, ScaleLoader, SyncLoader } from 'halogenium'
import produce from 'immer'
import { chain, isNil, min, sortBy } from 'lodash'
import moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import ReactJson from 'react-json-view'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { ThemeContext } from 'styled-components'
import { ExperimentType, ModelExperimentLinkType, ModelType, ProjectType, RunStatusType, TrialType, ModelEvaluationType } from '../../../codegen/models/models'
import Api, { ApiContext, useApi } from '../../../core/api'
import { defaultChartProps, labelSpacing } from '../../../core/charts/Charts'
import Breadcrumbs from '../../../core/components/Breadcrumbs'
import ConfirmBox from '../../../core/components/ConfirmBox'
import Icon from '../../../core/components/Icon'
import Loader from '../../../core/components/Loader'
import Modal from '../../../core/components/Modal'
import Page from '../../../core/components/Page'
import PageSection from '../../../core/components/PageSection'
import { MultiRequestable } from '../../../core/components/Requestable'
import ScrollThumb from '../../../core/components/ScrollThumb'
import useInterval from '../../../core/hooks/useInterval'
import { IApplicationState } from '../../../core/state'
import { useIdSearchParam } from '../../../core/utils/router'
import { setInnerPageUrl, setIsLoadingInnerPage } from '../../app/actions'
import { getExistingModels, trainModel, updateModel } from '../../model/actions'
import { model } from '../../model/reducer'
import { IModel } from '../../model/state'
import { getProjects } from '../../project/actions'
import { IProjects } from '../../project/state'
import { deleteAllExperiments, deleteModelExperimentLink, getExperiments, getModelExperimentLinks, getTrainingMetric, setModelsChecked, updateExperiment } from '../actions'
import { IExperiment, TrainingMetricId } from '../state'
import { ProjectTrainingMetrics, TrainingMetric } from '../types'
import ExperimentsTable from './ExperimentsTable'

const Models = ({ experiment, models, modelExperimentLinks, modelsChecked, projectId }: { experiment: ExperimentType, models: ModelType[], modelExperimentLinks: ModelExperimentLinkType[], modelsChecked: number[], projectId: number}) => {
    const dispatch = useDispatch()
    const links = modelExperimentLinks.filter(l => l.experimentId === experiment.id)
    return <Scrollbars renderThumbVertical={ScrollThumb}>
        <Box pad='small' gap='small'>
            <DataTable
                columns={[
                    {
                        property: 'visible',
                        header: <CheckBox
                            checked={modelsChecked.find(m => modelExperimentLinks.find(l => l.modelId === m && l.experimentId === experiment.id) !== undefined) !== undefined}
                            onChange={ev => {
                                const models = modelExperimentLinks.filter(l => l.experimentId === experiment.id).map(l => l.modelId)
                                dispatch(setModelsChecked({ models, checked: ev.target.checked }))
                            }}
                        />,
                        render: (link: ModelExperimentLinkType) => {
                            const model = models.find(m => m.id === link.modelId)
                            return model && <CheckBox
                                checked={modelsChecked.find(m => m === model.id) !== undefined}
                                onChange={ev => {
                                    const link = modelExperimentLinks.find(l => l.modelId === model.id && l.experimentId === experiment.id)
                                    if (link && model.id) dispatch(setModelsChecked({ models: [model.id], checked: ev.target.checked }))
                                }}
                                />
                        }
                    },
                    {
                        property: 'name',
                        header: 'Name',
                        render: (link: ModelExperimentLinkType) => {
                            const model = models.find(m => m.id === link.modelId)
                            return model && <Text>{model.name}</Text>
                        }
                    },
                    {
                        property: 'revisionId',
                        header: 'Revision',
                        render: (link: ModelExperimentLinkType) => {
                            const model = models.find(m => m.id === link.modelId)
                            return model && <Text>{model.revisionId}</Text>
                        }
                    },
                    {
                        property: 'id',
                        header: 'Remove',
                        render: (link: ModelExperimentLinkType) => (
                            <Button plain onClick={() => dispatch(deleteModelExperimentLink(link))} margin={{left: 'auto'}}>
                                <Tip content='Delete Model'>
                                    <Box fill>
                                        <Icon icon='trash' color='background-front' size='sm' />
                                    </Box>
                                </Tip>
                            </Button>
                        )
                    }
                ]}
                data={links}
            />
            <ConfirmBox
                icon='trash'
                label='Delete All'
                confirmLabel={'Really Delete all Experiments?'}
                onConfirm={() => { dispatch(deleteAllExperiments({ projectId })) }}
                onReject={() => {}}
            />
        </Box>
    </Scrollbars>
}

export const Status = ({ status }: { status: RunStatusType }) => {
    const theme = useContext(ThemeContext) as ThemeType
    const color = normalizeColor('brand', theme)
    const getIcon = (type: RunStatusType): IconName => {
        switch (type) {
            case 'complete':
                return 'check'
            case 'failed_to_complete':
            case 'failed_to_initialise':
            case 'failed_to_stop':
                return 'times'
            case 'pending':
                return 'ellipsis-h'
            case 'stopped':
                return 'stop'
            case 'interrupted':
                return 'slash'
            default:
                return 'question'
        }
    }
    const getLoader = (status: RunStatusType) => {
        switch(status) {
            case 'initialising':
                return <SyncLoader size='4px' color={color}/>
            case 'starting':
                return <BeatLoader size='5px' color={color}/>
            default:
                return <ScaleLoader height='10px' width='2px' color={color}/>
        }
    }
    return <Box fill align='center'>
        <Tip content={status}>
            <Box>
                {
                    ['running', 'initialising', 'starting'].includes(status) ? getLoader(status)
                    : status === 'stop_requested' ? <DotLoader size='10px' color={color} /> :
                    <Icon icon={getIcon(status)}/>
                }
            </Box>
        </Tip>
    </Box>
}

type LogLevels = 'ERROR' | 'WARNING' | 'INFO'

const getLogLevelImage = (levelname: LogLevels) => {
    switch (levelname) {
        case 'ERROR':
            return <Icon icon='skull-crossbones' color='red'/>
        case 'WARNING':
            return <Icon icon='exclamation-triangle' color='yellow'/>
        case 'INFO':
            return <Icon icon='info-circle' color='white'/>
        default:
            return null;
    }
}

const LogsViewer = ({ modelId }: { modelId: number}) => {
    const url = `storage/model/${modelId}/logs.json`
    const [logs, setLogs] = useState<{levelname: LogLevels, message: string, trialId?: number, time: string, target?: string}[]>()
    const [error, setError] = useState<string>()
    const api = useApi()
    useEffect(() => {
        const update = async () => {
            try {
                const logs = api && await api.get(url)
                setLogs(logs)
            } catch {
                setError(`Unable to get logs for model ${modelId}`)
            }
        }
        if (logs === undefined && error === undefined && api !== undefined) update()
    }, [logs, error, api])
    return <Box fill>
        {
            logs ? <Scrollbars>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Trial ID</TableCell>
                            <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {
                        chain(logs)
                            .filter(l => l.target === 'user')
                            .orderBy(l => l.trialId || '')
                            .groupBy('trialId')
                            .toPairs()
                            .value()
                            .map(([trialId, logs]) => logs.map(l => {
                                const time = moment(l.time)
                                return <TableRow>
                                    <TableCell>{getLogLevelImage(l.levelname)}</TableCell>
                                    <TableCell>{l.message}</TableCell>
                                    <TableCell>{l.trialId || 'Head'}</TableCell>
                                    <TableCell>{`${time.format('ll')} ${time.format('LTS')}`}</TableCell>
                                </TableRow>
                            }))
                    }
                    </TableBody>
                </Table>
            </Scrollbars>
            : error ? <Text>{error}</Text>
            : <GridLoader />
        }
    </Box>
}

const formatDuration = (duration: number) => {
    if (duration < 60.0) {
        return duration.toFixed(1) + " s";
    }
    else if (duration < 3600.0) {
        return (duration / 60).toFixed(1) + " m";
    }
    else {
        return (duration / 3600).toFixed(1) + " h";
    }
}

const EvaluationScore = ({evaluation}: {evaluation: ModelEvaluationType}) => <>{evaluation.score && evaluation.score.toExponential(4)}</>
const EvaluationMetric = ({evaluation}: {evaluation: ModelEvaluationType}) => <>{evaluation.metric !== undefined ? evaluation.metric : null}</>
const TrialConfig = ({trial}: { trial: TrialType}) => <>{trial.config.map(c => `${c.name}=${c.value}`).join(', ')}</>
const TrialLoss = ({trial}: { trial: TrialType}) => <>{trial.loss?.toExponential(4)}</>
const TrainingTime = ({trainingTime}: {trainingTime: number | undefined}) => <>{trainingTime && formatDuration(trainingTime)}</>

const TrainingRuns = ({ modelsChecked, models }: { modelsChecked: number[], models: ModelType[] }) => {
    const matchTrialIds = (a: string, b: string) => a.slice(0, 5).toLowerCase() === b.slice(0, 5).toLowerCase()
    const dispatch = useDispatch()
    useInterval(() => dispatch(getExistingModels()), 5000)
    const [modelToView, setModelToView] = useState<ModelType | undefined>()
    const [viewTrials, setViewTrials] = useState<number[]>([])
    const [viewLogs, setViewLogs] = useState<number | undefined>()
    const [token, setToken] = useState<string | undefined>()
    const { getAccessTokenSilently } = useAuth0()
    useEffect(() => {
        const getToken = async () => {
            try {
                const t = await getAccessTokenSilently()
                setToken(t)
            } catch (e) {
                console.warn('Could not get token')
                setToken('')
            }
        }
        if (token === undefined) {
            getToken()
        }
    }, [token, getAccessTokenSilently])
    const api = useContext(ApiContext) || new Api()
    return <Box fill>
            <Scrollbars renderThumbVertical={ScrollThumb}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell scope='col' border='bottom'>View Trials</TableCell>
                        <TableCell scope='col' border='bottom'>Train</TableCell>
                        <TableCell scope='col' border='bottom'>Model</TableCell>
                        <TableCell scope='col' border='bottom'>Revision</TableCell>
                        <TableCell scope='col' border='bottom'>Status</TableCell>
                        <TableCell scope='col' border='bottom'>Evaluation Score</TableCell>
                        <TableCell scope='col' border='bottom'>Metric</TableCell>
                        <TableCell scope='col' border='bottom'>Best Trial Config</TableCell>
                        <TableCell scope='col' border='bottom'>Best Trial Loss</TableCell>
                        <TableCell scope='col' border='bottom'>Training Time</TableCell>
                        <TableCell scope='col' border='bottom'>View</TableCell>
                        <TableCell scope='col' border='bottom'>Logs</TableCell>
                        <TableCell scope='col' border='bottom'>Tensorboard</TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {
                    sortBy(modelsChecked.map(mid => models.find(m => m.id === mid)), m => m?.id || -1).map(m => {
                        if (!m?.id) return undefined
                        const trialsOpen = viewTrials.find(i => i === m.id)
                        const bestTrialId = m.bestTrialId
                        const bestTrial = bestTrialId ? m.trials?.find(t => matchTrialIds(t.trialId, bestTrialId)) : undefined
                        return <Fragment key={`table-rows-${m.id}`}>
                            <TableRow>
                                <TableCell>
                                    <Button disabled={!m.bestTrialId} onClick={() => {
                                        if (!m.id) return
                                        if (trialsOpen) {
                                            setViewTrials(viewTrials.filter(i => i !== m.id))
                                        } else {
                                            setViewTrials([...viewTrials, m.id])
                                        }
                                    }}>
                                        <Icon icon={trialsOpen ? 'chevron-down' : 'chevron-right'} />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {
                                        m.runStatus === 'running'  || m.runStatus === 'stop_requested' || m.runStatus === 'initialising' ?
                                        <Button disabled={m.runStatus !== 'running'} onClick={() => m.id && dispatch(updateModel(produce(m, md => {md.runStatus = 'stop_requested'})))}>
                                            <Icon icon='stop' />
                                        </Button> : <Button disabled={!isNil(m.bestTrialId)} onClick={() => m.id && dispatch(trainModel({ modelId: m.id }))}>
                                            <Icon icon='play'/>
                                        </Button>
                                    }
                                </TableCell>
                                <TableCell>{m.name}</TableCell>
                                <TableCell>{m.revisionId}</TableCell>
                                <TableCell><Status status={m.runStatus} /></TableCell>
                                <TableCell>{m.modelEvaluation && <EvaluationScore evaluation={m.modelEvaluation}/>}</TableCell>
                                <TableCell>{m.modelEvaluation && <EvaluationMetric evaluation={m.modelEvaluation}/>}</TableCell>
                                <TableCell>{bestTrial && <TrialConfig trial={bestTrial} />}</TableCell>
                                <TableCell>{bestTrial && <TrialLoss trial={bestTrial}/>}</TableCell>
                                <TableCell><TrainingTime trainingTime={m.runProperties?.totalTrainingTime}/></TableCell>
                                <TableCell>
                                    <Button onClick={() => setModelToView(m)}>
                                        <Icon icon='eye' />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button disabled={!(m.runStatus === 'complete' || m.runStatus === 'failed_to_complete')} onClick={() => setViewLogs(m.id)}>
                                        <Icon icon='file' />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Box fill='horizontal' align='center' justify='center'>
                                        <Button onClick={async () => {
                                            dispatch(setIsLoadingInnerPage(true))
                                            try {
                                                const {uri} = await api.get(`tensorboard/${m.id}`)
                                                dispatch(setInnerPageUrl(uri))
                                            } catch {
                                                dispatch(setInnerPageUrl(undefined))
                                                toastr.error('Tensorboard', 'Error loading tensorboard - may not be supported in this version')
                                            }
                                        }}>
                                            <Image src='/tensorboard-icon.png' height='16px' width='16px' />
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            {
                                trialsOpen && m.trials?.map(trial => {
                                    const isBestTrial = trial.trialId === bestTrial?.trialId
                                    return <TableRow style={{fontStyle: 'italic', fontWeight: isBestTrial ? 'bold' : 'normal'}}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><TrialConfig trial={trial}/></TableCell>
                                        <TableCell><TrialLoss trial={trial} /></TableCell>
                                        <TableCell><TrainingTime trainingTime={trial.trainingTime}/></TableCell>
                                    </TableRow>
                                })
                            }
                        </Fragment>
                    })
                }
                </TableBody>
            </Table>
        </Scrollbars>
        <Modal isOpen={modelToView !== undefined} width='large' height='medium'>
            <Box pad='small' fill>
                <Box direction='row' fill='horizontal' justify='between'>
                    {`${modelToView?.name} - Revision ${modelToView?.revisionId} @ ${moment.utc(modelToView?.revisionTime).format('Do MMM YYYY, HH:mm')}`}
                    <Button onClick={() => setModelToView(undefined)}><Icon icon='times'/></Button>
                </Box>
                <Scrollbars renderThumbVertical={ScrollThumb} renderThumbHorizontal={ScrollThumb}>
                    <Box gap='small' pad='small'>
                        {
                            modelToView && <ReactJson src={modelToView} theme='codeschool' name='model' />
                        }
                    </Box>
                </Scrollbars>
            </Box>
        </Modal>
        <Modal isOpen={viewLogs !== undefined} width='xlarge' height='large' >
            <Box direction='column' fill gap='small' pad='small' align='center'>
            {
                viewLogs &&
                <LogsViewer modelId={viewLogs} />
            }
            <Button label='OK' onClick={() => setViewLogs(undefined)}/>
            </Box>
        </Modal>
    </Box>
}

const ActualVsPredicted = ({ data }: { data: Serie[] }) => {
    const themeContext = useContext<ThemeType>(ThemeContext)
    return <ResponsiveScatterPlotCanvas
        data={data}
        {...defaultChartProps(themeContext)}
        {...labelSpacing({
            xAxis: { title: 'Actual' },
            yAxis: { title: 'Prediction' },
        })} />
}

const ActualVsPredictedMemo = React.memo(ActualVsPredicted)

const ResidualOverTime = ({ data }: {data: Serie[]}) => {
    const themeContext = useContext<ThemeType>(ThemeContext)
    return <ResponsiveScatterPlotCanvas
        data={data}
        xScale={{type: 'time', format: '%Y-%m-%dT%H:%M:%S'}}
        yScale={{type: 'linear', min: min(data[0].data.map(d => d.y)) as number}}
        {...defaultChartProps(themeContext)}
        {...labelSpacing({
            xAxis: { title: 'Time', format: '%b %d %H:%M' },
            yAxis: { title: 'Residual (Predicted - Actual)' },
        })} />

}

const ResidualOverTimeMemo = React.memo(ResidualOverTime)

const ConfusionMatrix = ({ data }: { data: HeatMapData }) => {
    const themeContext = useContext<ThemeType>(ThemeContext)
    return <ResponsiveHeatMap
        data={data.data}
        keys={data.keys}
        indexBy={data.indexBy}
        {...defaultChartProps(themeContext)}
        forceSquare={true}
        margin={{
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
        }}
        axisTop={{
          legend: "Predicted",
          legendPosition: "middle",
          legendOffset: -40
        }}
        axisLeft={{
          legend: "Actual",
          legendPosition: "middle",
          legendOffset: -50
        }}
    />
}

const ConfusionMatrixMemo = React.memo(ConfusionMatrix)

const VisContainer = ({name, children}: { name: string, children: React.ReactNode}) => {
    return <Box fill justify='center' align='center' gap='small'>
        <Text>{name}</Text>
        <Box fill='horizontal' height='medium'>
            {children}
        </Box>
    </Box>
}

interface ConfusionMatrixPlotData {
    id: string
    data: HeatMapData
}

const MetricVisualisation = ({ id, metric }: { id: TrainingMetricId, metric: TrainingMetric }) => {
    switch (id) {
        case 'actual-v-prediction':
            return <VisContainer name='Actual vs. Predicted'>
                <ActualVsPredictedMemo data={metric.data} />
            </VisContainer>
        case 'residual-over-time':
            return <VisContainer name='Residual'>
                <ResidualOverTimeMemo data={metric.data} />
            </VisContainer>
        case 'confusion-matrix':
            return <Box fill direction='row'>{
                metric.data.map((datum: ConfusionMatrixPlotData) => {
                    return <VisContainer name={'Confusion Matrix: ' + datum.id}><ConfusionMatrixMemo data={datum.data} /></VisContainer>
                })}
            </Box>
    }
}

const TrainingMetrics = ({ model, metrics }: { model: ModelType, metrics: TrainingMetricId[] }) => {
    const dispatch = useDispatch()
    const trainingMetrics = useSelector<IApplicationState, ProjectTrainingMetrics>(s => s.experiment.trainingMetrics)
    const metricData = metrics.map(m => ({id: m, data: model.id && trainingMetrics[model.id]?.[m]}))
    useEffect(() => {
        metricData.forEach(m => {
            if (model.runStatus === 'complete' && m.data === undefined && model.id) {
                dispatch(getTrainingMetric({modelId: model.id, metricId: m.id }))
            }
        })
    }, [metricData, dispatch, model])
    return <Box fill>
        {
            <Box fill direction='row' gap='small' align='center'>
                {model ? <Text style={{fontWeight: 'bold'}}>{model.name} Revision {model.revisionId}</Text> : <Text>Model Unknown</Text>}
                <Box fill direction='row'>
                    {
                        model.runStatus !== 'complete' ? <Text>Metrics will load when model completes...</Text> :
                        metricData.filter(m => m.data && m.data.type !== 'not-found').map(m => <Box fill key={`metric-vis-${model.id}-${m.id}`} pad='small' justify='center' align='center'>
                            {
                                m.data && m.data.type === 'loading' ? <Loader /> :
                                !m.data || m.data.type === 'not-found' ? <Text>Metric "{m.id}" not found</Text> :
                                <MetricVisualisation id={m.id} metric={m.data} />
                            }
                        </Box>)
                    }
                </Box>
            </Box>
        }
    </Box>
}

const ExperimentAnalysis = ({ modelsChecked }: { modelsChecked: number[] }) => {
    const models = useSelector<IApplicationState, (ModelType | undefined)[]>(s => modelsChecked.map(modelId => s.model.existingModels.value?.find(m => m.id === modelId)))
    return <Scrollbars renderThumbHorizontal={ScrollThumb}>
        <Box gap='small' pad='small'>
        {
            sortBy(models, m => m?.id || -1).map(m => m && <TrainingMetrics key={`training-metrics-${m.id}`} model={m} metrics={['actual-v-prediction', 'residual-over-time', 'confusion-matrix']} />)
        }
        </Box>
    </Scrollbars>
}

const ExperimentDetail = ({ experiment, modelExperimentLinks, models, project, modelsChecked }: { experiment: ExperimentType, modelExperimentLinks: ModelExperimentLinkType[], models: ModelType[], project: ProjectType, modelsChecked: number[]}) => {
    const dispatch = useDispatch()
    return <Box fill>
        <Box fill='horizontal' direction='row'>
            <Breadcrumbs
                parentName='Experiments'
                name={experiment.name}
                description={experiment.description}
                searchParamName='experimentId'
                onNameEdited={n => dispatch(updateExperiment(produce(experiment, m => {m.name = n})))}
                onDescriptionEdited={n => dispatch(updateExperiment(produce(experiment, m => {m.description = n})))}
                />
        </Box>
        <Box fill direction='row' pad='small' gap='small'>
            <PageSection title='Models' fill basis='20%'>
                <Scrollbars>
                    {
                        project.id &&
                        <Models projectId={project.id} experiment={experiment} modelExperimentLinks={modelExperimentLinks} models={(models as ModelType[]).filter(m => m.projectId === project.id)} modelsChecked={modelsChecked} />
                    }
                </Scrollbars>
            </PageSection>
            <Box fill gap='small' basis='80%'>
                <PageSection title='Training'>
                    <TrainingRuns modelsChecked={modelsChecked} models={(models as ModelType[]).filter(m => m.projectId === project.id)} />
                </PageSection>
                <PageSection title='Analysis'>
                    <ExperimentAnalysis modelsChecked={modelsChecked} />
                </PageSection>
            </Box>
        </Box>
    </Box>
}

const ExperimentLoader = ({ experiments, modelExperimentLinks, models, project, modelsChecked }: { experiments: ExperimentType[], modelExperimentLinks: ModelExperimentLinkType[], models: ModelType[], project: ProjectType, modelsChecked: number[]}) => {
    const currentExperimentId = useIdSearchParam('experimentId')
    const currentExperiment = experiments.find(e => e.id === currentExperimentId)
    return currentExperiment ?
        <ExperimentDetail experiment={currentExperiment} modelExperimentLinks={modelExperimentLinks} models={models} project={project} modelsChecked={modelsChecked} /> :
        <ExperimentsTable experiments={experiments} />
}

export default ({ projectId }: { projectId: number }) => {
    const experiment = useSelector<IApplicationState, IExperiment>(s => s.experiment)
    const models = useSelector<IApplicationState, IModel>(s => s.model)
    const projects = useSelector<IApplicationState, IProjects>(s => s.projects)
    return <Page>
        <MultiRequestable
            requestables={[
                { requestable: experiment.experiments, action: getExperiments },
                { requestable: experiment.modelExperimentLinks, action: getModelExperimentLinks },
                { requestable: models.existingModels, action: getExistingModels },
                { requestable: projects.projects, action: getProjects }
            ]}
            render={([experiments, modelExperimentLinks, models, projects]) => {
                const currentProject = (projects as ProjectType[]).find(p => p.id === projectId)
                return currentProject ?
                    <ExperimentLoader project={currentProject} experiments={(experiments as ExperimentType[]).filter(e => e.projectId === projectId)} modelExperimentLinks={modelExperimentLinks} models={(models as ModelType[]).filter(m => m.projectId === projectId)} modelsChecked={experiment.modelsChecked} /> :
                    <></>
            }}
        />
    </Page>
}
