import { createAction } from '@reduxjs/toolkit';
import { toastr } from "react-redux-toastr";
import { DatasetType, DataSourceType } from "../../codegen/models/models";
import { createApiThunk } from "../../core/api";

export const getDataSource = createApiThunk<
    DataSourceType,
    { dataSourceId: number }
>(
    'data/getDataSource',
    async (api, { dataSourceId }) => await api.get(`data-source/${dataSourceId}`),
    e => e.type === 'api-error' && toastr.error('Get Data Source Info', e.message)
)

export const getDatasets = createApiThunk<
    DatasetType[],
    void
>(
    'data/getDatasets',
    async (api) => await api.get(`dataset`),
    e => e.type === 'api-error' && toastr.error('Get Datasets', e.message)
)

export const createDataset = createApiThunk<
    DatasetType,
    { dataset: DatasetType }
>(
    'data/createDataset',
    async (api, { dataset }) => await api.post('dataset', dataset),
    e => e.type === 'api-error' && toastr.error('Create Dataset', e.message)
)


export const clearAddedDataset = createAction('data/clearAddedDataset')

export const deleteDataset = createApiThunk<
    void,
    { datasetId: Number }
>(
    'dataset/deleteDataset',
    async (api, {datasetId}) => {
      await api.delete(`dataset/${datasetId}`)
    },
    e => e.type === 'api-error' && toastr.error('Delete Dataset', e.message)
)


export const updateDataset = createApiThunk<
    DatasetType,
    DatasetType
>(
    'data/updateDataset',
    async (api, dataset) => await api.put(`dataset/${dataset.id}`, dataset),
    e => e.type === 'api-error' && toastr.error('Update Dataset', e.message)
)
