import { Box, Button, Heading, Image, Text } from 'grommet'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../../../core/components/Page'
import Requestable, { MultiRequestable } from '../../../core/components/Requestable'
import { IApplicationState } from '../../../core/state'
import { getProjects } from '../../project/actions'
import { IProjects } from '../../project/state'
import { IData } from '../../data/state' 
import { DatasetType, FeatureTypeType, ProjectType, QuestionType, RunStatusType, SiftDatasetPropertiesType, 
         SiftDataSourceInfoType, WizardType } from '../../../codegen/models/models'
import { doWizardry, createModels} from '../actions'
import { produce } from 'immer'
import WizardFeatures from './WizardFeatures'
import { createDataset, getDatasets, getDataSource } from '../../data/actions'
import PageSection from '../../../core/components/PageSection'
import Questions from './Questions'
import WizardDatasetViewer from './WizardDatasetViewer'
import WizardInstances from './WizardInstances'
import WizardDateRange from './WizardDateRange'
import ErrorCat from '../../../core/components/ErrorCat'
import Phase from './Phase'
import WizardResults from './WizardResults'
import { addWizard, getWizards, updateWizard } from '../../../codegen/actions/wizardActions'
import useInterval from '../../../core/hooks/useInterval'
import { WizardState } from '../../../codegen/states/wizardState'



export default ({projectId}: {projectId: number}) => {
  const [currentWizardId, setCurrentWizardId] = useState<number | undefined>(undefined)
  const [currentPhase, setCurrentPhase] = useState(0)
  const [currentDatasetId, setCurrentDatasetId] = useState<number | undefined>(undefined)
  const projectState = useSelector<IApplicationState, IProjects>(s => s.projects)
  const wizardsState = useSelector<IApplicationState, WizardState>(w => w.wizards)  
  const datasetState = useSelector<IApplicationState, IData>(d => d.data)
  return <Page>
    <MultiRequestable
      requestables={[
        {requestable: projectState.projects, action: getProjects},
        {requestable: datasetState.datasets, action: getDatasets},
        {requestable: wizardsState.wizards, action: getWizards},
      ]}
      render={([projects, datasets, wizards]) => {
        const project = (projects as ProjectType[]).find(p => p.id === projectId)
        const currentWizard = wizards? (wizards as WizardType[]).find(w => w.id === currentWizardId): undefined
        const currentDataset = datasets ? (datasets as DatasetType[]).find(d => d.id === currentDatasetId) : undefined
        return <Box fill direction='column' gap='small' alignContent='center'>
          <Box fill>
            {
              phases(
                currentPhase, 
                setCurrentPhase, 
                setCurrentWizardId, 
                setCurrentDatasetId, 
                currentWizard, 
                currentDataset, 
                project
              )
            }
          </Box>
        </Box>
      }}
    />
  </Page>
}

const phases = (
    currentPhase: number, 
    setCurrentPhase: Dispatch<SetStateAction<number>>,
    setCurrentWizardId: Dispatch<SetStateAction<number | undefined>>,
    setCurrentDatasetId: Dispatch<SetStateAction<number | undefined>>,
    currentWizard?: WizardType, 
    currentDataset?: DatasetType,
    project?: ProjectType
  ) => {  
  if(currentPhase === 0){
    if (project){
      return <Phase0 
        project={project}  
        setPhase={setCurrentPhase} 
        setCurrentWizardId={setCurrentWizardId}/>}
  }
  if(currentWizard){
    if(currentPhase === 1){
      if(project){
        return <Phase1 
          wizard={currentWizard}
          project={project}
          setPhase={setCurrentPhase} 
          setCurrentDatasetId={setCurrentDatasetId}/>
      }
    }
    if(currentPhase === 2){
      if(currentDataset){
        return <Phase2 
          wizard={currentWizard} 
          dataset={currentDataset} 
          dataSourceId={currentDataset.dataSourceId} 
          setPhase={setCurrentPhase}
        />
      }
    }
    if(currentPhase === 3){
      if(currentDataset && project?.id){
        return <Phase3 
          wizard={currentWizard}
          dataset={currentDataset}
          dataSourceId={currentDataset.dataSourceId}
          projectId={project.id}
          setPhase={setCurrentPhase}
        />
      }
    }
  }
  return <ErrorCat message='Phase Number is Invalid!'/>
}


// ------------ PHASES --------------
export const Phase0 = ({project, setPhase, setCurrentWizardId}: 
  {project: ProjectType, setPhase: (value: number) => void, setCurrentWizardId: Dispatch<SetStateAction<number | undefined>> }) => {
  const dispatch = useDispatch()
  return <Phase
    content={
      <Box align='center'>
        <Box animation={{'type':'slideDown', 'duration':10000, 'size':'medium'}}>
          <Image style={{zIndex: 0}} src={require('../../../cat_wizard.gif')}/>
        </Box>
        <Box animation={{'type':'slideUp', 'duration':10000, 'size':'medium'}}> 
          <Image  style={{zIndex: 1, position:'relative', bottom:'200px'}} src={require('../../../crystal_ball.gif')}/>
        </Box>
        <Box align='center' gap='small' margin='large'>
          <Text size='xxlarge' weight='bold'>Welcome to the Sift AML Wizard!</Text>
          <Text size='large'>The Wizard will guide you through the process of setting up a Machine Learning Problem...</Text>
        </Box>
      </Box>
    }
    controls={
      <Box alignSelf='center' width='250px' height='50px'>
        <Button primary fill label='Get Started!' 
          onClick={async () => {
            const {payload: newWizard} = await dispatch<any>(addWizard({
              schemaRef: 'wizard.schema.json',
              questions: setupQuestions() as QuestionType[],
              datasetId: undefined
            }))
            setCurrentWizardId(newWizard.id)
            setPhase(1)
          }} 
        />
      </Box>
    }
  />
}

export const Phase1 = ({ wizard, project, setPhase, setCurrentDatasetId }: 
    { wizard: WizardType, project: ProjectType, setPhase: (value: number) => void, setCurrentDatasetId: Dispatch<SetStateAction<number | undefined>> }) => {
  const [selected, setSelected] = useState('none')
  const dispatch = useDispatch()
  return <Phase 
    header={
      <WizardHeader 
          textA="First, we need some information on the kind of problem you are trying to solve!"
          textB="What Kind of Problem Are You Trying to Solve...?"
          phase={1}
      />
    }
    content={
      <Box fill direction='column' gap='large' align='center'>
        <Box basis='1/3' fill direction='column' gap='small' align='center'>
          <Button 
            primary={selected === 'classification' ? true : false} 
            label='Classification' 
            onClick={() => {
               setSelected('classification')
               dispatch(updateWizard(produce(wizard, w => {
                w.problemClass = 'classification'
                })))
            }} 
          />
          <Button 
            primary={selected === 'regression' ? true : false} 
            label='Regression' 
            onClick={() => { 
              setSelected('regression')
              dispatch(updateWizard(produce(wizard, w => {
                w.problemClass = 'regression'
              })))
            }}
          />
          <Button 
            primary={selected === 'clustering' ? true : false} 
            label='clustering' 
            onClick={() => { 
              setSelected('clustering') 
              dispatch(updateWizard(produce(wizard, w => {
                w.problemClass = 'clustering'
              })))
            }} 
          />
          <Text size='large'>Or...</Text>
          <Button 
            primary={selected === 'none' ? true : false} 
            label="I Don't Know!"
          />
        </Box>
        <Questions wizard={wizard} />
      </Box>
    }
    controls={
      <Box alignSelf='center' width='250px' height='50px'>
        <Button 
          fill
          label='Continue...' 
          onClick={async () => { 
            if(project?.id){
              const {payload: newDataset} = await dispatch<any>(createDataset(
                {
                  "dataset": {
                    name: 'New Dataset From Wizard',
                    projectId: project.id,
                    wizardId: wizard.id,
                    dataSourceId: project.defaultDataSourceId,
                    schemaRef: 'dataset.schema.json',
                    purpose: 'train',
                    properties: {
                        schemaRef: 'sift_dataset_properties.schema.json',
                        filterStrings: [],
                        dateRange: {schemaRef: 'dates.schema.json#/definitions/date_range'},
                        instances: [],
                        features: [],
                        featurized: false
                      }
                    }
                  }           
                )
              )
              setCurrentDatasetId(newDataset.id)
              dispatch(updateWizard(produce(wizard, w =>{
                w.datasetId = newDataset.id
              })))
              setPhase(2)
            }
          }}
        />
      </Box>
    }
  />
}
    
export const Phase2 = ({ wizard, dataset, dataSourceId, setPhase }:
   { wizard: WizardType, dataset: DatasetType, dataSourceId: number, setPhase: (value: number) => void })  => {
  const data = useSelector<IApplicationState, IData>(s => s.data)
  const [mode, setMode] = useState('targets')
  const dispatch = useDispatch()
  return <Requestable
  requestable={data.dataSource[dataSourceId]}
  action={() => dataset && getDataSource({ dataSourceId: dataset.dataSourceId })}
  render={dataSource => <Phase 
    header={
      <WizardHeader 
        textA="Next, let's take a look at the data that you have available!"
        textB={ 
          mode ==='targets' ? 
            "Select Signal/Flags to use as the TARGETS for this problem..." :
          mode ==='features' ? 
            "Select Signal/Flags to use as the FEATURES for this problem..." : 
          mode ==='date/instances' ? 
            "Select The Date Range and Instances for this problem..." : 
          ''
        }
        phase={2}
      />
    }
    content={
      <Box fill direction='row' align='center' gap='small'>
        <Box fill basis='1/2' align='center'>
          {
            dataset ? <WizardDatasetViewer 
            dataSource={dataSource.info as any as SiftDataSourceInfoType}
            datasetProperties={dataset.properties as any as SiftDatasetPropertiesType}
            />:
            <ErrorCat message='No DataSource Found!'/>
          }
        </Box>
        <Box fill basis='1/2' align='center' gap='small'>
          <PageSection fill title='Available Data: '>
              <Box fill>
                {
                  mode === 'targets' ?
                    <WizardFeatures  
                      dataSource={dataSource.info as any as SiftDataSourceInfoType}
                      dataset={dataset}
                      datasetProperties={dataset.properties as any as SiftDatasetPropertiesType}
                      featureType={'target' as FeatureTypeType}
                    />:
                  mode === 'features' ?
                    <WizardFeatures  
                      dataSource={dataSource.info as any as SiftDataSourceInfoType}
                      dataset={dataset}
                      datasetProperties={dataset.properties as any as SiftDatasetPropertiesType}
                      featureType={'feature' as FeatureTypeType}
                    />:
                  mode === 'date/instances'?
                    <Box fill direction='column' gap='small' align='center'>
                      <Text size='large' weight='bold'>Date Range:</Text>
                      <WizardDateRange 
                        groups={(dataSource.info as any as SiftDataSourceInfoType).groups}
                        dataset={dataset}
                      />
                      <Text size='large' weight='bold'>Instances:</Text>
                      <WizardInstances
                        dataSource={(dataSource.info as any as SiftDataSourceInfoType)}
                        dataset={dataset}
                        datasetProperties={dataset.properties as any as SiftDatasetPropertiesType}                     
                      />
                    </Box>:
                    <ErrorCat message='Dataset Selection Mode is Invalid!'/>
                    }
                  </Box>
            </PageSection>
        </Box>
      </Box>
    }
    controls={
      <Box align='center' margin='small'>
        {
          mode === 'targets' ?
            <Box align='center' direction='row' gap='small' justify='center'>
              <Button label='Go Back!' onClick={()=>{ dispatch(setPhase(1)) }}/>
              <Button label='Continue...' onClick={()=>{ setMode('features') }}/>
            </Box>:
          mode === 'features' ?
            <Box align='center' direction='row' gap='small' justify='center'>
              <Button label='Go Back!' onClick={()=>{ setMode('targets') }}/>
              <Button label='Continue...' onClick={()=>{ setMode('date/instances') }}/>
            </Box>:
          mode === 'date/instances'?
            <Button label='Perform Wizardry...!' onClick={()=>{ 
              wizard.id && dispatch(doWizardry({ wizardId: wizard.id }))
              setPhase(3) 
            }}/>
          :<ErrorCat message='Dataset Selection Mode Is Invalid!'/>
        }
      </Box>
    }
  />
  }/>
}

export const Phase3 = ({ wizard, dataset, projectId, setPhase}:
   { wizard: WizardType, dataset: DatasetType, dataSourceId: number, projectId: number, setPhase: (value: number) => void }) => {
  const catSayings = [
    'Performing Wizardry!',
    'Consulting Spellbooks...',
    'Performing Seances...',
    'Consulting Future Versions of you...',
    'Asking the Magic-8-Ball...',
    'Catnapping... zzz ...',
    'Chasing Shiny Things...!',
    'Channeling Ancient Wisdom....',
  ]
  const [currentSaying, setCS] = useState(catSayings[0])
  const dispatch = useDispatch()
  
  useInterval(() => {
      if (wizard.wizardStatus !== 'complete'){
        setCS(catSayings[Math.floor(Math.random() * catSayings.length)])
        dispatch(getWizards())
      }
  }, 5000)
  return <Box fill direction='column' align='center' gap='xsmall' margin='medium'>
      {
        !(wizard.results) ? 
          <Box fill direction='column' align='center' gap='xsmall' margin='medium'>
            <Box animation={{type:'pulse', duration:1000, size:'medium'}}>
              <Image src={require('../../../magic_wand.gif')}/>
            </Box>
            <Text size='large'>{currentSaying}</Text>
            <Button label='PANIC!!' onClick={() => setPhase(2)}/>
          </Box>
          :
          dataset.properties?.schemaRef === 'sift_dataset_properties.schema.json' ?
            <WizardResults 
              wizard={wizard}
              datasetProperties={dataset.properties}
              dataSourceId={dataset.dataSourceId}
              projectId={projectId}
            />:
            <ErrorCat />
      }
    </Box>
}    

// ----------- OTHER -----------
export const WizardHeader = ({textA, textB, phase}: {textA: string, textB: string, phase:number}) => {
  return <Box align='center' gap='small'>
      { phase !== 0 && <Text size='large' weight='bold'> {`Step ${phase}`}</Text>}
      <Text size='medium'>{textA}</Text>
      <Heading level='2'>{textB}</Heading>
  </Box>
}

// TODO - Migrate to load from .json file...
const setupQuestions = () => {
  return [
    {'questionNumber': 1, 
    'questionText': 'The relationship i am tyring to model has a temporal dependency', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'}, 
    {'questionNumber': 2, 
    'questionText': 'I want to use data from some instances to infer data at a different instance', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'}, 
    {'questionNumber': 3,
    'questionText': 'I only care about turbines that are operating normally', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'}, 
    {'questionNumber': 4, 
    'questionText': 'I want to use past behaviour to infer future behaviour', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'}, 
    {'questionNumber': 5, 
    'questionText': 'I Want models which will train quickly', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'}, 
    {'questionNumber': 6,
    'questionText': 'Additional Questions', 
    'value': 2, 
    schemaRef:'wizard.schema.json#/definitions/question'} 
] 
}
