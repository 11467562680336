import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, CheckBox, Heading, Sidebar, Text, ThemeType, Tip } from "grommet";
import { normalizeColor } from "grommet/utils";
import { BounceLoader, GridLoader } from 'halogenium';
import produce from 'immer';
import moment from "moment";
import React, { useContext, useState } from 'react';
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import { ThemeContext } from "styled-components";
import { getConfigs, updateConfig } from '../../codegen/actions/configActions';
import { AppInfoType, SiftLinkType } from '../../codegen/models/models';
import { ConfigState } from '../../codegen/states/configState';
import { setInnerPageUrl } from '../../pages/app/actions';
import { IApp } from '../../pages/app/state';
import { useApi, useApiEffect } from '../api';
import useInterval from "../hooks/useInterval";
import { IApplicationState } from '../state';
import { buildRedirectTo, storeRedirectTo } from "../utils/login";
import Icon from './Icon';
import InputRow from './InputRow';
import LinkAnchor from './LinkAnchor';
import Modal from './Modal';
import Requestable from './Requestable';
import ScrollThumb from "./ScrollThumb";

export interface RouteDef {
    name: string
    path: (p: string) => string
    exact?: boolean
    component: (projectId: number) => JSX.Element
    icon: (color: string) => JSX.Element
}

const Link = ({ to, path, name, icon }: { to: string, path: string, name: string, icon: (color: string) => JSX.Element }) => {
    const isActive = useRouteMatch(path)?.isExact
    return <Box fill align='stretch'>
        <LinkAnchor to={to}>
            <SidebarIcon icon={icon} title={name} isActive={isActive || false} />
        </LinkAnchor>
    </Box>
}

const SidebarIcon = ({ icon, title, isActive }: { icon: (color: string) => JSX.Element, title: string, isActive: boolean }) => {
    return <Box fill direction='row' gap='small' align='center' pad='xsmall'>
        <Box width='xxsmall' fill={false} align='center'>
            {icon(isActive ? 'brand' : 'accent-1')}
        </Box>
        <Box justify='start' direction='row' fill>
            <Text color={isActive ? 'brand' : 'accent-1'} weight='bold'>{title}</Text>
        </Box>
    </Box>
}

const AppInfo = () => {
    const [appInfo, setAppInfo] = useState<AppInfoType>()
    useApiEffect(async api => {
        if (appInfo === undefined) {
            const appInfoResult = await api.get('app-info')
            setAppInfo(appInfoResult)
        }
    }, [appInfo])
    return <Box fill direction='column' align='center'>
        <Heading level={3}>Sift AML</Heading>
        <Text>Version: {appInfo?.version}</Text>
    </Box>
}

const AppGlossary = () => {
    return <Box fill direction='column' align='center' gap='small' border='all'>
        <Text size='large' weight='bold'>Sift AML Machine Learning Glossary</Text>
        <Box basis='2/3' fill direction='column'>
            <Scrollbars renderThumbHorizontal={ScrollThumb}>
            {
                <Box fill direction='column' align='center'>
                    <Text size="medium" weight='bold'>The glossary entries are still under development</Text> 
                    <Text size="medium" weight='bold'>Please bear with us!</Text> 
                </Box> 
            }    
            </Scrollbars>
        </Box>
    </Box>
}

const Routes = ({ routes }: { routes: RouteDef[] }) => {
    const { url, path } = useRouteMatch()
    const { search } = useLocation()
    return <Box direction='column' align='center' gap='small'>
        {
            routes.map(r => <Link to={`${r.path(url)}${search}`} path={r.path(path)} key={r.path(url)} name={r.name} icon={r.icon} />)
        }
    </Box>
}

const Title = () => {
    return <Box border={{ side: 'bottom' }}>
        <LinkAnchor to='/'>
            <Text>
                <Heading level={3}>Sift AML</Heading>
            </Text>
        </LinkAnchor>
    </Box>
}

const NoSiftLink = ({ filename }: { filename: string }) => (
    <Tip content={<Box><Text>No Link Active.</Text><Text>Please navigate to <b>Sift AML</b> from Sift project <b>{filename}</b> to activate link.</Text></Box>}>
        <Box>
            <Icon icon='times' size='lg' />
        </Box>
    </Tip>
)

const ActiveSiftLink = ({ link, color }: { link: SiftLinkType, color: string }) => (
    <Tip content={<Box><Text>Active Sift link established to machine <b>{link.machineName}</b></Text></Box>}>
        <Box>
            <BounceLoader color={color} size='16px' />
        </Box>
    </Tip>
)

const SiftLink = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const theme = useContext(ThemeContext) as ThemeType
    const [link, setLink] = useState<SiftLinkType | undefined>()
    const [filename, setFilename] = useState<string | undefined>()
    const api = useApi()
    const brandColor = normalizeColor('brand', theme)
    useInterval(() => {
        const updateLink = async (projectId: number) => {
            const links = await api?.get('sift-link') as SiftLinkType[]
            const link = links.find(l => l.projectId === projectId && moment.utc().diff(moment.utc(l.lastContactTime), 'seconds') < 120)
            setLink(link)
        }
        if (projectId && Number.isInteger(Number(projectId))) {
            updateLink(Number(projectId))
        }
    }, 15000)
    useApiEffect(async api => {
        if (filename === undefined) {
            const project = await api.get(`project/${projectId}`)
            const dataSource = await api.get(`data-source/${project.defaultDataSourceId}`)
            setFilename(dataSource.details?.filename)
        }
    })
    return <Box fill direction='row' align='center' gap='small' pad='xsmall'>
        <Box width='xxsmall' fill={false} align='center'>
        {
            link ? <ActiveSiftLink link={link} color={brandColor} /> : <NoSiftLink filename={filename || 'unknown'}/>
        }
        </Box>
        <Box fill>
            <Text style={{fontWeight: 'bold'}} color='brand'>Sift Link</Text>
        </Box>
    </Box>
}

export default ({ routes }: { routes: RouteDef[] }) => {
    const [showWindow, setShowWindow] = useState<'version' | 'glossary' | undefined>()
    const app = useSelector<IApplicationState, IApp>(s => s.app)
    const { logout } = useAuth0()
    const dispatch = useDispatch()
    return <Box fill='vertical' background='background-contrast'>
        <Sidebar
            header={<Box gap='small'>
                <Title />
                <Routes routes={routes} />
            </Box>}
            footer={<Box direction='column' align='center' gap='small' fill='horizontal'>
                {
                    app.innerPageUrl && <Button onClick={() => dispatch(setInnerPageUrl(undefined))}><Icon icon='times' size='lg' /></Button>
                }
                <SiftLink />
                <Button onClick={() => setShowWindow('glossary')} fill='horizontal'>
                    <SidebarIcon icon={c => <Icon icon='book' size='lg' color={c} />} title='Glossary' isActive={showWindow === 'glossary'} />
                </Button>
                <Button onClick={() => setShowWindow('version')} fill='horizontal'>
                    <SidebarIcon icon={c => <Icon icon='question-circle' size='lg' color={c} />} title='About' isActive={showWindow === 'version'} />
                </Button>
                <Button onClick={() => {
                    storeRedirectTo(buildRedirectTo())
                    logout({ returnTo: window.location.origin + '?logout=true' })
                }} fill='horizontal'>
                    <SidebarIcon icon={c => <Icon icon='sign-out-alt' size='lg' color={c} />} title='Logout' isActive={showWindow === 'version'} />
                </Button>
            </Box>}
        />
        <Modal isOpen={showWindow === 'version'}>
            <Box gap='small' direction='column' pad='small'>
                <AppInfo />
                <Box fill='horizontal' justify='center'>
                    <Button label='OK' onClick={() => setShowWindow(undefined)} />
                </Box>
            </Box>
        </Modal>
        <Modal isOpen={showWindow === 'glossary'} width='large' height='large'>
            <Box fill gap='small' direction='column' pad='small'>
                <AppGlossary />
                <Box fill='horizontal' justify='center'>
                    <Button label='OK' onClick={() => setShowWindow(undefined)} />
                </Box>
            </Box>
        </Modal>
    </Box>
}