import { Theme, Box as NivoBox } from "@bitbloom/nivo-core"
import { ThemeType } from "grommet"
import { normalizeColor } from "grommet/utils"
import { maxBy } from "lodash"
import { AxisProps } from '@bitbloom/nivo-axes'

export const theme = (context: ThemeType): Theme => {
    const textColor = normalizeColor('text', context)
    return {
      axis: {
        domain: {
          line: { fill: textColor },
        },
        ticks: {
          line: { fill: textColor },
          text: { fill: textColor },
        },
        legend: {
          text: { fill: textColor },
        },
      },
      labels: {
        text: { fill: textColor },
      },
      legends: {
        text: { fill: textColor },
      },
      markers: {
        textColor,
      },
      tooltip: {
        container: {
          background: normalizeColor('background-back', context),
        },
      },
    }
  }
  
export const defaultChartProps = (themeContext: ThemeType): { theme: Theme, axisTop: null, colors: string[], nodeSize: number } => ({
      theme: theme(themeContext),
      axisTop: null,
      colors: [
        'rgb(146, 196, 67)',
        'rgb(244, 151, 37)',
        'rgb(33, 159, 211)',
        'rgb(236, 100, 161)',
        'rgb(204, 204, 204)',
      ],
      nodeSize: 2
    }
  )


  
export const formatTickValue = (value: number | string | Date) => {
    if (typeof value === 'number' && Number.isFinite(value) && (Math.abs(value) > 1000 || Math.abs(value) < 0.001)) {
      return value.toPrecision(3)
    }
    return value.toString()
  }
  
export const labelSpacing = ({ legend, yAxis, xAxis }: { legend?: string[], yAxis?: {data?: string[], title?: string}, xAxis: {data?: string[], title?: string, format?: string} }): { margin: NivoBox, axisLeft: AxisProps, axisBottom: AxisProps, gridXValues?: string[] } => {
    const maxHorizontal = 450;
    const maxVertical = 250;
    const maxTicks = 25;
    const maxGridLines = 50;
    const rotationDegrees = 25;
    const axisTitleSize = 30
    const pad = {
      legend: 110,
      yLabel: 50 + 20,
      xLabel: xAxis.title ? 60 : 20,
    }
  
    const ctx = document.createElement('canvas').getContext('2d')
    const rotatedLength = (length: number, degrees: number) => length * Math.cos((90 - degrees) * (Math.PI / 180))
    const getLabelwidth = (arr?: string[]) => ctx?.measureText(maxBy(arr, (l) => l.length) || '').width || 0
    const skipXLabels = (index: number, maxVal: number) => xAxis.data ? ((index + 1) % Math.ceil(xAxis.data.length / maxVal) === 0) : false
  
    const longestLegend = getLabelwidth(legend)
    const longestYLabel = getLabelwidth(yAxis?.data)
    const longestXLabel = getLabelwidth(xAxis.data)
    const margin = {
      top: 10,
      right: Math.min(longestLegend, maxHorizontal) + pad.legend,
      left: Math.min(Math.max(longestYLabel, rotatedLength(longestXLabel, rotationDegrees)), maxHorizontal) + pad.yLabel,
      bottom: Math.min(rotatedLength(longestXLabel, rotationDegrees), maxVertical) + pad.xLabel,
    }
    return {
      margin,
      axisLeft: {
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -margin.left + axisTitleSize,
        legend: yAxis?.title,
        format: formatTickValue,
      },
      axisBottom: {
        tickSize: 2,
        tickPadding: 5,
        tickRotation: longestXLabel > 20 ? -rotationDegrees : 0,
        legendPosition: 'middle',
        legendOffset: margin.bottom - axisTitleSize,
        tickValues: xAxis.data?.filter((d, i) => skipXLabels(i, maxTicks)),
        legend: xAxis.title,
        format: xAxis.format
      },
      gridXValues: xAxis.data?.filter((d, i) => skipXLabels(i, maxGridLines)),
    }
  }