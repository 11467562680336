import { filter, flatMap, get, isNil, join, map, isArray } from "lodash"
import { ModelType } from "../../codegen/models/models"
import { SchemaInterface, toCamelCase, ValidationCondition, ValidationRule } from "../components/Utils"

export interface ValidationPlan {
    schemaRef: string
    propertyName: string
    validationRules: ValidationRule[]
}

export interface ValidationResult {
    level: 'error' | 'warning'
    message: string
    propertyName: string
}

const getValueToEvaluate = (model: ModelType, condition: ValidationCondition, propertyName: string): any => {
    if (condition.value !== undefined) {
        return condition.value
    } else if (condition.property !== undefined) {
        if (condition.property.startsWith('.')) {
            const parts = propertyName.split('.')
            const path = join(parts.slice(0, parts.length - 1), '.') + toCamelCase(condition.property)
            return get(model, path)
        }
        return get(model, condition.property)
    }
}

const evaluateValidationCondition = (model: ModelType, condition: ValidationCondition, propertyName: string, value: any): boolean => {
    const valueToEvaluate = getValueToEvaluate(model, condition, propertyName)
    if (valueToEvaluate === undefined) return false
    switch (condition.type) {
        case '!=': return valueToEvaluate !== value
        case '=': return valueToEvaluate === value
        case '<': return value < valueToEvaluate
        case '<=': return value <= valueToEvaluate
        case '>': return value > valueToEvaluate
        case '>=': return value >= valueToEvaluate
    }
}

export const validateInput = (model: ModelType, validationRules: ValidationRule[], propertyName: string, value: any): ValidationResult[] => {
    return filter(map(validationRules, (rule): ValidationResult | undefined => {
        const result = {
            level: rule.level,
            message: rule.message,
            propertyName: propertyName
        }
        const results = rule.conditions.map(c => evaluateValidationCondition(model, c, propertyName, value))
        if (results.filter(r => r === false).length === 0) {
            return result
        }
    }), (r): r is ValidationResult => r !== undefined)
}

export interface SchemaObject {
    schemaRef: string
    [key: string]: any
}

export const isSchemaObject = (obj: any): obj is SchemaObject => {
    return !isNil(obj?.schemaRef)
}

export const executeValidationPlans = (model: ModelType, obj: object, plans: ValidationPlan[], parentName?: string): ValidationResult[] => {
    if (!isSchemaObject(obj)) {
        return []
    }
    const results: ValidationResult[] = parentName ?
        flatMap(plans.filter(p => p.schemaRef === obj.schemaRef), p => validateInput(model, p.validationRules, `${parentName}.${p.propertyName}`, obj[p.propertyName])) :
        []
    return [
        ...results,
        ...flatMap(obj, (property, propertyName) => {
            if (isArray(property)) return flatMap(property, (p, i) => executeValidationPlans(model, p, plans, parentName ? `${parentName}.${propertyName}[${i}]` : `${propertyName}[${i}]`))
            return executeValidationPlans(model, property, plans, parentName ? `${parentName}.${propertyName}` : propertyName)
        })]
}

export const getValidationPlans = (schemas: { schemaRef: string, schema: SchemaInterface }[]): ValidationPlan[] => (
    flatMap(schemas, ({ schema, schemaRef }) =>
        map(schema.properties, (property, propertyName) => ({
            validationRules: property.validationRules || [],
            schemaRef: schemaRef,
            propertyName: propertyName
        }))
    )
)