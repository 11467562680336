import React, { useState } from 'react';
import Requestable, { MultiRequestable } from '../../../core/components/Requestable'
import { PreprocessorType, ModelType, DatasetType, SiftDataSourceInfoType, DataSourceType } from '../../../codegen/models/models'
import { getAvailablePreprocessors, addPreprocessor, updateModel } from '../actions'
import { getDatasets, getDataSource } from '../../data/actions'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationState } from '../../../core/state'
import { IData } from '../../data/state'
import { IRequestable } from '../../../core/api'
import { Accordion, Box, Button, Select, Text } from 'grommet';
import { Preprocessor } from './Preprocessor';
import { displayNameFromSchemaRef } from '../../../core/components/Utils'
import InputRow from '../../../core/components/InputRow';
import produce from 'immer';
import Scrollbars from 'react-custom-scrollbars';
import ScrollThumb from '../../../core/components/ScrollThumb';


export const Preprocessing = ({ model }: {model: ModelType }) => {
  const availablePreprocessors = useSelector<IApplicationState, IRequestable<string[]>>(s => s.model?.availablePreprocessors)
  const [preprocessorSchemaRef, setSchemaRef] = useState<string | undefined>()
  const dispatch = useDispatch()
  const data = useSelector<IApplicationState, IData>(d => d.data)
  return <MultiRequestable
      requestables={ [{requestable: availablePreprocessors, action: getAvailablePreprocessors},
                      {requestable: data.datasets, action: getDatasets}]}
      render={([availablePreprocessors, datasets]) => {
        const currentDataset = (datasets as DatasetType[]).find(d => d.id === model.datasetId)
        if (!currentDataset) {
          return <Text>Current dataset not found</Text>
        }
        return <Scrollbars renderThumbVertical={ScrollThumb} >
        <Box gap='small'>
            <Accordion>
                  {
                      model.preprocessors.map(
                        (item: PreprocessorType, index: number) => (
                          <Requestable
                            requestable={data.dataSource[currentDataset.dataSourceId]}
                            action={() => getDataSource({ dataSourceId: currentDataset.dataSourceId })}
                            render={(dataSource: DataSourceType) => <Preprocessor
                              model={model}
                              index={index}
                              key={`preprocessor-${index}`} preprocessor={ item }
                              dataset={ currentDataset }
                              onUpdated={p => dispatch(updateModel(produce(model, m => { m.preprocessors[index] = p })))}
                              onRemoved={() => dispatch(updateModel(produce(model, m => { m.preprocessors = m.preprocessors.filter(p => p !== m.preprocessors[index]) })))}
                              dataSourceInfo={dataSource.info as any as SiftDataSourceInfoType}/>}
                            />
                        )
                      )
                  }
            </Accordion>
            <InputRow label='Add Preprocessor'>
                <Box direction='row' fill='horizontal' gap='small' align='center'>
                  <Box basis='80%'>
                    <Select
                      id="select"
                      name="select"
                      labelKey={ displayName => displayNameFromSchemaRef(displayName) }
                      placeholder="Select Preprocessor"
                      options={ availablePreprocessors }
                      value={preprocessorSchemaRef}
                      onChange={ event => {
                        if (event.value) setSchemaRef(event.value)
                      }}
                    />
                  </Box>
                  <Box basis='20%' fill='vertical'>
                    <Button secondary  label='Add' onClick={() => preprocessorSchemaRef && dispatch(addPreprocessor({ preprocessorSchemaRef, model: model }))}/>
                  </Box>
                </Box>
            </InputRow>
          </Box>
        </Scrollbars>
      }}
    />
}

export default Preprocessing;
