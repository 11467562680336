import React from 'react'

export default function ScrollThumb({ ...props }) {
  const viewStyle = {
    backgroundColor: '#fff',
    opacity: '0.3',
    borderRadius: '5px',
  };
  const { style } = props;
  return (
    <div
      {...props}
      style={{ ...viewStyle, ...style }}
    />
  )
}
