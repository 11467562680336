import React from 'react'
import { Grommet, ThemeType } from 'grommet'


export const orange = '#EF9234'
export const green = '#88A550'
export const blue = '#219FD3'
export const pink = '#E769A2'
export const yellow = '#FBCD48'
export const siftGreen = '#79F1B1'
export const mintGreen = '#2aa198'
export const monitorBlue = '#5C9DD0'

export function GrommetWrapper({ children }: any) {
  const darkTheme: ThemeType = {
    text: {
      medium: {
        size: '14px',
      },
      '2xl?': {
      },
      '3xl?': {
      },
      '4xl?': {
      },
      '5xl?': {
      },
      '6xl?': {
      },
    },
    global: {
      colors: {
        brand: pink,
        control: mintGreen,
        focus: mintGreen,
        text: '#f8f8f8',
        'accent-1': mintGreen,
        'background-back': '#00212B',
        'background-contrast': '#002B36',
        'background-front': '#004052',
        'background-600': '#062d37',
        'background-500': '#09404e',
        'background-400': '#00485e',
      },
      font: {
        family: 'Lato',
        size: '12px',
      },
      drop: {
        background: { dark: 'background-contrast' },
        border: { radius: '3px' },
      },
      hover: {
        background: 'background-contrast'
      }
    },
    heading: {
      font: {
        family: 'Overpass',
        size: '14px',
      },
      level: {
        2: {
          medium: {
            size: '18px',
          },
        },
        3: {
          medium: {
            size: '16px',
          },
        },
        4: {
          medium: {
            size: '14px',
          },
        },
      },
    },
    checkBox: {
      size: '1.4rem',
    },
    tip: {
      content: {
        background: 'dark-3',
        margin: 'small',
        round: '3px',
        elevation: 'xsmall',
      },
    },
  }
  // const lightTheme = {
  //   global: {colors: {brand: blue}}
  // }
  return (
    <Grommet full theme={darkTheme} id='grommet-root'>
      {children}
    </Grommet>
  )
}
