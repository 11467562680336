import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { addWizard, deleteWizard, getWizards, updateWizard } from "../actions/wizardActions"
import { WizardState } from "../states/wizardState"

export const wizards = createSlice<WizardState, SliceCaseReducers<WizardState>>({
    name: 'wizards',
    initialState: { wizards: { isRequesting: false } },
    reducers: { },
    extraReducers: (builder) => {
        builder.addCase(addWizard.fulfilled, (state, { payload: wizard }) => {
            if (state.wizards.value !== undefined && wizard !== undefined ) {
                state.wizards.value = [...state.wizards.value, wizard]
            }
        })
        builder.addCase(getWizards.pending, (state) => {
            if (state.wizards.value === undefined) {
                state.wizards = {
                    isRequesting: true
                }
            }
        })
        builder.addCase(getWizards.fulfilled, (state, { payload: wizards }) => {
            state.wizards = {
                isRequesting: false,
                value: wizards
            }
        })
        builder.addCase(getWizards.rejected, (state, { error: { message }}) => {
            state.wizards = {
                isRequesting: false,
                errorMessage: message
            }
        })
        builder.addCase(updateWizard.pending, (state, { meta: { arg: wizard }}) => {
            if (state.wizards.value !== undefined && wizard.id !== undefined) {
                state.wizards.value = state.wizards.value.map(
                    s => s.id === wizard.id ? wizard : s
                )
            }
        })
        builder.addCase(updateWizard.rejected, () => {
            // TODO: Handle rejected case
        })
        builder.addCase(deleteWizard.pending, (state, { meta: { arg: { wizardId } }}) => {
            if (state.wizards.value !== undefined) {
                state.wizards.value = state.wizards.value.filter(s => s.id !== wizardId)
            }
        })
        builder.addCase(deleteWizard.rejected, () => {
            // TODO: Handle rejected case
        })
    }
})