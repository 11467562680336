import { Box, Heading, Text } from 'grommet'
import React from 'react'
import { useSelector } from "react-redux"
import { DataSourceType, ProjectType } from '../../codegen/models/models'
import LinkAnchor from '../../core/components/LinkAnchor'
import Requestable from "../../core/components/Requestable"
import { IApplicationState } from "../../core/state"
import { getDataSources, getProjects } from "./actions"
import { IProjects } from "./state"

export const ProjectDesc = ({ project, dataSources }: { project: ProjectType, dataSources: DataSourceType[] }) => {
    const dataSource = dataSources.find(d => d.id === project.defaultDataSourceId)
    return <Text size='s'>{project.description}: {dataSource?.details?.['filename']}</Text>
}

export const ProjectSelector = ({ projects, dataSources }: { projects: ProjectType[], dataSources: DataSourceType[] }) => {
    return <Box fill justify='center' align='center'>
        <Box width='m' height='m' background='background-front' pad={{horizontal: 'xlarge', vertical: 'large'}}>
            <Heading level='3'>Select Project</Heading>
            <Box>
                {projects.map(p => <LinkAnchor key={`link_anchor_${p.id}`} to={`/project/${p.id}`}>
                        <ProjectDesc project={p} dataSources={dataSources}/>
                    </LinkAnchor>
                )}
            </Box>
        </Box>
    </Box>
}

export default () => {
    const projectsState = useSelector<IApplicationState, IProjects>(s => s.projects)
    return <Requestable
        requestable={projectsState.projects}
        action={getProjects}
        render={projects => <Requestable
                                requestable={projectsState.dataSources}
                                action={getDataSources}
                                render={dataSources => <ProjectSelector projects={projects} dataSources={dataSources}/>} />
    } />
}
