import { useAuth0 } from '@auth0/auth0-react'
import { Anchor, Box, Button, Heading, Text } from 'grommet'
import { Location } from 'history'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import './App.css'
import { SessionType } from './codegen/models/models'
import { useApiEffect } from './core/api'
import Icon from './core/components/Icon'
import Loader from './core/components/Loader'
import Sidebar, { RouteDef } from './core/components/Sidebar'
import { IApplicationState } from './core/state'
import { buildRedirectTo, clearRedirectTo, getIsLogout, getRedirectTo, storeRedirectTo } from './core/utils/login'
import { IApp } from './pages/app/state'
import Data from './pages/data/components/Data'
import Experiment from './pages/experiment/components/Experiment'
import Model from './pages/model/components/Model'
import Predict from './pages/predict/components/Predict'
import Wizard from './pages/Wizard/components/Wizard'
import { addSession } from './pages/project/actions'
import ProjectSelector from './pages/project/ProjectSelector'
import { Session } from './pages/project/state'
import jwtDecode, { JwtPayload } from "jwt-decode"

const createSession = (projectId: number, location: Location): SessionType => {
  return {
    projectId,
    lastUpdateTime: moment().utc().toISOString(),
    schemaRef: 'session.schema.json',
    lastLocation: JSON.stringify({
      pathname: location.pathname,
      search: location.search,
      hash: location.hash
    })
  }
}

const useSession = (sessions: Session[], projectId?: number) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const sessionId = sessions.find(s => s.projectId === projectId)?.id
  useApiEffect(async api => {
    if (!projectId) {
      return
    }
    if (!sessionId) {
      const sessions = await api.get('session') as SessionType[]
      const projectSession = sessions.find(s => s.projectId === projectId)
      if (projectSession?.id) {
        dispatch(addSession({ id: projectSession.id, projectId }))
        if (projectSession.lastLocation) {
          const location = JSON.parse(projectSession.lastLocation)
          history.push(location)
        }
      } else {
        const newSession = await api.post('session', createSession(projectId, location)) as SessionType
        dispatch(addSession({ id: newSession.id, projectId }))
      }
    } else {
      await api.put(`session/${sessionId}`, createSession(projectId, location))
    }
  }, [dispatch, location, sessionId, projectId])
}

const Routes = ({ routes }: { routes: RouteDef[] }) => {
  const { url, path } = useRouteMatch()
  const { projectId } = useParams<{ projectId: string }>()
  const sessions = useSelector<IApplicationState, Session[]>(s => s.projects.sessions)
  useSession(sessions, projectId?.length ? Number(projectId) : undefined)
  if (!projectId || !Number.isInteger(Number(projectId))) return <Redirect to='/' />
  return (
    <Box fill>
      <Switch>
        {
          routes.map(r => (
            <Route key={r.path(url)} path={r.path(path)} exact={r.exact || false}>
              {r.component(Number(projectId))}
            </Route>
          ))
        }
      </Switch>
    </Box>
  )

}

const InnerPage = ({ app }: { app: IApp }) => <Box fill align='center' justify='center'>
  {
    app.isLoadingInnerPage ?
      <Loader /> :
      <div dangerouslySetInnerHTML={{ __html: `<iframe src='${app.innerPageUrl}' width='100%' height='100%'/>` }} style={{ width: '100%', height: '100%' }} />
  }
</Box>

const Login = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const isLogout = getIsLogout()
  const redirectTo = buildRedirectTo()
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !isLogout) {
      storeRedirectTo(redirectTo)
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, isLogout, redirectTo])
  return <Box>
    {
      isLoading ? <Text>Loading...</Text> :
      isLogout ? <Box gap='medium'>
        <Text>You've been logged out</Text>
        <Button primary label='Login' onClick={loginWithRedirect}/>
      </Box> :
      <Text>Loading...</Text>
    }
  </Box>
}

const Callback = () => {
  const redirectTo = getRedirectTo()
  const history = useHistory()
  useEffect(() => {
    if (redirectTo) {
      clearRedirectTo()
      history.push(redirectTo)
    }
  }, [redirectTo, history])
  return <Box>
    <Text>Loading...</Text>
  </Box>
}

const NewUserWelcome = () => {
  const { loginWithRedirect } = useAuth0()
  return <Box fill align='center' justify='center' width='medium' gap='medium'>
    <Heading level={2}>Welcome to Sift AML!</Heading>
    <Text>You haven't been granted access yet.</Text>
    <Text>Email <Anchor href='mailto:info@bitbloom.tech?subject=Sift AML Access'>Bitbloom</Anchor> to get access.</Text>
    <Text>Sit tight and we'll get back to you soon!</Text>
    <Button onClick={loginWithRedirect} label='Refresh' primary />
  </Box>
}

type Token = JwtPayload & { permissions: string[] }

function App() {
  const app = useSelector<IApplicationState, IApp>(s => s.app)
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const [accessToken, setAccessToken] = useState<Token | undefined>(undefined)
  useEffect(() => {
    const getUserMetadata = async () => {
  
      try {
        const accessToken = await getAccessTokenSilently()
        const decoded = jwtDecode<Token>(accessToken);
        setAccessToken(decoded)
      } catch (e) {
        if (e.error === 'login_required' || e.error === 'constent_required') {
          loginWithRedirect()
        }
        throw e
      }
    }
    if (isAuthenticated && !isLoading && accessToken === undefined) {
      getUserMetadata()
    }
  }, [getAccessTokenSilently, isAuthenticated, accessToken, isLoading, loginWithRedirect])
  const routes: RouteDef[] = [
    {
      name: 'Data',
      path: (p: string) => `${p}/data`,
      component: projectId => <Data projectId={projectId} />,
      icon: color => <Icon icon='database' color={color} size='lg' />
    },
    {
      name: 'Model',
      path: (p: string) => `${p}/model`,
      component: projectId => <Model projectId={projectId} />,
      icon: color => <Icon icon='project-diagram' color={color} size='lg' />
    },
    {
      name: 'Experiment',
      path: (p: string) => `${p}/experiment`,
      component: projectId => <Experiment projectId={projectId} />,
      icon: color => <Icon icon='flask' color={color} size='lg' />
    },
    {
      name: 'Predict',
      path: (p: string) => `${p}/predict`,
      component: projectId => <Predict projectId={projectId} />,
      icon: color => <Icon icon='chart-line' color={color} size='lg' />
    },
    {
      name: 'Wizard',
      path: (p: string) => `${p}/wizard`,
      component: projectId => <Wizard projectId={projectId} />,
      icon: color => <Icon icon='hat-wizard' color={color} size='lg' />
    },
  ]
  return <Box fill direction='row' background='background-back'>
    {
      (!isAuthenticated || isLoading || !accessToken) ? <Box fill align='center' justify='center'><Login /></Box> :
      (app.innerPageUrl || app.isLoadingInnerPage) ? <InnerPage app={app} /> :
      accessToken.permissions.includes('read:projects') ?
      <Router>
        <Route path='/' exact>
          <ProjectSelector />
        </Route>
        <Route path='/project/:projectId'>
          <Sidebar routes={routes} />
          <Routes routes={routes} />
        </Route>
        <Route path='/callback'>
          <Callback />
        </Route>
      </Router> : <NewUserWelcome />
    }
  </Box>
}

export default App
