import React from 'react'
import { Box, Tip } from 'grommet'
import Icon from '../components/Icon'
import { ValidationResult } from '../validation/validate'

export const ValidationMessage = ({ result }: { result: ValidationResult }) => {
    const color = result.level === 'warning' ? 'orange' : 'red'
    return <Box>
        <Tip content={result.message}>
            <Box fill pad={{ horizontal: 'xsmall' }} align='center' justify='center'>
                <Icon icon='exclamation' color={color} />
            </Box>
        </Tip>
    </Box>
}

export default ValidationMessage
