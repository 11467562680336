import { Accordion, AccordionPanel, Box, Button, CheckBox, Text } from 'grommet'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SiftDatasetPropertiesType, SiftDataSourceInfoType, WizardType } from '../../../codegen/models/models'
import PageSection from '../../../core/components/PageSection'
import Requestable from '../../../core/components/Requestable'
import { IApplicationState } from '../../../core/state'
import { getDataSource } from '../../data/actions'
import { IData } from '../../data/state'
import { createModels } from '../actions'
import WizardDatasetViewer from './WizardDatasetViewer'

export default ({ wizard, dataSourceId, datasetProperties, projectId }: 
                { wizard: WizardType, dataSourceId: number, datasetProperties: SiftDatasetPropertiesType, projectId: number }) => {
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const dispatch = useDispatch()
    const [selectedPPs, setSelectedPPs] = useState(wizard.results ? wizard.results.preprocessors: [])
    const [selectedAlgos, setSelectedAlgos] = useState(wizard.results ? wizard.results.algorithms: [])
    const addOrRemoveSelected = (type: string, value: string, add: boolean) => {
        if(type === 'preprocessors'){
            add ?
                setSelectedPPs([...selectedPPs, value]):
            setSelectedPPs(selectedPPs.filter((sp: string) => sp !== value))
        }
        if(type === 'algorithms'){
            add ?
                setSelectedAlgos([...selectedAlgos, value]):
            setSelectedAlgos(selectedAlgos.filter((sa: string) => sa !== value))
        }
    }
    return <Box fill align='center' pad='small'>
        <Box fill direction='row' gap='small'>
            <Box fill basis='1/3' direction='column' gap='small'>
                <Box fill basis='2/3'>
                    <Requestable
                        requestable={data.dataSource[dataSourceId]}
                        action={() => getDataSource({ dataSourceId: dataSourceId })}
                        render={dataSource => <WizardDatasetViewer
                                dataSource={(dataSource.info as any as SiftDataSourceInfoType)}
                                datasetProperties={datasetProperties}
                            />
                    }/>
                </Box>
                <Box fill basis='1/3' border='all' round='xsmall' pad='small'>
                    <Text alignSelf='center' weight='bold'>Date Range</Text>
                    <Box direction='row' gap='small'>
                        <Text>Date From:</Text>
                        <Text>{datasetProperties.dateRange.dateFrom}</Text>
                    </Box>
                    <Box direction='row' gap='small'>
                        <Text>Date To:</Text>
                        <Text>{datasetProperties.dateRange.dateTo}</Text>
                    </Box>
                    <Text alignSelf='center' weight='bold'>Instances</Text>
                    {
                        datasetProperties.instances.map(i => <Text key={`${i.id}_name`}>{i.displayName}</Text>)
                    }
                </Box>
            </Box>
            <Box fill basis='2/3' direction='column' gap='small'>
                <PageSection title='Recommended Preprocessors:'>
                    <Accordion>
                        {
                            wizard?.results?.preprocessors &&
                                wizard.results.preprocessors.map((p: string) => <Box key={`${p}_container`} fill direction='row' gap='small' pad='small'>
                                         <CheckBox
                                            checked={selectedPPs.includes(p)}
                                            onChange={(value) => { addOrRemoveSelected('preprocessors', p, value.target.checked) }}
                                        />
                                        <AccordionPanel label={ <Box fill direction='column' gap='small' pad='small'>
                                            <Text weight='bold'>{p}</Text>
                                        </Box>}>
                                             <Text>INFO GOES HERE!</Text>
                                        </AccordionPanel>
                                    </Box>  
                                )  
                        }
                    </Accordion>
                </PageSection>
                <PageSection title='Recommended Algorithms:'>
                <Accordion>
                        {
                            wizard?.results?.algorithms &&
                                wizard.results.algorithms.map((a: string) => <Box key={`${a}_container`} fill direction='row' gap='small' pad='small'>
                                <CheckBox
                                    checked={selectedAlgos.includes(a)}
                                    onChange={(value) => { addOrRemoveSelected('algorithms', a, value.target.checked) }}
                                />
                                <AccordionPanel label={ <Box fill direction='column' gap='small' pad='small'>
                                    <Text weight='bold'>{a}</Text>
                                </Box>}>
                                    <Text>INFO GOES HERE!</Text>
                                </AccordionPanel>
                            </Box>  
                        )
                        }
                    </Accordion>
                </PageSection>
            </Box>
        </Box>
        <Button 
            size='large'
            primary
            label='Build Selected Models'
            margin='medium'
            onClick={() => {
                dispatch(createModels({
                    algos: selectedAlgos, 
                    pps: selectedPPs, 
                    projectId: projectId,
                    wizard: wizard
                }))
            }}
        />
    </Box>
}
