import { Box, CheckBox, Text } from 'grommet'
import produce from 'immer'
import { groupBy, isEqual, keys, some, sortBy } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { DatasetType, EventFeatureType, FlagFeatureType, FlagType, GroupType, SignalFeatureType, SignalType } from '../../codegen/models/models'


export const GroupedItemSelector = <T extends (SignalType | FlagType), FT extends (SignalFeatureType | FlagFeatureType)>(
    {items, groups, features, dataset, getProp, label, createFeature, updateFunction, featureType}:
    { items: T[], groups: GroupType[], features: FT[], dataset: DatasetType, getProp: (v: T | FT) => string, 
      label: (v: T) => string | React.ReactElement, createFeature: (v: T) => FT, updateFunction: any, featureType?: string }
    ) => {
    const grouped = groupBy(items, s => s.groupId)
    const groupName = (groupId: string) => {
        const group = groups.find(g => g.id === groupId)
        return group?.displayName || group?.name || group?.name
    }
    const allSignalsInGroupSelected = (groupId: string) => isEqual(
        sortBy(features.filter(f => f.groupId === groupId).map(getProp)),
        sortBy(items.filter(s => s.groupId === groupId).map(getProp))
    ) ? true : some(features.filter(f => f.groupId === groupId)) ? undefined : false
    const groupStates: {[key: string]: boolean | undefined} = keys(grouped).reduce((p, g) => ({...p, [g]: allSignalsInGroupSelected(g)}), {})
    const dispatch = useDispatch()
    return <Box flex fill margin='small'>
        {
            keys(grouped).map(groupId =>
                <Box key={`${groupId}-items-group`}>
                    {
                        <CheckBox
                            label={<Text weight='bold' size='small'>{groupName(groupId)}</Text>}
                            indeterminate={groupStates[groupId] === undefined}
                            checked={groupStates[groupId]}
                            onChange={e => dispatch(updateFunction(addOrRemoveFeatures(
                                        items.filter(s => s.groupId === groupId).map(createFeature),
                                        e.target.checked,
                                        dataset,
                                        featureType
                              )))}
                        />
                    }
                    {
                        grouped[groupId].map(item =>
                            <Box key={`${getProp(item)}-items`} pad={{left: 'small', vertical: 'xxsmall'}}>
                                <CheckBox
                                    label={label(item)}
                                    checked={features.find(f => getProp(f) === getProp(item) && f.groupId === item.groupId) !== undefined}
                                    onChange={e => dispatch(updateFunction(
                                        addOrRemoveFeatures(
                                            [createFeature(item)],
                                            e.target.checked,
                                            dataset,
                                            featureType
                                        )
                                    ))}
                                />
                            </Box>
                        )
                    }
                </Box>
            )
        }
    </Box>
}

const featuresEqual = (l: (SignalFeatureType | FlagFeatureType | EventFeatureType), r: (SignalFeatureType | FlagFeatureType | EventFeatureType)) => {
    if ( l.groupId !== r.groupId || l.schemaRef !== r.schemaRef) {
        return false
    }
    if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') {
        return l.signalId === r.signalId
    } else if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature') {
        return l.flagId === r.flagId
    } else if (l.schemaRef === 'sift_dataset_properties.schema.json#/definitions/event_feature' && r.schemaRef === 'sift_dataset_properties.schema.json#/definitions/event_feature') {
        return l.columnSpec === r.columnSpec
    }
    return false
}

const addOrRemoveFeatures = (features: (SignalFeatureType | FlagFeatureType | EventFeatureType)[], add: boolean, dataset: DatasetType, ft?: string): DatasetType => {
    return produce(dataset, (newDataset) => {
        if (newDataset?.properties?.schemaRef === 'sift_dataset_properties.schema.json') {
            const currentFeatures = newDataset?.properties.features
            if (add) {
                const featuresToAdd = features.filter(f => !currentFeatures.find(currF => featuresEqual(f, currF)) && f.featureType !== ft)
                newDataset.properties.features = [...newDataset.properties.features, ...featuresToAdd]
            } else {
                newDataset.properties.features = newDataset.properties.features.filter(currF => !features.find(f => featuresEqual(f, currF)) && currF.featureType !== ft)
            }
        }
        return newDataset
    })
}