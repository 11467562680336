import { Box, CheckBox, DropButton, Image, Text } from 'grommet'
import produce from 'immer'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { DatasetType, EventFeatureType, FlagFeatureType, InstanceType, SiftDatasetPropertiesType, SiftDataSourceInfoType, SignalFeatureType } from '../../../codegen/models/models'
import ScrollThumb from '../../../core/components/ScrollThumb'
import { updateDataset } from '../actions'
import { InstanceSelector } from '../../../core/components/InstanceSelector'
import Icon from '../../../core/components/Icon'
import featurizeImage from '../../../featurize_help.png'


export default ({dataSource, dataset, datasetProperties}: {dataSource: SiftDataSourceInfoType, dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType}) => {
    const dispatch = useDispatch()
    return <Box fill direction='column' gap='xsmall'>
        <Box fill pad='small'>
            <Scrollbars renderThumbVertical={ScrollThumb}>
                <InstanceSelector 
                    dataSource={dataSource}
                    instances={datasetProperties.instances}
                    dataset={dataset}
                />
            </Scrollbars>
        </Box>
        <Box fill basis='15%' border='top'>
            { datasetProperties.instances.length > 1 ?
                <Box fill direction='row' gap='small' justify='center'>
                    <CheckBox 
                    checked={ datasetProperties.featurized }
                    label={'Featurize Instances'}
                    onChange={({ target: { checked }}) => { 
                        if(checked){ dispatch(updateDataset(convertToFeaturized(dataset, datasetProperties))) }
                        else{ dispatch(updateDataset(convertToNonFeaturized(dataset, datasetProperties))) }
                    }}/>
                    <Box fill='vertical' justify='center'>
                        <DropButton
                            dropContent={
                                <Box fill pad='small' background='background-front' >
                                    <Image fit='contain' src={featurizeImage}/>
                                </Box>
                            } dropAlign={{top: 'bottom'}}>
                            <Box fill pad='small'>
                                <Icon icon='info-circle' color='white'/>
                            </Box>
                        </DropButton>
                    </Box>
                </Box> :
                <Text> Select Multiple Instances to Featurize! </Text> 
            }
        </Box>
    </Box>
}

const convertToFeaturized = (dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType): DatasetType => {
    return produce(dataset, d => {
        if (d.properties?.schemaRef !== "sift_dataset_properties.schema.json") { return d }
        else{
            d.properties.features = createFeaturesFromInstances(datasetProperties.features, datasetProperties.instances)
            d.properties.featurized = true
            return d
        }
    })
}

const convertToNonFeaturized = (dataset: DatasetType, datasetProperties: SiftDatasetPropertiesType): DatasetType => {
    return produce(dataset, d => {
        if (d.properties?.schemaRef !== "sift_dataset_properties.schema.json") { return d }
        else{
            d.properties.features = reduceFeaturesByInstances(datasetProperties.features)
            d.properties.featurized = false
            return d       
        }
    })
}

export const createFeaturesFromInstances = (features: (SignalFeatureType | FlagFeatureType | EventFeatureType)[], instances: InstanceType[]) => {
    const newDatasetFeatures: (SignalFeatureType | FlagFeatureType | EventFeatureType)[] = []
    instances.forEach((i) => {
            features.forEach((f) => { 
                if(i.id && i.groupId && i.groupId == f.groupId){
                    const newFeature = produce(f, newf => { 
                        if(i.id && i.groupId){
                            newf.instanceId = { 
                                id: i.id,
                                groupId: i.groupId,
                                schemaRef: 'sift_dataset_properties.schema.json#/definitions/instance_id'
                            }
                            newf.id = constructInstanceFeatureId(f, i)
                        }
                        return newf
                    })
                    if( !newDatasetFeatures.find(f => f.id === newFeature.id && f.instanceId === newFeature.instanceId) ){ 
                        newDatasetFeatures.push(newFeature) 
                    }
                }
            })
        
    })
    return newDatasetFeatures
}

const reduceFeaturesByInstances = (features: (SignalFeatureType | FlagFeatureType | EventFeatureType)[]) =>{
    const newDatasetFeatures: (SignalFeatureType | FlagFeatureType | EventFeatureType)[] = []
    const feature_ids: string[] = []
    features.forEach((f) => {
        const deconstructedId = deconstructInstanceFeatureId(f)
        if (!feature_ids.includes(deconstructedId)){
            feature_ids.push(deconstructedId)
            newDatasetFeatures.push(produce(f, feature => { 
                feature.id = deconstructedId
                feature.instanceId = undefined 
            }))
        }
    })
    return newDatasetFeatures
}

const constructInstanceFeatureId = (f: SignalFeatureType | FlagFeatureType | EventFeatureType, i: InstanceType): string => {
    const newId = `${i.id}_${f.id}`
    return newId
} 

const deconstructInstanceFeatureId = (f: SignalFeatureType | FlagFeatureType | EventFeatureType): string => {
    const newId = f.instanceId ? f.id.replace(f.instanceId.id + '_', ''): f.id
    return newId
}
