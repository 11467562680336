import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { addExperiment, addModelExperimentLink, deleteExperiment, deleteAllExperiments, deleteModelExperimentLink, getExperiments, getModelExperimentLinks, setModelsChecked, updateExperiment, updateModelExperimentLink, getTrainingMetric } from "./actions"
import { IExperiment } from "./state"

export const experiment = createSlice<IExperiment, SliceCaseReducers<IExperiment>>({
    name: 'experiment',
    initialState: {
        experiments: { isRequesting: false },
        modelExperimentLinks: { isRequesting: false },
        modelsChecked: [],
        trainingMetrics: {}
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getExperiments.pending, state => {
            state.experiments = { isRequesting: true }
        })
        builder.addCase(getExperiments.fulfilled, (state, { payload }) => {
            state.experiments = { isRequesting: false, value: payload }
        })
        builder.addCase(getExperiments.rejected, (state, { error }) => {
            state.experiments = { isRequesting: false, errorMessage: error.message }
        })
        builder.addCase(addExperiment.pending, () => {
        })
        builder.addCase(addExperiment.fulfilled, (state, { payload }) => {
            const experiments = state.experiments.value
            if (experiments && payload) {
                experiments.push(payload)
            }
        })
        builder.addCase(addExperiment.rejected, () => { })
        builder.addCase(updateExperiment.pending, (state, { meta: { arg: experiment } }) => {
            const experiments = state.experiments.value
            if (experiments) {
                state.experiments.value = experiments.map(e => e.id === experiment.id ? experiment : e)
            }
        })
        builder.addCase(updateExperiment.fulfilled, () => { })
        builder.addCase(updateExperiment.rejected, () => { })
        builder.addCase(getModelExperimentLinks.pending, state => {
            state.modelExperimentLinks = { isRequesting: true }
        })
        builder.addCase(getModelExperimentLinks.fulfilled, (state, { payload }) => {
            state.modelExperimentLinks = { isRequesting: false, value: payload }
        })
        builder.addCase(getModelExperimentLinks.rejected, (state, { error }) => {
            state.modelExperimentLinks = { isRequesting: false, errorMessage: error.message }
        })
        builder.addCase(addModelExperimentLink.pending, () => {
        })
        builder.addCase(addModelExperimentLink.fulfilled, (state, { payload }) => {
            const modelExperimentLinks = state.modelExperimentLinks.value
            if (modelExperimentLinks && payload) {
                modelExperimentLinks.push(payload)
            }
        })
        builder.addCase(addModelExperimentLink.rejected, () => { })
        builder.addCase(updateModelExperimentLink.pending, (state, { meta: { arg: modelExperimentLink } }) => {
            const modelExperimentLinks = state.modelExperimentLinks.value
            if (modelExperimentLinks) {
                state.modelExperimentLinks.value = modelExperimentLinks.map(e => e.id === modelExperimentLink.id ? modelExperimentLink : e)
            }
        })
        builder.addCase(updateModelExperimentLink.fulfilled, () => { })
        builder.addCase(updateModelExperimentLink.rejected, () => { })
        builder.addCase(setModelsChecked, (state, { payload: { models, checked } }) => {
            const toAdd = checked ? models : []
            state.modelsChecked = [
                ...state.modelsChecked.filter(id => models.find(m => id === m) === undefined),
                ...toAdd
            ]
        })
        builder.addCase(deleteExperiment.pending, (state, { meta: { arg: experiment }}) => {
            if (state.experiments.value) {
                state.experiments.value = state.experiments.value.filter(e => e.id !== experiment.id)
            }
        })
        builder.addCase(deleteModelExperimentLink.pending, (state, { meta: { arg: modelExperimentLink }}) => {
            if (state.modelExperimentLinks.value) {
                state.modelExperimentLinks.value = state.modelExperimentLinks.value.filter(l => l.id !== modelExperimentLink.id)
            }
            state.modelsChecked = state.modelsChecked.filter(m => m !== modelExperimentLink.modelId)
        })
        builder.addCase(deleteAllExperiments.pending, (state, { meta: {arg}}) => {
            const deletedExperiments: number[] = []
            if (state.experiments.value) {
                state.experiments.value.forEach(e => { if(e.projectId === arg.projectId){e.id !== undefined && deletedExperiments.push(e.id)}})
                state.experiments.value = state.experiments.value.filter(e => e.projectId !== arg.projectId)
            }
            if (state.modelExperimentLinks.value) {
                state.modelExperimentLinks.value = state.modelExperimentLinks.value.filter(l => deletedExperiments.includes(l.experimentId))
            }
            state.modelsChecked = []
        })
        builder.addCase(getTrainingMetric.pending, (state, { meta: { arg: { modelId, metricId }}}) => {
            if (!state.trainingMetrics[modelId]) {
                state.trainingMetrics[modelId] = {}
            }
            state.trainingMetrics[modelId][metricId] = {type: 'loading'}
        })
        builder.addCase(getTrainingMetric.fulfilled, (state, { meta: { arg: { modelId, metricId }}, payload}) => {
            if (!state.trainingMetrics[modelId]) {
                state.trainingMetrics[modelId] = {}
            }
            state.trainingMetrics[modelId][metricId] = payload || {type: 'not-found'}
        })
        builder.addCase(getTrainingMetric.rejected, (state, { meta: { arg: { modelId, metricId }}}) => {
            if (!state.trainingMetrics[modelId]) {
                state.trainingMetrics[modelId] = {}
            }
            state.trainingMetrics[modelId][metricId] = {type: 'not-found'}
        })
    }
})
