import { Box } from 'grommet'
import produce from 'immer'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ExperimentType } from '../../../codegen/models/models'
import ItemsTable, { DeleteCell, EditableCell, NameCell } from '../../../core/components/ItemsTable'
import { DeleteModal } from '../../../core/components/Modal'
import { deleteExperiment, updateExperiment } from '../actions'

export default ({ experiments }: { experiments: ExperimentType[] }) => {
    const dispatch = useDispatch()
    const [deleting, setDeleting] = useState<number>()
    return <Box>
        <ItemsTable
            name='Experiment'
            searchParam='experimentId'
            items={experiments}
            columns={[
                {
                    property: 'name',
                    header: 'Name',
                    render: (item) => <NameCell item={item} searchParam='experimentId' />
                },
                {
                    property: 'description',
                    header: 'Description',
                    render: (item) => <EditableCell value={item.description || ''} onChanged={v => dispatch(updateExperiment(produce(item, e => { e.description = v })))} />
                },
                {
                    header: 'Delete',
                    property: 'id',
                    size: 'xsmall',
                    render: (item: ExperimentType) => {
                        return <DeleteCell onDelete={setDeleting} item={item}/>
                    }
                }
            ]}
        />
        <DeleteModal
            isOpen={deleting !== undefined}
            item={experiments.find(e => e.id === deleting)?.name || '?'}
            text={'Experiment'}
            onAccept={() => {
                const experiment = experiments.find(e => e.id === deleting)
                experiment && dispatch(deleteExperiment(experiment))
                setDeleting(undefined)
            }}
            onCancel={() => setDeleting(undefined)} />
    </Box>
}