import { createAction } from "@reduxjs/toolkit"
import { toastr } from "react-redux-toastr"
import { ExperimentType, ModelExperimentLinkType } from "../../codegen/models/models"
import { createApiThunk } from "../../core/api"
import { getExistingModels } from "../model/actions"
import { TrainingMetricId } from "./state"
import { TrainingMetric } from "./types"

export const getExperiments = createApiThunk<
    ExperimentType[],
    void
>(
    'experiments/getExperiments',
    async api => await api.get('experiment'),
    error => error.type === 'api-error' && toastr.error('Get Experiments', error.message)
)

export const addExperiment = createApiThunk<
    ExperimentType,
    { name?: string,
      projectId: number }
>(
    'experiments/addExperiment',
    async (api, { name, projectId }) => {
        const newExperiment: ExperimentType = {
            name: name || 'New Experiment',
            projectId: projectId,
            schemaRef: 'experiment.schema.json'
        }
        return await api.post('experiment', newExperiment)
    },
    error => error.type === 'api-error' && toastr.error('Add Experiment', error.message)
)

export const updateExperiment = createApiThunk<
    ExperimentType,
    ExperimentType
>(
    'experiments/updateExperiment',
    async (api, experiment) => await api.put(`experiment/${experiment.id}`, experiment),
    error => error.type === 'api-error' && toastr.error('Update Experiment', error.message)
)

export const getModelExperimentLinks = createApiThunk<
    ModelExperimentLinkType[],
    void
>(
    'experiments/getModelExperimentLinks',
    async api => await api.get('model-experiment-link'),
    error => error.type === 'api-error' && toastr.error('Get ModelExperimentLinks', error.message)
)

export const addModelExperimentLink = createApiThunk<
    ModelExperimentLinkType,
    { modelId: number, experimentId: number }
>(
    'experiments/addModelExperimentLink',
    async (api, { modelId, experimentId }, { dispatch }) => {
        const newModelExperimentLink: ModelExperimentLinkType = {
            modelId,
            experimentId,
            schemaRef: 'model_experiment_link.schema.json'
        }
        const result = await api.post('model-experiment-link', newModelExperimentLink)
        dispatch(getExistingModels())
        dispatch(setModelsChecked({ models: [result.modelId], checked: true }))
        return result
    },
    error => error.type === 'api-error' && toastr.error('Add ModelExperimentLink', error.message)
)

export const updateModelExperimentLink = createApiThunk<
    ModelExperimentLinkType,
    ModelExperimentLinkType
>(
    'experiments/updateModelExperimentLink',
    async (api, modelExperimentLink) => await api.put(`model-experiment-link/${modelExperimentLink.id}`, modelExperimentLink),
    error => error.type === 'api-error' && toastr.error('Update ModelExperimentLink', error.message)
)

export const addModelToNewExperiment = createApiThunk<
    ModelExperimentLinkType,
    { modelId: number, projectId: number, experimentName?: string }
>(
    'experiments/addModelToNewExperiment',
    async (api, { modelId, projectId, experimentName }, { dispatch }) => {
        const result = await dispatch(addExperiment({name: experimentName, projectId: projectId})) as any
        if (addExperiment.fulfilled.match(result)) {
            const experiment = result.payload as ExperimentType
            if (experiment.id !== undefined) {
                const linkResult = await dispatch(addModelExperimentLink({ modelId, experimentId: experiment.id })) as any
                if (addModelExperimentLink.fulfilled.match(linkResult)) {
                    return linkResult.payload as ModelExperimentLinkType
                }
            }
        }
    }
)

export const setModelsChecked = createAction<
    { models: number[], checked: boolean }
>('experiments/setModelsChecked')

export const deleteExperiment = createApiThunk<
    void,
    ExperimentType
>(
    'experiments/deleteExperiment',
    async (api, experiment) => await api.delete(`experiment/${experiment.id}`)
)

export const deleteModelExperimentLink = createApiThunk<
    void,
    ModelExperimentLinkType
>(
    'experiments/deleteModelExperimentLink',
    async (api, link) => await api.delete(`model-experiment-link/${link.id}`)
)

export const deleteAllExperiments = createApiThunk<
    void,
    { projectId: number }
>(
  'experiments/deleteAllExperiments',
  async (api, { projectId }, { dispatch }) => {
    await api.delete(`experiment?projectId=${projectId}`)
    dispatch(getExperiments())
  }
)

export const getTrainingMetric = createApiThunk<
    TrainingMetric,
    { modelId: number, metricId: TrainingMetricId }
>(
    'experiments/getTrainingMetric',
    async (api, { modelId, metricId }) => {
        const result = await api.get(`model/${modelId}/training-metric/${metricId}`, false)
        return {
            ...result,
            type: 'metric'
        }
    }
)