import { isNil } from "lodash"

export const buildRedirectTo = () => {
  const search = window.location.search ? `?${window.location.search}` : ''
  return window.location.pathname + search
}

export const storeRedirectTo = (redirectTo: string) => 
  window.localStorage.setItem('loginRedirectTo', redirectTo)

export const getRedirectTo = () => window.localStorage.getItem('loginRedirectTo')

export const clearRedirectTo = () => window.localStorage.removeItem('loginRedirectTo')

export const getIsLogout = () => new URLSearchParams(window.location.search).get('logout') === 'true'

export const getIsRedirect = () => !isNil(new URLSearchParams(window.location.search).get('code'))