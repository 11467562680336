import { Box, CheckBox, Text } from 'grommet'
import produce from 'immer'
import { groupBy, isEqual, keys, some, sortBy } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { DatasetType, EventFeatureType, FlagFeatureType, InstanceType, SiftDataSourceInfoType, SignalFeatureType } from '../../codegen/models/models'
import { updateDataset } from '../../pages/data/actions'


export const InstanceSelector = ({ dataSource, instances, dataset, onUpdated }: 
                                 { dataSource: SiftDataSourceInfoType, 
                                   instances: InstanceType[], 
                                   dataset: DatasetType, 
                                   onUpdated?: (i: InstanceType[], ar: boolean) => void}) => {
    const grouped = groupBy(dataSource.instances, i => i.groupId)
    const dispatch = useDispatch()
    const groupName = (groupId: string) => {
        const group = dataSource.groups.find(g => g.id === groupId)
        return group?.displayName || group?.name 
    }
    const allInstancesInGroupSelected = (groupId: string) => isEqual(
        sortBy(dataSource.instances.filter(f => f.groupId === groupId).map(i => i.id)),
        sortBy(instances.filter(s => s.groupId === groupId).map(i => i.id))
    ) ? true : some(instances.filter(f => f.groupId === groupId)) ? undefined : false
    const groupStates: {[key: string]: boolean | undefined} = keys(grouped).reduce((p, g) => ({...p, [g]: allInstancesInGroupSelected(g)}), {})
    return <Box>
        {
            keys(grouped).map(groupId => 
                <Box key={`${groupId}-items-group`}>
                    {
                        <CheckBox
                            label={<Text weight='bold' size='small'>{groupName(groupId)}</Text>}
                            indeterminate={groupStates[groupId] === undefined}
                            checked={groupStates[groupId]}
                            onChange={({ target: { checked }}) => {
                                onUpdated ? onUpdated(dataSource.instances.filter(s => s.groupId === groupId), checked) :
                                dataset.properties?.schemaRef === 'sift_dataset_properties.schema.json' && dataset?.properties?.featurized === true ?
                                dispatch(updateDataset(addOrRemoveFeaturizedInstances(dataSource.instances.filter(s => s.groupId === groupId), checked, dataset))):
                                dispatch(updateDataset(addOrRemoveInstances(dataSource.instances.filter(s => s.groupId === groupId), checked, dataset)))
                            }}
                        />
                    }
                    {
                        grouped[groupId].map(instance => 
                            <Box key={`${instance.id}-items`} pad={{left: 'small', vertical: 'xxsmall'}}>
                                <CheckBox
                                    label={instance.displayName || instance.id}
                                    checked={instances.find(i => i.id === instance.id && i.groupId === instance.groupId) !== undefined}
                                    onChange={({ target: { checked }}) => {
                                        onUpdated ? onUpdated([instance], checked) :
                                        dataset.properties?.schemaRef === 'sift_dataset_properties.schema.json' && dataset?.properties?.featurized === true ?
                                        dispatch(updateDataset(addOrRemoveFeaturizedInstances([instance], checked, dataset))):
                                        dispatch(updateDataset(addOrRemoveInstances([instance], checked, dataset)))
                                    }}
                                />
                            </Box>
                        )
                    }
                </Box>
            )
        }
    </Box>
}

const addOrRemoveInstances = (instances: InstanceType[], addOrRemove: boolean, dataset: DatasetType): DatasetType => {
    return produce(dataset, (d) => {
        if (d.properties?.schemaRef !== "sift_dataset_properties.schema.json") { return d }
        const currentProperties = d.properties
        if (addOrRemove) {
            const instancesToAdd = instances.filter(i => !currentProperties.instances.find(ci => ci.groupId === i.groupId && ci.id === i.id))
            d.properties.instances = [...d.properties.instances, ...instancesToAdd]
        } else {
            d.properties.instances = [...d.properties.instances.filter(ci => !instances.find(i => i.groupId === ci.groupId && i.id === ci.id))]
        }
        return d
    })
}

const addOrRemoveFeaturizedInstances = (instances: InstanceType[], addOrRemove: boolean, dataset: DatasetType): DatasetType => {
    return produce(dataset, (d) => {
        if (d.properties?.schemaRef !== "sift_dataset_properties.schema.json") { return d }
        const currentProperties = d.properties
        if (addOrRemove) {
            const instancesToAdd = instances.filter(i => !currentProperties.instances.find(ci => ci.groupId === i.groupId && ci.id === i.id))
            d.properties.instances = [...d.properties.instances, ...instancesToAdd]
            const uniqueFeatures: (SignalFeatureType | FlagFeatureType | EventFeatureType)[] = []
            const feature_ids: string[] = []
            currentProperties.features.forEach((f) => {
                if (!feature_ids.includes(f.id)){
                    feature_ids.push(f.id)
                    uniqueFeatures.push(produce(f, feature => { feature.instanceId = undefined }))
                }
            })
            const newFeatures: (SignalFeatureType | FlagFeatureType | EventFeatureType)[] = []
            instances.forEach(i => {
                uniqueFeatures.forEach(uf => {
                    if (uf.groupId === i.groupId) {
                        newFeatures.push(produce(uf, nf => { 
                            if(i.id && i.groupId){
                                nf.instanceId = {
                                    id: i.id,
                                    groupId: i.groupId,
                                    schemaRef: 'sift_dataset_properties.schema.json#/definitions/instance_id'
                                }
                            }
                            return nf
                        }))
                    }
                })
            })
            d.properties.features = [...d.properties.features, ...newFeatures ]
        } 
        else {
            d.properties.instances = [...d.properties.instances.filter(ci => !instances.find(i => i.groupId === ci.groupId && i.id === ci.id))]
            const newFeatures: (SignalFeatureType | FlagFeatureType | EventFeatureType)[] = []
            currentProperties.features.forEach((f) => {
                instances.forEach(i => {
                    const instanceId = {
                        id: i.id,
                        groupId: i.groupId,
                        schemaRef: 'sift_dataset_properties.schema.json#/definitions/instance_id'
                    }
                    if (!isEqual(f.instanceId, instanceId)){
                        newFeatures.push(f)
                    }
                })
            })
            d.properties.features = newFeatures
        }
        return d
    })
}
