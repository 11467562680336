import React, { useEffect } from 'react';
import { ModelType, DataLoaderType, DatasetType } from '../../../codegen/models/models'
import { useDispatch, useSelector } from 'react-redux'
import { getSchema, updateModel } from '../actions'
import { Box, Select, Text } from 'grommet';
import InputRow from '../../../core/components/InputRow'
import produce from 'immer';
import { generateModel, SchemaInterface } from '../../../core/components/Utils';
import { IRequestable } from '../../../core/api';
import { IApplicationState } from '../../../core/state';
import { getDatasets } from '../../data/actions';
import Requestable from '../../../core/components/Requestable';
import ModelEditor from '../../../core/components/ModelEditor';

const setDatasetId = (datasetId: number, model: ModelType) => {
    return produce(model, m => { m.datasetId = datasetId })
}

const DatasetSelector = ({dataSourceId, projectId, onSelected, datasetId}: {dataSourceId?: number, projectId?: number, datasetId?: number, onSelected: (datasetId: number) => void}) => {
    const datasets = useSelector<IApplicationState, IRequestable<DatasetType[]>>(s => s.data.datasets)
    return (dataSourceId !== undefined && projectId !== undefined) ? <Requestable
        requestable={datasets}
        action={getDatasets}
        render={
            (datasets) => {
                const currentDataset = datasets.find(d => d.id === datasetId)
                return <Select
                            options={datasets.filter(d => d.projectId === projectId && !d.revisionId && !d.wizardId)}
                            value={currentDataset}
                            labelKey={v => v.name}
                            placeholder={'No Dataset Selected'}
                            emptySearchMessage={'No Datasets Found'}
                            onChange={({ value }) => onSelected(value.id)}
                            />
            }}
        /> : <Text>Data Source or Project ID Not Found</Text>
}

const DataLoaderEditor = ({ model, dataSourceId, projectId, schema, schemaRef }: { model: ModelType, dataSourceId?: number, projectId?: number, schema: SchemaInterface, schemaRef: string }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (!model.dataLoader) {
            dispatch(
                updateModel(produce(model, m => {
                    m.dataLoader = generateModel(schema, schemaRef) as DataLoaderType
                }))
            )
        }
    }, [model.dataLoader, schema, schemaRef, dispatch, model])
    if (!model.dataLoader) {
        return <Text>Creating Data Loader...</Text>
    }
    return <Box gap='small' pad='small'>
        <InputRow label='Dataset'>
            <DatasetSelector datasetId={model.datasetId} dataSourceId={dataSourceId} projectId={projectId} onSelected={datasetId => dispatch(updateModel(setDatasetId(datasetId, model)))} />
        </InputRow>
        <ModelEditor
            model={model}
            property={'dataLoader'}
            schema={schema}
        />
    </Box>
}

export default ({ model, dataSourceId }: { model: ModelType, dataSourceId?: number }) => {
    const schemaRef = 'model.schema.json#/definitions/data_loader'
    const schemaRequestable = useSelector<IApplicationState, IRequestable<SchemaInterface>>(s => s.model.schemas[schemaRef])
    return <Requestable
                requestable={schemaRequestable}
                action={() => getSchema(schemaRef)}
                render={schema => <DataLoaderEditor
                    model={model}
                    dataSourceId={dataSourceId} 
                    projectId={model.projectId} 
                    schema={schema} 
                    schemaRef={schemaRef} />
                } />
    
}

