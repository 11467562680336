import { Anchor, Box, DropButton } from "grommet"
import React from "react"
import Icon from "./Icon"
import ReactMarkdown from 'react-markdown'


export const InfoDropdown = ({ info }:{ info:string }) => {
    return <Box fill='vertical' justify='center'>
    <DropButton
        dropContent={
            <Box pad='small' background='background-front' >
                <ReactMarkdown components={{a: ({ node, ...props }) => <Anchor {...props} target='_' />}}>
                    {info}
                </ReactMarkdown>
            </Box>
        } dropAlign={{top: 'bottom'}}>
        <Box fill pad='small'>
            <Icon icon='info-circle' color='white'/>
        </Box>
    </DropButton>
</Box>
}