import { useHistory, useLocation, useRouteMatch } from "react-router"

export const useSearchUpdate = () => {
    const history = useHistory()
    const { url } = useRouteMatch()
    const params = useSearchParams()
    return (name: string, value: string | undefined) => {
        if (value === undefined) {
            params.delete(name)
        } else {
            params.set(name, value)
        }
        history.push({
            pathname: url,
            search: `?${params.toString()}`
        })
    }
}

export const useSearchParams = () => new URLSearchParams(useLocation().search)

export const useIdSearchParam = (name: string) => {
    const strParam = useSearchParams().get(name)
    return strParam?.length ? Number(strParam) : undefined
}