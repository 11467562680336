import React from 'react'
import { Text, TextProps } from "grommet"
import { FlagType } from "../../../codegen/models/models"
import { ColorType } from 'grommet/utils'

export default ({flag, ...props}: {flag: FlagType} & TextProps & Omit<JSX.IntrinsicElements['span'], 'color'>) => {
    const flagColour: ColorType = flag.flagType === 'information_not_available' ? 'rgb(204, 204, 204)'
        : flag.flagType === 'neutral' ? 'rgb(146, 196, 67)'
        : flag.flagType === 'partial_performance_degraded' ? 'rgb(33, 159, 211)'
        : flag.flagType === 'partial_performance_derated' ? 'rgb(236, 100, 161)'
        : flag.flagType === 'unavailable' ? 'rgb(244, 151, 37)' : undefined
    return <Text color={flagColour} {...props}>{flag.displayName || flag.flagId}</Text>
}