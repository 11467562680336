import { Box } from 'grommet'
import produce from 'immer'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModelType, ProjectType } from '../../../codegen/models/models'
import ItemsTable, { CopyCell, DeleteCell, EditableCell, NameCell } from '../../../core/components/ItemsTable'
import { DeleteModal } from '../../../core/components/Modal'
import { IApplicationState } from '../../../core/state'
import { clearAddedModel, createModel, deleteModel, updateModel } from '../actions'
import { IModel } from '../state'

export default ({ project, models }: { project: ProjectType, models: ModelType[] }) => {
    const model = useSelector<IApplicationState, IModel>(s => s.model)
    const dispatch = useDispatch()
    const [deleting, setDeleting] = useState<number>()
    return <Box>
        <ItemsTable
            clearAddedIdAction={clearAddedModel}
            addedId={model.modelAdded}
            name='Model'
            onCreate={name => project.id && dispatch(createModel({ 
                model: {
                    name: name || 'New Model',
                    projectId: project.id,
                    schemaRef: 'model.schema.json',
                    preprocessors: [],
                    runStatus: 'pending'
                }
            }))}
            searchParam='modelId'
            items={models}
            columns={[
                {
                    property: 'name',
                    header: 'Name',
                    render: (item) => <NameCell item={item} searchParam='modelId' />
                },
                {
                    property: 'description',
                    header: 'Description',
                    render: (item) => <EditableCell value={item.description || ''} onChanged={v => dispatch(updateModel(produce(item, m => { m.description = v })))} />
                },
                {
                    header: 'Copy',
                    property: 'id1',
                    size: 'xsmall',
                    render: (model: ModelType) => {
                        return <CopyCell onCopy={() => dispatch(createModel({ model: {
                            ...model,
                            id: undefined,
                            name: `${model.name} (Copy)`
                        }}))} />
                    }
                },
                {
                    header: 'Delete',
                    property: 'id2',
                    size: 'xsmall',
                    render: (item: ModelType) => {
                        return <DeleteCell onDelete={setDeleting} item={item}/>
                    }
                }
            ]}
        />
        <DeleteModal
            isOpen={deleting !== undefined}
            item={models.find(m => m.id === deleting)?.name || '?'}
            text={'Model'}
            onAccept={() => {
                deleting && dispatch(deleteModel({modelId: deleting}))
                setDeleting(undefined)
            }}
            onCancel={() => setDeleting(undefined)} />
    </Box>
}