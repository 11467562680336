import { Box, Button, ColumnConfig, DataTable, Heading, Text, TextInput } from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router'
import { useSearchUpdate } from '../utils/router'
import EditableText from './EditableText'
import Icon from './Icon'
import LinkAnchor from './LinkAnchor'
import Modal from './Modal'

export interface Item {
    id?: number
    name: string
}

export const NameCell = ({ item: {id, name}, searchParam }: { item: Item, searchParam: string }) => {
    const { search } = useLocation()
    const { url } = useRouteMatch()
    const params = new URLSearchParams(search)
    if (id !== undefined) {
        params.set(searchParam, id.toString())
    }
    return <LinkAnchor to={`${url}?${params.toString()}`}>{name}</LinkAnchor>
}

export const EditableCell = ({ value, onChanged }: { value: string, onChanged: (v: string) => void}) => {
    const [isEditing, setIsEditing] = useState(false)
    return isEditing ? <EditableText initialValue={value} onFinishEdit={v => {
        onChanged(v)
        setIsEditing(false)
    }} onAbortEdit={() => setIsEditing(false)} placeholder={'Enter a value...'}/> :
    <Box direction='row' justify='between'>
        <Text>{value}</Text>
        <Button onClick={() => setIsEditing(true)}>
            <Icon icon='pen' color='background-front' />
        </Button>
    </Box>
}

export const CopyCell = ({ onCopy }: {onCopy: () => void}) => {
    return <Button onClick={() => onCopy()}>
        <Icon icon='copy' color='background-front' />
    </Button>
}

export const DeleteCell = ({ item: { id }, onDelete }: { item: Item, onDelete: (id: number) => void }) => {
    return <Button onClick={() => id && onDelete(id)}>
        <Icon icon='trash' color='background-front' />
    </Button>
}

export default <ItemT extends Item>({ items, columns, name, onCreate, searchParam, addedId, clearAddedIdAction }: 
    { items: ItemT[], columns: ColumnConfig<ItemT>[], name: string, 
      onCreate?: (n?: string) => void, searchParam: string, addedId?: number | undefined, clearAddedIdAction?: () => any }) => {
    const dispatch = useDispatch()
    const searchUpdate = useSearchUpdate()
    const [showWindow, setShowWindow] = useState(false)
    useEffect(
        () => {
            if (addedId && clearAddedIdAction) {
                dispatch(clearAddedIdAction())
                searchUpdate(searchParam, addedId.toString())
            }
        },
        [dispatch, addedId, searchUpdate, clearAddedIdAction, searchParam]
    )
    return <Box fill='vertical' pad='small' width='xlarge'>
        <Box fill='horizontal' direction='row'>
            <Heading level={2}>{name}s</Heading>
            {
                onCreate &&
                <Box fill='horizontal' direction='row' justify='end' align='center'>
                    <Button onClick={() => setShowWindow(true)} label={`Create ${name}`}/>
                </Box>
            }
        </Box>
        <DataTable columns={columns}
            primaryKey='id'
            data={items}
            onClickRow={() => {}}
            />
        <NewNameModal
                isOpen={showWindow === true}
                item={name}
                onAccept={onCreate}
                onCancel={() => setShowWindow(false)} 
            />
    </Box>
}

export function NewNameModal({ isOpen, item, onCancel, onAccept }: 
    { isOpen: boolean, item: string, onCancel: () => void, onAccept?: (n?: string) => void }) {
      const [name, setName] = useState(`New_${item}`)
      return (
        <Modal isOpen={isOpen}>
              <Box fill align='center' justify='evenly' pad='medium' height='auto'>
                  <TextInput
                      id='value-name-input'
                      value={name}
                      onChange={(e) => {setName(e.target.value)}}
                  />
                  <Box direction='row' fill='horizontal' justify='evenly'>
                      <Button label='OK' onClick={() => onAccept ? onAccept(name): {}} />
                      <Button label='Cancel' onClick={onCancel} />
                  </Box>
              </Box>
        </Modal>
      )
    }