import { createAction } from '@reduxjs/toolkit'
import { createApiThunk } from '../../core/api'
import { DatasetType, WizardType, ResultsType, ModelType, PreprocessorType, ModelAttributeType } from '../../codegen/models/models';
import { toastr } from "react-redux-toastr";
import { generateModel, SchemaInterface } from '../../core/components/Utils';
import produce from 'immer';
import { create } from 'domain';
import { createModel, getSchema } from '../model/actions';
import { createDataset } from '../data/actions';

// ---------------------- WIZARD MODELS ----------------------
export const doWizardry = createApiThunk<
    void,
    { wizardId: number }
>(
    'do-wizard/do-wizardry',
    async (api, { wizardId }, {dispatch}) => {
        await api.post(`do-wizardry/${wizardId}`)
    },
    e => e.type === 'api-error' && toastr.error('Do Wizardry', e.message)
)

export const createModels = createApiThunk<
    void,
    { algos: string[],
      pps: string[],
      wizard: WizardType,
      projectId: number }
>(
    'wizard/createModels',
    async (api, { algos, pps, wizard, projectId }, { dispatch }) => {
        const model_schema: SchemaInterface = await api.get(`schemas/model.schema.json`)
        let PPs: PreprocessorType[] = []
        pps.forEach(async (pp: string) => {
            const pp_schema: SchemaInterface = await api.get(`schemas/model.schema.json%23/definitions/${pp}`)
            const pp_object = generateModel(pp_schema, `model.schema.json#/definitions/${pp}`)
            PPs.push(pp_object as PreprocessorType)
        })
        algos.forEach(async (algo: string) => {
            const model_obj = generateModel(model_schema, 'model.schema.json')
            const algo_schema: SchemaInterface = await api.get(`schemas/model.schema.json%23/definitions/${algo}`)
            const algo_object = generateModel(algo_schema, `model.schema.json#/definitions/${algo}`)
            const new_model = produce(model_obj, m => {
                m.algorithm = algo_object
                m.preprocessors = PPs
                m.name = ('Wizard_Model_for_' + algo)
                m.runStatus = 'pending'
                m.projectId = projectId
                m.datasetId = wizard.datasetId
            })
            dispatch(createModel({model: new_model as ModelType}))
        });
    },
    e => e.type === 'api-error' && toastr.error('Create Models', e.message)
)
