import { toastr } from "react-redux-toastr";
import { createApiThunk } from "../../core/api";
import { DataSourceType, ProjectType } from "../../codegen/models/models"
import { createAction, PrepareAction } from "@reduxjs/toolkit";
import { Session } from "./state";

export const getProjects = createApiThunk<
    ProjectType[],
    void
>(
    'project/getProjects',
    async (api) => await api.get('project'),
    e => e.type === 'api-error' && toastr.error('Get Projects', e.message)
)

export const getDataSources = createApiThunk<
    DataSourceType[],
    void
>(
    'project/getDataSources',
    async (api) => await api.get('data-source'),
    e => e.type === 'api-error' && toastr.error('Get Data Sources', e.message)
)

export const addSession = createAction<PrepareAction<Session>>(
    'project/addSession',
    (session: Session) => ({ payload: session })
)