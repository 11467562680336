import { Box, Button, Text } from 'grommet'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useSearchParams } from '../utils/router'
import EditableText from './EditableText'
import Icon from './Icon'
import LinkAnchor from './LinkAnchor'

export default ({ parentName, name, description, searchParamName, onNameEdited, onDescriptionEdited }: { parentName: string, name: string | undefined, description: string | undefined, searchParamName: string, onNameEdited?: (n: string) => void, onDescriptionEdited?: (n: string) => void }) => {
    const [isEditingName, setIsEditingName] = useState(false)
    const [isEditingDescription, setIsEditingDescription] = useState(false)
    const { url } = useRouteMatch()
    const params = useSearchParams()
    params.delete(searchParamName)
    return <Box fill='horizontal' gap='small' direction='row' pad='small' align='center'>
        <LinkAnchor to={`${url}?${params.toString()}`}>
            <Box direction='row' gap='small' align='center'>
                <Icon icon='table' color='accent-1' />
                <Text size='medium'>{parentName}</Text>
            </Box>
        </LinkAnchor>
        <Text size='medium'>/</Text>
        {
            isEditingName ?
            <EditableText onAbortEdit={() => {setIsEditingName(false)}} initialValue={name}
            onFinishEdit={v => {
                if (v && onNameEdited) onNameEdited(v)
                setIsEditingName(false)
            }} placeholder='Enter a name...'/> :
            <Text size='medium'>{name}</Text>
        }
        {
            onNameEdited && name &&
            <Button plain onClick={() => setIsEditingName(true)}>
                <Icon icon='pen' color='background-front' size='sm' />
            </Button>
        }
        <Box width='small' />
        <Text style={{fontStyle: 'italic'}}>Description:</Text>
        {
            isEditingDescription ?
            <EditableText onAbortEdit={() => {setIsEditingDescription(false)}} initialValue={description}
            onFinishEdit={v => {
                if (v && onDescriptionEdited) onDescriptionEdited(v)
                setIsEditingDescription(false)
            }} placeholder='Enter a description...'/> :
            <Text size='medium'>{description}</Text>
        }
        {
            onDescriptionEdited &&
            <Button plain onClick={() => setIsEditingDescription(true)}>
                <Icon icon='pen' color='background-front' size='sm' />
            </Button>
        }
    </Box>
  }