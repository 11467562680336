import { Box, Button, Heading } from 'grommet'
import React, { useState } from 'react'
import Icon from './Icon'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import Modal from './Modal'


export default ({icon, label, confirmLabel, onConfirm, onReject}:
                    {icon: string, label: string, confirmLabel: string, onConfirm:() => (void), onReject:() => (void)}) => {
  const [confirm, setConfirm] = useState(false)
  return  <Box pad='small' align='center'>
      <Button plain
                 icon={<Icon icon={icon as IconName} color='background-front' size='sm' />}
                 label={label}
                 onClick={() => setConfirm(!confirm)}
      />
      <Modal isOpen={confirm}>
            <Box pad='small' align='center' fill>
                <Heading level={3}>{confirmLabel}</Heading>
                <Box fill direction='row' gap='small' align='center' justify='center'>
                    <Button size='small' label='Yes' onClick={() => { onConfirm()
                                                                      setConfirm(false)}}/>
                    <Button size='small' label='No!' onClick={() => { onReject()
                                                                    setConfirm(false)}}/>
                </Box>
            </Box>
      </Modal>
    </Box>
}
