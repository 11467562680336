import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Text, Box } from 'grommet'
import Loader from './Loader'
import { IRequestable, ILargeRequestable } from '../api'

type IRequestableProps<T> = { requestable?: IRequestable<T>, render: (value: T) => React.ReactElement, action: () => any }

export default function Requestable<T>({ requestable, render, action }: IRequestableProps<T>) {
  const dispatch = useDispatch()

  const { value, isRequesting, errorMessage } = requestable || {};

  useEffect(() => { value === undefined && !isRequesting && !errorMessage && dispatch(action()) }, [value, isRequesting, errorMessage, action, dispatch])

  return errorMessage ? (
    <Box direction='column' pad='xlarge'>
      <Text color='status-error'>{errorMessage}</Text>
    </Box>
  ) : isRequesting || value === undefined ? (
    <Box direction='column' pad='xlarge'>
      <Loader />
    </Box>
  ) : render(value)
}

type IMultiRequestableProps = { requestables: { requestable: IRequestable<any>, action: (() => any)}[], render: (value: any[]) => React.ReactElement }

export function MultiRequestable({ requestables, render }: IMultiRequestableProps) {
  const dispatch = useDispatch()

  useEffect(() => {
    requestables.forEach(({ requestable: { value, isRequesting, errorMessage }, action}) => {
      value === undefined && !isRequesting && !errorMessage && dispatch(action())
    })
  }, [requestables, dispatch])

  return requestables.filter(r => r.requestable.errorMessage).length > 0 ? (
    <Box direction='column' pad='xlarge'>
      {
        requestables.filter(r => r.requestable.errorMessage).map(r => <Text color='status-error'>{r.requestable.errorMessage}</Text>)
      }
    </Box>
  ) : requestables.filter(r => r.requestable.isRequesting || r.requestable.value === undefined).length > 0 ? (
    <Box direction='column' pad='xlarge'>
      <Loader />
    </Box>
  ) : render(requestables.map(r => r.requestable.value))
}

export function useConditionalDispatch<T>({ requestable, action }: { requestable?: IRequestable<T>, action: () => void}) {
  const dispatch = useDispatch()

  const { value, isRequesting, errorMessage } = requestable || {};
  if (value === undefined && !isRequesting && !errorMessage) {
    dispatch(action())
  }

  return value
}

export function useConditionalLargeDispatch<T>({ requestable, action }: { requestable?: ILargeRequestable<T>, action: () => void}) {
  const dispatch = useDispatch()

  const {
    value, isRequesting, errorMessage, gotAll,
  } = requestable || {};
  if ((value === undefined || !gotAll) && !isRequesting && !errorMessage) {
    dispatch(action())
  }

  return value
}
