import { Box, Text } from 'grommet'
import produce from 'immer'
import { filter } from 'lodash'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { DatasetType, 
         EventFeatureType, 
         FeatureTypeType, 
         FlagFeatureType, 
         SiftDatasetPropertiesType, 
         SiftDataSourceInfoType, 
         SignalFeatureType, 
         SignalType } from '../../../codegen/models/models'
import ScrollThumb from '../../../core/components/ScrollThumb'
import Flag from '../../data/components/Flag'
import Signal from '../../data/components/Signal'

const isSignal = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is SignalFeatureType => {
    return v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature' }
const isFlag = (v: (SignalFeatureType | FlagFeatureType | EventFeatureType)): v is FlagFeatureType => {
    return v.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature' }


export default ({dataSource, datasetProperties}: {dataSource: SiftDataSourceInfoType, datasetProperties: SiftDatasetPropertiesType}) => {
    const dispatch = useDispatch()
    const signalFeatureFeatures = filter(datasetProperties.features, isSignal).filter(s => s.featureType === 'feature')
    const signalFeatureTargets = filter(datasetProperties.features, isSignal).filter(s => s.featureType === 'target')
    const flagFeatureFeatures = filter(datasetProperties.features, isFlag).filter(fl => fl.featureType === 'feature')
    const flagFeatureTargets = filter(datasetProperties.features, isFlag).filter(fl => fl.featureType === 'target')

    const featureLabel = (s: (SignalFeatureType | FlagFeatureType)) => {
        if (s.schemaRef === 'sift_dataset_properties.schema.json#/definitions/signal_feature') {
            const signal = dataSource.signals.find(v => v.signalId === s.signalId)
            return signal && <Signal signal={signal} size='s'/>
        } else if (s.schemaRef === 'sift_dataset_properties.schema.json#/definitions/flag_feature') {
            const flag = dataSource.flags.find(v => v.flagId === s.flagId)
            return flag && <Flag flag={flag}  size='s'/>
        }
    }

    const setFeatureType = (dataset: DatasetType, match: (f: SignalFeatureType | FlagFeatureType | EventFeatureType) => boolean, featureType: FeatureTypeType): DatasetType => {
        return produce(dataset, d => {
            if (d.properties?.schemaRef !== 'sift_dataset_properties.schema.json') return d
            const currentProperties = d.properties
            const indexToUpdate = currentProperties.features.findIndex(match)
            currentProperties.features[indexToUpdate].featureType = featureType
        })
    }

    return <Box fill direction='column' border='all' round='xsmall' margin={{top:'small'}}>
        <Box flex fill basis='1/3' title='Current Targets: '>
            <Scrollbars renderThumbVertical={ScrollThumb}>
                <Box pad='small' gap='xsmall'>
                    <Text alignSelf='center' weight='bold'>Targets</Text> 
                    {
                        signalFeatureTargets.map(s => <Box direction='row' align='center' gap='small' key={`signal-feature-${s.groupId}-${s.signalId}`}>
                                <Box fill>
                                    <Text size='s'>{featureLabel(s) || s.signalId} ({s.groupId})</Text>
                                </Box>
                            </Box>

                        )
                    }
                </Box>
            </Scrollbars>         
        </Box>
        <Box flex fill basis='2/3' title='Current Features: '>
            <Scrollbars renderThumbVertical={ScrollThumb}>
                <Box pad='small' gap='xsmall'>
                    <Text alignSelf='center' weight='bold'>Features</Text> 
                    {
                        signalFeatureFeatures.map(s => <Box direction='row' align='center' gap='small' key={`signal-feature-${s.groupId}-${s.signalId}`}>
                                <Box fill>
                                    <Text size='s'>{featureLabel(s) || s.signalId} ({s.groupId})</Text>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Scrollbars> 
        </Box>  
    </Box>
}