import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"
import { addSiftLink, deleteSiftLink, getSiftLinks, updateSiftLink } from "../actions/siftLinkActions"
import { SiftLinkState } from "../states/siftLinkState"

export const siftLinks = createSlice<SiftLinkState, SliceCaseReducers<SiftLinkState>>({
    name: 'siftLinks',
    initialState: { siftLinks: { isRequesting: false } },
    reducers: { },
    extraReducers: (builder) => {
        builder.addCase(addSiftLink.fulfilled, (state, { payload: siftLink }) => {
            if (state.siftLinks.value !== undefined && siftLink !== undefined ) {
                state.siftLinks.value = [...state.siftLinks.value, siftLink]
            }
        })
        builder.addCase(getSiftLinks.pending, (state) => {
            if (state.siftLinks.value === undefined) {
                state.siftLinks = {
                    isRequesting: true
                }
            }
        })
        builder.addCase(getSiftLinks.fulfilled, (state, { payload: siftLinks }) => {
            state.siftLinks = {
                isRequesting: false,
                value: siftLinks
            }
        })
        builder.addCase(getSiftLinks.rejected, (state, { error: { message }}) => {
            state.siftLinks = {
                isRequesting: false,
                errorMessage: message
            }
        })
        builder.addCase(updateSiftLink.pending, (state, { meta: { arg: siftLink }}) => {
            if (state.siftLinks.value !== undefined && siftLink.id !== undefined) {
                state.siftLinks.value = state.siftLinks.value.map(
                    s => s.id === siftLink.id ? siftLink : s
                )
            }
        })
        builder.addCase(updateSiftLink.rejected, () => {
            // TODO: Handle rejected case
        })
        builder.addCase(deleteSiftLink.pending, (state, { meta: { arg: { siftLinkId } }}) => {
            if (state.siftLinks.value !== undefined) {
                state.siftLinks.value = state.siftLinks.value.filter(s => s.id !== siftLinkId)
            }
        })
        builder.addCase(deleteSiftLink.rejected, () => {
            // TODO: Handle rejected case
        })
    }
})