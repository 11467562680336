import React from "react"
import { Box, Collapsible } from "grommet"
import { ModelType } from "../../codegen/models/models"

export default ({ isOpen, model }: { isOpen: boolean, model: ModelType }) => {
    return <Collapsible direction="horizontal" open={isOpen} style={{zIndex:1}}>
        <Box flex fill background="dark-2" pad="small" overflow='scroll' wrap={true} elevation="small">
            <pre>{JSON.stringify(model, null, 2)}</pre>
        </Box>
    </Collapsible>
}
