import React from 'react'
import {  Box, Button, Heading, Text } from 'grommet'
import { BasisType, FillType } from 'grommet/utils'
import { useDispatch } from 'react-redux'


export const Phase = ({header, content, controls}:
                      {header?: string | React.ReactElement | React.ReactElement[], 
                       content: string | React.ReactElement | React.ReactElement[],
                       controls: string | React.ReactElement | React.ReactElement[]}) => (
       <Box fill direction='column' basis='1' flex={true} gap='small' pad='xsmall' >
            <Box basis='1/8' >{header}</Box>
            <Box basis='3/4' >{content}</Box>
            <Box basis='1/8' >{controls}</Box>
      </Box>
)

export default Phase