import { Box, DateInput, Text } from "grommet"
import React, { useState } from "react"
import { DateRangeType } from "../../codegen/models/models"

const DateInputWrapper = ({date, onUpdate}: {date?: string, onUpdate: (d: string) => void}) => {
    const [value, setValue] = useState<string | undefined>()
    const onChange = (value?: string) => {
        setValue(value)
        if (value) onUpdate(value)
    }
    return <DateInput format="yyyy/mm/dd" value={value} defaultValue={date} onChange={({ value }) => onChange(value?.toString())} />
}

export default ({ dateRange, onUpdate, initialDateRange }: { dateRange: DateRangeType, initialDateRange: DateRangeType, onUpdate: (dr: DateRangeType) => void }) => {
    const fromValue = dateRange?.dateFrom || initialDateRange?.dateFrom || (new Date()).toISOString()
    const toValue = dateRange?.dateTo || initialDateRange?.dateTo || (new Date()).toISOString()
    return <Box pad='small' gap='small'>
        <Box direction='row' gap='small'>
            <Box justify='center' width='20%'>
                <Text size='s'>Date From</Text>
            </Box>
            <DateInputWrapper
                date={fromValue}
                onUpdate={d => onUpdate({...dateRange, schemaRef: 'dates.schema.json#/definitions/date_range', dateFrom: d})}
            />
        </Box>
        <Box direction='row' gap='small'>
            <Box justify='center' width='20%'>
                <Text size='s'>Date To</Text>
            </Box>
            <DateInputWrapper
                date={toValue}
                onUpdate={d => onUpdate({...dateRange, schemaRef: 'dates.schema.json#/definitions/date_range', dateTo: d})}
            />
        </Box>
    </Box>
}
