import { Box, CheckBox, Text } from 'grommet'
import { groupBy, isEqual, keys, some, sortBy } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { DatasetType, FlagFeatureType, FlagType, GroupType, SignalFeatureType, SignalType } from '../../../codegen/models/models'
import { updateDataset } from '../actions'
import { addOrRemoveFeatures } from './FeatureSelector'


export default <T extends (SignalType | FlagType), FT extends (SignalFeatureType | FlagFeatureType)>(
    {items, groups, features, dataset, getProp, target, createFeature}:
    { items: T[], groups: GroupType[], features: FT[], dataset: DatasetType, getProp: (v: T | FT) => string, target: (v: T) => string | React.ReactElement, createFeature: (v: T) => FT }
    ) => {
    const grouped = groupBy(items, s => s.groupId)
    const groupName = (groupId: string) => {
        const group = groups.find(g => g.id === groupId)
        return group?.displayName || group?.name || group?.name
    }
    const allSignalsInGroupSelected = (groupId: string) => isEqual(
        sortBy(features.filter(f => f.groupId === groupId).map(getProp)),
        sortBy(items.filter(s => s.groupId === groupId).map(getProp))
    ) ? true : some(features.filter(f => f.groupId === groupId)) ? undefined : false
    const groupStates: {[key: string]: boolean | undefined} = keys(grouped).reduce((p, g) => ({...p, [g]: allSignalsInGroupSelected(g)}), {})
    const dispatch = useDispatch()
    return <Box>
        {
            keys(grouped).map(groupId =>
                <Box key={`${groupId}-items-group`}>
                    {
                        <CheckBox
                            label={<Text weight='bold' size='small'>{groupName(groupId)}</Text>}
                            indeterminate={groupStates[groupId] === undefined}
                            checked={groupStates[groupId]}
                            onChange={e => dispatch(updateDataset(
                                addOrRemoveFeatures(
                                    items.filter(s => s.groupId === groupId).map(createFeature),
                                    e.target.checked,
                                    dataset
                                )
                            ))}
                        />
                    }
                    {
                        grouped[groupId].map(item =>
                            <Box key={`${getProp(item)}-items`} pad={{left: 'small', vertical: 'xxsmall'}}>
                                <CheckBox
                                    label={target(item)}
                                    checked={features.find(f => getProp(f) === getProp(item) && f.groupId === item.groupId) !== undefined}
                                    onChange={e => dispatch(updateDataset(
                                        addOrRemoveFeatures(
                                            [createFeature(item)],
                                            e.target.checked,
                                            dataset
                                        )
                                    ))}
                                />
                            </Box>
                        )
                    }
                </Box>
            )
        }
    </Box>
}