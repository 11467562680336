import { Anchor } from 'grommet'
import { Validator } from 'prop-types'
import React, { forwardRef } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

const AnchorValidator : Validator<'object'> = (props: { [key: string]: any }, propName: string, componentName: string, location: string, propFullName: string) => null

Anchor.propTypes = {
  ...Anchor.propTypes,
  as: AnchorValidator,
}

const LinkWithRef = forwardRef(({
  href, children, hasLabel, hasIcon, colorProp, focus, ...rest
}: any, ref) => <Link ref={ref} to={href} {...rest}>{children}</Link>)

export default forwardRef(({ to, children, ...rest }: any, ref) => (
  <Anchor
    href={to}
    ref={ref}
    as={LinkWithRef}
    {...rest}
  >
    {children}
  </Anchor>
))
