import { Box, Text, RadioButton } from "grommet"
import produce from "immer"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateWizard } from "../../../codegen/actions/wizardActions"
import { WizardType, QuestionType } from "../../../codegen/models/models"
import { IApplicationState } from "../../../core/state"
import { IData } from "../../data/state"



export default ({ wizard }: { wizard: WizardType }) => {
    const data = useSelector<IApplicationState, IData>(s => s.data)
    const scale = ['Not At All', 'Not Very', 'Indifferent', 'Somewhat', 'Very']
    return <Box fill direction='column' align='center' gap='small' pad='medium'>
      <Text size='large' weight='bold'>Answer the following questions in terms of importance:</Text> 
      <Box fill direction='column' pad='small' gap='small'>
          <QuestionRow b={'Question'}>    
            <Box fill direction='row' basis='1/3' gap='medium'>
              {
                scale.map(v => <Text key={`${v}_text`} size='small' weight='bold'>{v}</Text> )
              }
            </Box>
          </QuestionRow>
          {
          (wizard.questions) ?
              wizard.questions.map((q, index) => <Question 
                key={`Question_${index}`} 
                id={index}
                question={q} 
                wizard={wizard}
              />)
            :
              <Text> No Questions Found!</Text>
          }
      </Box>
      <Text size='small'>If you're not sure, select the middle option...!</Text>
    </Box>
}

export const Question = ({id, question, wizard}: { id: number, question: QuestionType, wizard: WizardType }) =>{
    const dispatch = useDispatch()
    const values = [1,2,3,4,5]
    return <QuestionRow b={question.questionText}>
      <Box fill  direction='row' basis='1/3' gap='large'>
        {
          values.map((value, index) => <RadioButton
              key={`button_for_value_${index}`}
              name={value.toString()}
              value={value}
              checked={question.value === index ? true : false }
              onChange={() => { dispatch(updateWizard(produce(wizard, w => { 
                  if(w.questions){ w.questions[id].value = value - 1 }
              })))}}
            />
          )
        }    
      </Box>
    </QuestionRow>
}

const QuestionRow = ({ children, b }: { children: React.ReactElement, b?: string }) => {
  return <Box fill direction='row' gap='medium' align='center'>
    <Box fill='horizontal' direction='row' basis='1/3'>
      {children}
    </Box>
    <Box fill='horizontal' direction='row' basis='2/3'>
      <Text size='medium'>{b}</Text>    
    </Box>
  </Box>
}